import service from "@/utils/request"

export function vipAddressBookGetList(params) {
    return service({
        url: '/vip/addressBook/getList',
        method: 'get',
        params
    })
}

export function vipAddressBookSetCheckEnterpriseApi(params) {
    return service({
        url: '/vip/addressBook/setCheckEnterprise',
        method: 'get',
        params
    })
}
export function getVipGoodsApi(params) {
    return service({
        url: '/vip/addressBook/getGoodsList',
        method: 'get',
        params
    })
}

export function getVipStaffInfoApi(params) {
    return service({
        url: '/vip/addressBook/getVipStaffInfo',
        method: 'get',
        params
    })
}
