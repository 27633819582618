<template>
  <div style="min-height: 87vh">
    <VipPayComponent ref="VipPayComponent" @checkPay="checkPay"></VipPayComponent>
    <!--    顶部标题-->
    <TitleCard style="margin: 10px auto; max-width: 97%" title="数字卡券">
      <template v-slot:subTitle>
        <ul class="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
          <li class="breadcrumb-item text-muted">Digital Card Roll</li>
        </ul>
      </template>
    </TitleCard>
    <!--高级搜索部分-->
    <div class="container-fluid d-flex flex-stack flex-column-fluid" style="padding: 0 30px">
      <form action="#" style="width: 100%">
        <!--begin::Card-->
        <div class="card">
          <!--begin::Card body-->
          <div class="card-body">
            <!--begin::Compact form-->
            <div class="d-flex align-items-center">
              <!--begin::Input group-->
              <div class="position-relative w-md-400px me-md-2">
								<span
                    class="svg-icon svg-icon-3 svg-icon-gray-500 position-absolute top-50 translate-middle ms-6">
									<svg height="24px" version="1.1" viewBox="0 0 24 24" width="24px"
                       xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
										<g fill="none" fill-rule="evenodd" stroke="none" stroke-width="1">
											<rect height="24" width="24" x="0" y="0"></rect>
											<path
                          d="M14.2928932,16.7071068 C13.9023689,16.3165825 13.9023689,15.6834175 14.2928932,15.2928932 C14.6834175,14.9023689 15.3165825,14.9023689 15.7071068,15.2928932 L19.7071068,19.2928932 C20.0976311,19.6834175 20.0976311,20.3165825 19.7071068,20.7071068 C19.3165825,21.0976311 18.6834175,21.0976311 18.2928932,20.7071068 L14.2928932,16.7071068 Z"
                          fill="#000000" fill-rule="nonzero" opacity="0.3"></path>
											<path
                          d="M11,16 C13.7614237,16 16,13.7614237 16,11 C16,8.23857625 13.7614237,6 11,6 C8.23857625,6 6,8.23857625 6,11 C6,13.7614237 8.23857625,16 11,16 Z M11,18 C7.13400675,18 4,14.8659932 4,11 C4,7.13400675 7.13400675,4 11,4 C14.8659932,4 18,7.13400675 18,11 C18,14.8659932 14.8659932,18 11,18 Z"
                          fill="#000000" fill-rule="nonzero"></path>
										</g>
									</svg>
								</span>
                <!--end::Svg Icon-->
                <input id="sTitle" v-model="comboGiftVoucherECardListParams.title"
                       class="form-control form-control-solid ps-10" name="shopName" placeholder="卡券标题"
                       type="text" value=""/>
              </div>
              <!--end::Input group-->
              <!--begin:Action-->
              <div class="d-flex align-items-center">
                <div class="btn btn-primary me-5 btn-sm" @click="modifyAssignment(choiceIndex,$event)">
                  查询
                </div>
                <div class="btn btn-secondary me-5 btn-sm" @click="resetParameters">
                  重置
                </div>
                <a id="kt_horizontal_search_advanced_link" class="btn btn-link" data-bs-toggle="collapse"
                   href="#kt_advanced_search_form">高级查询</a>
              </div>
              <!--end:Action-->
              <div class="d-flex flex-wrap my-1 ms-auto">

                <!--begin::Actions-->
                <div style="margin-left: 5px">
                  <el-button v-if="choiceIndex == 0" round size="small" type="primary" @click="openDialogForm(1)">
                    创建积分卡券
                  </el-button>
                  <el-button v-if="choiceIndex == 1" round size="small" type="primary" @click="openDialogForm(2)">
                    创建现金卡券
                  </el-button>
                  <el-button v-if="choiceIndex == 2" round size="small" type="primary"
                             @click="openDialogForm">
                    创建多选一卡券
                  </el-button>
                </div>
              </div>
            </div>
            <!--end::Compact form-->

            <!--begin::高级搜索-->
            <div id="kt_advanced_search_form" class="collapse">
              <!--begin::Separator-->
              <div class="separator separator-dashed mt-9 mb-6"></div>
              <!--end::Separator-->
              <!--begin::Row-->
              <div class="row g-8 mb-8">

                <!--begin::Col-->
                <div class="col-lg-3">
                  <label class="fs-6 form-label fw-bolder text-dark">创建时间</label>
                  <br/>
                  <el-date-picker v-model="eCardDateRange" :picker-options="pickerOptions" align="right"
                                  class="w-100" end-placeholder="结束日期" range-separator="至"
                                  start-placeholder="开始日期"
                                  value-format="yyyy-MM-dd HH:mm:ss"
                                  type="datetimerange" unlink-panels>
                  </el-date-picker>
                </div>
                <!--end::Col-->
              </div>
              <!--end::Row-->
            </div>
            <!--end::Advance form-->
          </div>
          <!--end::Card body-->
        </div>
        <!--end::Card-->
      </form>
    </div>
    <!--   下半部分  -->
    <div id="kt_post" class="post d-flex flex-column-fluid " style="margin-bottom: 20px">
      <div id="kt_content_container" class="container"
           style="max-width: 98%; flex-direction: row !important; display: flex">
        <!-- 左侧 -->
        <div class="flex-column flex-lg-row-auto mb-8 mb-lg-0 me-lg-9 me-5" style="flex: 1;margin-top: 10px">
          <div class="card">
            <div class="card-body" style="padding: 1.2rem 1rem;
            display: flex;
            justify-content: center;">
              <div class="col-xl-9">
                <div style="margin: 20px auto">
                  <h3>卡券类型</h3>
                </div>
                <!--begin::Row-->
                <div class="row g-9 text-center" data-kt-buttons="true"
                     data-kt-buttons-target="[data-kt-button]">
                  <!--begin::Col-->
                  <div v-for="(item, index) in cardType" :style="index != 0 ? 'margin-top: 15px;' : ''"
                       :class="{'displayNone':item.hidden}">
                    <template>
                      <label :ref="'cardType' + index" :class="choiceIndex == index ? 'active' : ''"
                             class="btn btn-outline btn-outline-dashed btn-active-light-primary d-flex text-start p-6"
                             data-kt-button="true" style="height: 120px;"
                             @click="modifyAssignment(index, $event)">
                        <!--begin::Radio button-->
                        <span
                            class="form-check form-check-custom form-check-solid form-check-sm align-items-start mt-1">
												<input :value="index + 1" checked="checked" class="form-check-input"
                               name="usage" type="radio"/>
											</span>
                        <!--end::Radio button-->
                        <span class="ms-5">
												<span class="fs-4 fw-bold mb-1 d-block">{{ item.name }}</span>
												<span class="fw-semibold fs-7 text-gray-600">{{ item.info }}</span>
											</span>
                      </label>
                    </template>
                  </div>

                </div>
                <!--end::Row-->
              </div>

            </div>
          </div>
        </div>

        <!-- 右侧 -->
        <div class="mb-8 mb-lg-0" style="flex: 5;margin-top: 10px;min-height: 70vh">
          <div class="card">
            <div class="card-body" style="
              padding: 1.2rem 1rem;
              box-sizing: content-box;
              padding: 1rem !important;
            ">
              <div id='manageCard' class="manageCard" >
                <div id="scoreCard" class="scoreCard">
                  <div :style="eCardListObj.pages == 0 ? 'display: flex;justify-content: center; ' : ''"
                       class="cardBox">
                    <div v-if="eCardListObj.pages == 0">
                      <span>暂无数据</span>
                    </div>
                    <div v-for="(item, index) in eCardListObj.rows" :key="index" class="cardItem">
                      <div class="img imgBlock">
                        <img :src="item.picUrl" width="100%" height="100%" alt="">
                        <div class="cardItem_imgWrap" style="display:none;">

                        </div>
                        <div class="cardItem_block" style="display:none;">
                          <div style="display:flex;">
                            <div style="width:50%;">
                              <img style="width:80%;aspect-ratio:1/1;"
                                   :src="item.qrCodeUrl" alt="">
                              <div class="viewBtn" @click="openMobileModalBox(item)">预览</div>
                            </div>
                            <div style="display:flex;flex-direction: column;justify-content: center;color:#fff;">
                              <div style="font-size: 20px;font-weight:600;margin-bottom:10px;">{{ item.score }}元</div>
                              <div>有效截至日期:</div>
                              <div>{{ item.endTime }}</div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="cardTitle" style="text-align:center;line-height:30px;">
                        <div style="margin-top:5px;">{{ item.title }}</div>
                        <div v-if="choiceIndex==2">
                          <el-tag type="success" size="mini">{{ item.goodsNum }} 选 {{ item.selectNum }}</el-tag>
                        </div>
                      </div>
                      <div class="cardBtn">
                        <div style="display: flex;justify-content: space-between;margin: 0 100px;">
                          <a href="javascript:void(0)" style="color: #000"  @click="openGoodsList(item)"  v-if="choiceIndex===2">商品</a>
                          <a href="javascript:void(0)" style="color: #000" @click="openCardKey(item)">卡密</a>
                          <a href="javascript:void(0)" style="color: #000" @click="editECard(item)">编辑</a>
                          <a href="javascript:void(0)" style="color: #000" @click="deleteECard(item)">删除</a>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
                <div class="block" style="display: flex;
                justify-content: center;
                margin-top: 15px">
                  <el-pagination :current-page="eCardListObj.current" :page-size="eCardListObj.size"
                                 :page-sizes="[9, 45, 90, 135]" :total="eCardListObj.total"
                                 layout="total, sizes, prev, pager, next, jumper" @size-change="handleSizeChange"
                                 @current-change="handleCurrentChange">
                  </el-pagination>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--  创建组合礼包卡券弹窗  -->
    <ModalDialog ref="comboGiftVoucherECardDialog" :title="status===1?'创建礼包卡券':'编辑礼包卡券'" width="30%">
      <div style="margin-bottom: 60px;">
        <el-form ref="ruleForm" :model="comboGiftVoucherECardFrom" :rules="rules" class="demo-ruleForm"
                 label-width="100px" size="small">
          <!--上传框-->
          <div style="display: flex;
                justify-content: center;">
            <el-upload v-if="!imgSrc" ref="upload" :auto-upload="false" :file-list="filList"
                       :http-request="handleChange" :on-change="onChange" :show-file-list="false" action="string"
                       class="upload-demo" drag>
              <div>
                <i class="el-icon-upload"></i>
                <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
              </div>

              <div slot="tip" class="el-upload__tip">
                再次点击即可重新上传, 建议尺寸：472像素 * 300像素。
                <br>
                大小不超过2M，格式为PNG、JPG。
              </div>
            </el-upload>
            <el-upload v-if="imgSrc" ref="upload" :auto-upload="false" :file-list="filList"
                       :http-request="handleChange" :on-change="onChange" :show-file-list="false" action="string"
                       class="upload-demo">
              <img :src="imgSrc" alt="" style="height: 178px;aspect-ratio: 472/300">
              <div slot="tip" class="el-upload__tip">
                再次点击即可重新上传, 建议尺寸：472像素 * 300像素。
                <br>
                大小不超过2M，格式为PNG、JPG。
              </div>
            </el-upload>

          </div>
          <el-button size="mini" style="margin-left: 72px;margin-top: 10px;margin-bottom: 10px" type="primary"
                     @click="openVisible">选择模板
          </el-button>

          <el-form-item label="兑换规则:" prop="selectNum" style="margin-top: 15px;font-weight: bold;" v-if="choiceIndex===2">
            <el-input-number v-model="comboGiftVoucherECardFrom.selectNum" :min="1" controls-position="right"
                             size="mini">

            </el-input-number>
            <el-tooltip content="N选N中用户可选的礼包数量" effect="dark" placement="bottom" style="margin-left: 5px">
              <i class="el-icon-question"/>
            </el-tooltip>
          </el-form-item>

          <el-form-item label="卡券名称:" prop="title" style="font-weight: bold;">
            <el-input v-model="comboGiftVoucherECardFrom.title" placeholder="请输入卡券名称"
                      style="width: 330px"></el-input>
          </el-form-item>

          <el-form-item :rules="[
						{ required: true, message: '卡券面值不能为空' },
						{ type: 'number', message: '卡券面值必须为数字值' }]" label="卡券面值:" prop="score"
                        style="font-weight: bold;">
            <el-input v-model.number="comboGiftVoucherECardFrom.score" autocomplete="off" placeholder="请输入卡券面值"
                      style="width: 330px" :disabled="status===2"></el-input>
            <el-tooltip content="卡券创建后面值将无法修改，请谨慎填写！" effect="dark" placement="bottom"
                        style="margin-left: 5px">
              <i class="el-icon-question"/>
            </el-tooltip>
          </el-form-item>

          <el-form-item label="卡密规则:" prop="itemDigit" style="font-weight: bold;"  :rules="[
              { required: true, message: '卡密规则不能为空' }

          ]">
            <el-select v-model="comboGiftVoucherECardFrom.itemDigit" placeholder="请选择生成位数" style="width: 80px">
              <el-option label="6位" value="6"></el-option>
              <el-option label="7位" value="7"></el-option>
              <el-option label="8位" value="8"></el-option>
              <el-option label="9位" value="9"></el-option>
              <el-option label="10位" value="10"></el-option>
              <el-option label="11位" value="11"></el-option>
              <el-option label="12位" value="12"></el-option>
            </el-select>

            <el-select v-model="comboGiftVoucherECardFrom.itemRule" placeholder="请选择生成规则" style="width: 250px">
              <el-option label="纯数字" :value="1">
                <span style="float: left">纯数字</span>
                <span style="float: right; color: #8492a6; font-size: 13px">简单</span>
              </el-option>
              <el-option label="英文小写" :value="2">
                <span style="float: left">英文小写</span>
                <span style="float: right; color: #8492a6; font-size: 13px">简单</span>
              </el-option>
              <el-option label="英文大写" :value="3">
                <span style="float: left">英文大写</span>
                <span style="float: right; color: #8492a6; font-size: 13px">简单</span>
              </el-option>
              <el-option label="纯数字 + 英文小写" :value="4">
                <span style="float: left">纯数字 + 英文小写</span>
                <span style="float: right; color: #8492a6; font-size: 13px">一般</span>
              </el-option>
              <el-option label="纯数字 + 英文大写" :value="5">
                <span style="float: left">纯数字 + 英文大写 </span>
                <span style="float: right; color: #8492a6; font-size: 13px">一般</span>
              </el-option>
              <el-option label="纯数字 + 英文小大写" :value="6">
                <span style="float: left">纯数字 + 英文小大写</span>
                <span style="float: right; color: #8492a6; font-size: 13px">复杂</span>
              </el-option>
            </el-select>
            <el-tooltip effect="dark" placement="bottom" style="margin-left: 5px">
              <div slot="content">
                增加卡密长度提高密码安全性
                <br/>
                数字和大小写英文不包含：0、1、2、9、i、l、o、z、g、q、I、L、O、Z
              </div>
              <i class="el-icon-question"/>
            </el-tooltip>
          </el-form-item>
          <el-form-item label="绑定商城" prop="wxShopId" style="font-weight: bold;" v-if="choiceIndex!==2">
            <el-select v-model="comboGiftVoucherECardFrom.wxShopId" placeholder="请选择商城" style="width: 100%" @change="setShopTime">
              <el-option  v-for="(item,index) in shopList" :label="item.name" :value="item.id" :key="index"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item v-if="shopList.length===0 && choiceIndex!==2">
            <router-link to="/shop">
              你不存在商城,点击前往商城！
            </router-link>
            <!--跳转店铺-->
          </el-form-item>
          <el-form-item label="到期时间:" prop="endTime" style="font-weight: bold;">
            <el-date-picker v-model="comboGiftVoucherECardFrom.endTime" placeholder="选择日期时间" type="datetime" format="yyyy-MM-dd HH:mm:ss">
            </el-date-picker>
          </el-form-item>


          <el-form-item label="展示市场价:" prop="showPrice" style="font-weight: bold;" v-if="choiceIndex===2">
            <el-switch v-model="comboGiftVoucherECardFrom.showPrice" active-color="#13ce66"
                       inactive-color="#ff4949">
            </el-switch>

          </el-form-item>

          <el-form-item label="启用短信:" prop="reminder" style="font-weight: bold;" v-if="choiceIndex===2">
            <el-tooltip content="暂未开放" placement="top">
              <el-switch v-model="comboGiftVoucherECardFrom.reminder" active-color="#13ce66" disabled
                         inactive-color="#ff4949">
              </el-switch>
            </el-tooltip>
          </el-form-item>

        </el-form>
      </div>
      <div style="position: relative; display: flex; justify-content: flex-end;">
        <el-button @click="$refs.comboGiftVoucherECardDialog.close()">关 闭</el-button>
        <el-button type="primary" @click="submitComboGiftVoucherECard" v-if="status==1">提 交</el-button>
        <el-button type="primary" @click="submitComboGiftVoucherECard" v-if="status==2">保 存</el-button>

      </div>
    </ModalDialog>
    <!--选择模板弹窗-->
    <el-dialog :visible.sync="dialogVisible" title="提示" width="50%">
      <div>
        <div class="w-100">
          <div class="fv-row">
            <!--begin::Label-->
            <label class="d-flex align-items-center fs-5 fw-bold mb-4">
              <span class="required">请选择一个界面模板</span> <i class="fas fa-exclamation-circle ms-2 fs-7"
                                                                  data-bs-toggle="tooltip"
                                                                  title="选择一个界面作为小程序首页展示使用"></i>
            </label>

            <div class=" pb-5">
              <div class="row g-5 g-xl-8" style="margin-left: 0px;">
                <div v-for="(item, index) in templateList" :key="index" class="overlay col-xl-3">
                  <div class="overlay-wrapper"
                       style=" display: flex;justify-content: center;align-items: center;">
                    <img :src="item.frontPicUrl" alt="img" class="rounded w-200px h-200px">
                  </div>
                  <div class="overlay-layer bg-dark bg-opacity-10 rounded"
                       style="display: flex;flex-direction: column;">

                    <a class="btn btn-sm btn-primary btn-shadow" href="javascript:void(0)"
                       @click="chooseTemplate(item.id, item.frontPicUrl)">选择模板</a>
                    <!-- <a href="#" class="btn btn-sm btn-primary btn-shadow" style="margin-top:5px ;"
                        @click="view(item.picUrl)">查看大图</a> -->
                  </div>

                  <div class="fs-4" style="text-align: center;margin-top: 20px;">{{ item.title }}</div>
                  <a v-if="comboGiftVoucherECardListParams.voucherTemplateId === item.id"
                     class="btn btn-sm btn-light-primary bbbb" href="javascript:void(0)"
                     style="margin:15px auto;display:block;width:100px;">
										<span class="svg-icon svg-icon-3">
											<svg height="24px" version="1.1" viewBox="0 0 24 24" width="24px"
                           xmlns="http://www.w3.org/2000/svg"
                           xmlns:xlink="http://www.w3.org/1999/xlink">
												<g fill="none" fill-rule="evenodd" stroke="none" stroke-width="1">
													<polygon points="0 0 24 0 24 24 0 24"></polygon>
													<path
                              d="M9.26193932,16.6476484 C8.90425297,17.0684559 8.27315905,17.1196257 7.85235158,16.7619393 C7.43154411,16.404253 7.38037434,15.773159 7.73806068,15.3523516 L16.2380607,5.35235158 C16.6013618,4.92493855 17.2451015,4.87991302 17.6643638,5.25259068 L22.1643638,9.25259068 C22.5771466,9.6195087 22.6143273,10.2515811 22.2474093,10.6643638 C21.8804913,11.0771466 21.2484189,11.1143273 20.8356362,10.7474093 L17.0997854,7.42665306 L9.26193932,16.6476484 Z"
                              fill="#000000" fill-rule="nonzero" opacity="0.5"
                              transform="translate(14.999995, 11.000002) rotate(-180.000000) translate(-14.999995, -11.000002)">
													</path>

												</g>
											</svg>
										</span>
                    已选择
                  </a>
                </div>
              </div>

              <el-pagination :current-page="pageIndex" :page-size="12" :page-sizes="[12]" :total="count"
                             layout="total, sizes, prev, pager, next, jumper"
                             @current-change="handleCurrentChangeVoucher">
              </el-pagination>

            </div>
          </div>

        </div>

      </div>
      <span slot="footer" class="dialog-footer">
				<el-button @click="dialogVisible = false">取 消</el-button>
				<el-button type="primary" @click="dialogVisible = false">确 定</el-button>
			</span>
    </el-dialog>
    <!-- 卡密列表 -->
    <el-dialog title="卡密管理" :visible.sync="dialogTableVisible" width="98%" top="5vh">
      <e-card-list :id="id" :endTime="endTime" v-if="dialogTableVisible" :type="choiceIndex"></e-card-list>
    </el-dialog>
    <!-- 弹窗 挑选商品 -->
    <ModalDialog title="请挑选商品到卡券" ref="goodsListDialog" width="95%">
      <el-tabs v-model="activeName1" type="card" @tab-click="handleClick">
        <el-tab-pane label="商品池" name="first">
          <el-row :gutter="20">
            <el-col :span="18">
              <div>
                <div style="width: 100%">
                  <div class="card">
                    <div class="card-body" style="padding: 5px 5px !important;">
                      <div class="d-flex mb-3">
                        <div class="col-lg-2 me-2">
                          <label class="fs-4 form-label fw-bold">
                            &nbsp;
                          </label>
                          <br/>
                          <el-select v-model="paramss.type" placeholder="请选择" @change="shaixuan">
                            <div v-if="userRole.includes('vipMember')">
                              <el-option v-for="item in optionss_vip" :key="item.value" :label="item.label" :value="item.value">
                              </el-option>
                            </div>
                            <div v-else>
                              <el-option v-for="item in optionss" :key="item.value" :label="item.label" :value="item.value">
                              </el-option>
                            </div>
                          </el-select>
                        </div>
                        <div class="col-lg-3" v-if="paramss.type==2 && !userRole.includes('vipMember')">
                          <label class="fs-4 form-label fw-bold">商品来源</label>
                          <br/>
                          <el-select v-model="paramss.isJd" placeholder="请选择" @change="shaixuan">
                            <el-option v-for="item in jdType" :key="item.type" :label="item.name"
                                       :value="item.type">
                            </el-option>
                          </el-select>
                        </div>
                      </div>
                      <div class="d-flex align-items-center">
                        <div class="position-relative w-md-400px me-md-2">
                      <span class="svg-icon svg-icon-3 svg-icon-gray-500 position-absolute top-50 translate-middle ms-6">
                        <svg height="24px" version="1.1" viewBox="0 0 24 24" width="24px"
                             xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                          <g fill="none" fill-rule="evenodd" stroke="none" stroke-width="1">
                            <rect height="24" width="24" x="0" y="0"></rect>
                            <path
                                d="M14.2928932,16.7071068 C13.9023689,16.3165825 13.9023689,15.6834175 14.2928932,15.2928932 C14.6834175,14.9023689 15.3165825,14.9023689 15.7071068,15.2928932 L19.7071068,19.2928932 C20.0976311,19.6834175 20.0976311,20.3165825 19.7071068,20.7071068 C19.3165825,21.0976311 18.6834175,21.0976311 18.2928932,20.7071068 L14.2928932,16.7071068 Z"
                                fill="#000000" fill-rule="nonzero" opacity="0.3"></path>
                            <path
                                d="M11,16 C13.7614237,16 16,13.7614237 16,11 C16,8.23857625 13.7614237,6 11,6 C8.23857625,6 6,8.23857625 6,11 C6,13.7614237 8.23857625,16 11,16 Z M11,18 C7.13400675,18 4,14.8659932 4,11 C4,7.13400675 7.13400675,4 11,4 C14.8659932,4 18,7.13400675 18,11 C18,14.8659932 14.8659932,18 11,18 Z"
                                fill="#000000" fill-rule="nonzero"></path>
                          </g>
                        </svg>
                      </span>
                          <input id="sTitle" v-model="paramss.title" class="form-control form-control-solid ps-10"
                                 name="shopName" placeholder="商品名称" type="text" value=""/>
                        </div>
                        <div class="d-flex align-items-center">
                          <div class="btn btn-primary me-5 btn-sm" @click="shaixuan">
                            查询
                          </div>
                          <div class="btn btn-secondary me-5 btn-sm" @click="resetBtn">
                            重置
                          </div>
                          <a id="kt_horizontal_search_advanced_link" class="btn btn-link" data-bs-toggle="collapse"
                             href="#kt_advanced_search_form">高级查询</a>
                        </div>
                      </div>
                      <!--begin::高级搜索-->
                      <div id="kt_advanced_search_form" class="collapse">
                        <div class="separator separator-dashed mt-9 mb-6"></div>
                        <div class="row g-8 mb-8">
                          <div class="col-lg-3">
                            <label class="fs-6 form-label">一级分类</label>
                            <br/>
                            <el-select v-model="paramss.categoryId1" placeholder="请选择" @change="getCategory(1)">
                              <el-option v-for="item in categoryId1" :key="item.categoryId" :label="item.fullName"
                                         :value="item.categoryId">
                              </el-option>
                            </el-select>
                          </div>
                          <div class="col-lg-3">
                            <label class="fs-6 form-label">二级分类</label>
                            <br/>
                            <el-select v-model="paramss.categoryId2" placeholder="请选择" @change="getCategory(2)">
                              <el-option v-for="item in categoryId2" :key="item.categoryId" :label="item.fullName"
                                         :value="item.categoryId">
                              </el-option>
                            </el-select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="table-responsive" v-loading="loading" element-loading-text="拼命加载中"
                   element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)">
                <table class="table align-middle table-row-dashed table-hover fs-6 gy-5 dataTable no-footer">
                  <thead>
                  <tr class="text-start text-gray-500 fw-bold fs-7 text-uppercase gs-0">
                    <th class="min-w-300px" tabindex="0" aria-controls="kt_ecommerce_products_table" rowspan="1" colspan="1"
                        aria-label="Product: activate to sort column ascending" style="width: 212.141px">
                      商品信息
                    </th>
                    <th>
                      分类
                    </th>
                    <th class="text-center">
                      市场价
                    </th>
                    <th class="text-center">
                      分销价
                    </th>
                    <th class="text-center" v-if="paramss.type==1">
                      销售价
                    </th>
                    <th class="text-center">
                      利润率
                    </th>
                    <th class="text-center">
                      运费模板
                    </th>
                    <th class="text-center">
                      库存
                    </th>
                    <th class="text-center">
                      商品来源
                    </th>
                    <th class="text-center sorting_disabled" rowspan="1" colspan="1" aria-label="操作" style="width: 100px;">
                      操作
                    </th>
                  </tr>
                  </thead>
                  <tbody class="fw-semibold text-gray-600">
                  <tr v-for="(item, index) in selGoodsList" :class="{ old: index % 2 == 0, even: index % 2 != 0 }"
                      :key="index">
                    <td>
                      <div class="d-flex align-items-center">
                        <a href="javascript:void(0);" class="symbol symbol-50px">
                          <img class="symbol-label lozad" v-if="item.picUrl.indexOf('http') == 0" :src="`${item.picUrl}`"/>
                          <img class="symbol-label lozad" v-else :src="`https://www.3jzc.cn${item.picUrl}`"/>
                        </a>
                        <div class="ms-5">
                          <a href="javascript:void(0);" class="text-gray-800 text-hover-primary fs-5"
                             data-kt-ecommerce-product-filter="product_name">{{ item.title }}</a>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div v-html="item.categoryName"></div>
                    </td>
                    <td class="text-center">{{ item.price | format }}</td>
                    <td class="text-center">{{ item.supplPrice | format }}</td>
                    <td class="text-center" v-if="paramss.type==1">{{ item.salePrice | format }}</td>
                    <td class="text-center" v-html="item.rightRate" v-if="paramss.type==1"></td>
                    <td class="text-center" v-html="item.maxRightRate" v-else></td>
                    <td class="text-center" v-if="paramss.type==1">{{ item.freightId }}</td>
                    <td class="text-center" v-else-if="paramss.type==2 && item.isJD == 2">厂供模板</td>
                    <td class="text-center" v-else-if="paramss.type==2 && item.isJD == 1">京东模板</td>
                    <td class="text-center" v-if="paramss.type==1">{{ item.stock || 0 }}</td>
                    <td class="text-center" v-else-if="paramss.type==2 && item.isJD == 2">{{ item.stock || 0 }}</td>
                    <td class="text-center" v-else-if="paramss.type==2 && item.isJD == 1">99</td>
                    <td class="text-center" v-if="paramss.type==1">{{ item.type }}</td>
                    <td class="text-center" v-else-if="paramss.type==2 && item.isJD == 1">京东</td>
                    <td class="text-center" v-else-if="paramss.type==2 && item.isJD == 2">自有</td>
                    <!-- 操作 -->
                    <td class="text-center">
                      <el-link type="success" @click="addGoods(item)" v-if="!idsArr.includes(item.id.toString()) && (item.isJd==2|| item.isJD==2)">添加</el-link>
                      <a class="text-gray-800 fs-5" v-if="idsArr.includes(item.id.toString()) && (item.isJd==2|| item.isJD==2)">已添加</a>
                      <el-link type="success" @click="addGoods(item)" v-if="!idsArr.includes(item.skuId+'')&& (item.isJd==1|| item.isJD==1)">添加</el-link>
                      <a class="text-gray-800 fs-5" v-if="idsArr.includes(item.skuId+'')&& (item.isJd==1 || item.isJD==1)">已添加</a>
                    </td>
                  </tr>
                  </tbody>
                </table>
                <Pagination :total-items="totalCount" @page-change="pageChange" ref="Pagination"></Pagination>
              </div>
            </el-col>
            <el-col :span="6">
              <div style="display: flex;justify-content:space-between;">
                <h4>已选商品<span style="font-size:12px;margin-left: 5px;">(共选择 {{ goodList.length }} 个商品)</span></h4>
                <el-button type="danger" @click="clearSelectedGoods" v-if="goodsIds.length" size="small">清空已选商品</el-button>
              </div>
              <div class="table-responsive">
                <table class="table align-middle table-row-dashed table-hover fs-6 gy-5 dataTable no-footer">
                  <thead>
                  <tr class="text-start text-gray-500 fw-bold fs-7 text-uppercase gs-0">
                    <th class="min-w-200px" tabindex="0" aria-controls="kt_ecommerce_products_table" rowspan="1" colspan="1"
                        aria-label="Product: activate to sort column ascending" style="width: 212.141px">
                      商品信息
                    </th>
                    <th class="text-center sorting_disabled" rowspan="1" colspan="1" aria-label="操作" style="width: 80px">
                      操作
                    </th>
                  </tr>
                  </thead>
                  <tbody class="fw-semibold text-gray-600">
                  <tr v-for="(item, index) in goodList" :class="{ old: index % 2 == 0, even: index % 2 != 0 }" :key="index">
                    <td>
                      <div class="d-flex align-items-center">
                        <a href="javascript:void(0);" class="symbol symbol-50px">
                          <img class="symbol-label lozad" v-if="item.img.indexOf('http') == 0" :src="`${item.img}`"/>
                          <img class="symbol-label lozad" v-else :src="`https://www.3jzc.cn${item.img}`"/>
                        </a>
                        <div class="ms-5">
                          <a href="javascript:void(0);" class="text-gray-800 text-hover-primary fs-5"
                             data-kt-ecommerce-product-filter="product_name">{{ item.title }}</a>
                        </div>
                      </div>
                    </td>

                    <!-- 操作 -->
                    <td class="text-end">
                      <el-link type="danger" @click="delGroupGoods(item)">删除</el-link>
                    </td>
                  </tr>
                  </tbody>
                </table>

              </div>
            </el-col>
          </el-row>
        </el-tab-pane>
        <el-tab-pane label="组合礼包" name="second">
          <el-row :gutter="20">
            <el-col :span="18">
              <el-table :data="tableData"
                        element-loading-text="拼命加载中" element-loading-spinner="el-icon-loading"
                        element-loading-background="rgba(0, 0, 0, 0.8)" v-loading="loading">
                <el-table-column label="礼包图片" fixed width="100" align="center">
                  <template slot-scope="props">
                    <img style="width:70px;height:70px;" :src="props.row.imageUrl" alt="">
                  </template>
                </el-table-column>
                <el-table-column fixed prop="name" label="礼包名称" width="170" align="center">
                </el-table-column>
                <el-table-column prop="giftCode" label="礼包编码" width="200" align="center">
                </el-table-column>
                <el-table-column label="礼包分类" width="100">
                  <template slot-scope="props">
                    <span>{{ props.row.categoryId1Name }}</span><br/>
                    <span>{{ props.row.categoryId2Name }}</span>
                  </template>
                </el-table-column>
                <el-table-column label="展示价" width="100" align="center">
                  <template slot-scope="props">
                    <span>{{ props.row.giftShowPrice | format }}</span>
                  </template>
                </el-table-column>
                <el-table-column label="市场价" width="100" align="center">
                  <template slot-scope="props">
                    <span>{{ props.row.giftPrice | format }}</span>
                  </template>
                </el-table-column>
                <el-table-column label="供货价" width="100" align="center">
                  <template slot-scope="props">
                    <span>{{ props.row.giftSupplyPrice | format }}</span>
                  </template>
                </el-table-column>
                <!--<el-table-column prop="giftSalePrice" label="销售价" width="100" sortable align="center">
                </el-table-column>-->
                <el-table-column label="利润率" width="100" sortable align="center">
                  <template slot-scope="props">
                    <span>{{  props.row.giftRate.toFixed(2)  }} %</span>
                  </template>
                </el-table-column>
                <el-table-column prop="giftTotalGoods" label="商品数" width="100" align="center">
                </el-table-column>
                <el-table-column label="单品详情" width="100" align="center">
                  <template slot-scope="props">
                    <span v-if="props.row.showSingle === 1">显示</span>
                    <span v-else>省略</span>
                  </template>
                </el-table-column>
                <el-table-column prop="state" label="状态" width="100" align="center">
                  <template slot-scope="props">
                    <span v-if="props.row.isShow === 1">启用</span>
                    <span v-else>停用</span>
                  </template>
                </el-table-column>
                <el-table-column prop="state" label="操作" width="100" align="center" fixed="right">
                  <template slot-scope="props">
                    <el-button @click="addGoods(props.row,1)" type="text" size="small" v-if="!idsArr.includes(props.row.id)">添加</el-button>
                    <a v-else>已添加</a>
                  </template>
                </el-table-column>
              </el-table>
              <Pagination :total-items="totalCount1" @page-change="pageChange1" ref="Pagination"></Pagination>
            </el-col>
            <el-col :span="6">
              <div style="display: flex;justify-content:space-between;">
                <h4>已选商品<span style="font-size:12px;margin-left: 5px;">(共选择 {{ goodList.length }} 个商品)</span></h4>
                <el-button type="danger" @click="clearSelectedGoods" v-if="goodsIds.length" size="small">清空已选商品</el-button>
              </div>
              <div class="table-responsive">
                <table class="table align-middle table-row-dashed table-hover fs-6 gy-5 dataTable no-footer">
                  <thead>
                  <tr class="text-start text-gray-500 fw-bold fs-7 text-uppercase gs-0">
                    <th class="min-w-200px" tabindex="0" aria-controls="kt_ecommerce_products_table" rowspan="1" colspan="1"
                        aria-label="Product: activate to sort column ascending" style="width: 212.141px">
                      商品信息
                    </th>
                    <th class="text-center sorting_disabled" rowspan="1" colspan="1" aria-label="操作" style="width: 80px">
                      操作
                    </th>
                  </tr>
                  </thead>
                  <tbody class="fw-semibold text-gray-600">
                  <tr v-for="(item, index) in goodList" :class="{ old: index % 2 == 0, even: index % 2 != 0 }" :key="index">
                    <td>
                      <div class="d-flex align-items-center">
                        <a href="javascript:void(0);" class="symbol symbol-50px">
                          <img class="symbol-label lozad" v-if="item.img.indexOf('http') == 0" :src="`${item.img}`"/>
                          <img class="symbol-label lozad" v-else :src="`https://www.3jzc.cn${item.img}`"/>
                        </a>
                        <div class="ms-5">
                          <a href="javascript:void(0);" class="text-gray-800 text-hover-primary fs-5"
                             data-kt-ecommerce-product-filter="product_name">{{ item.title }}</a>
                        </div>
                      </div>
                    </td>

                    <!-- 操作 -->
                    <td class="text-end">
                      <el-link type="danger" @click="delGroupGoods(item)">删除</el-link>
                    </td>
                  </tr>
                  </tbody>
                </table>

              </div>
            </el-col>
          </el-row>
        </el-tab-pane>
      </el-tabs>

    </ModalDialog>
    <MobileModalBox  ref="MobileModalBox"/>
  </div>
  <!--begin::Wrapper container-->
</template>

<script>


import {mixins} from "./mixins";
import ECardList from "@/views/eCardNew/component/eCardList.vue";

export default {
  components: {ECardList},
  mixins: [mixins]
}
</script>
<style>
.cardTitle {
  display: block;
}

.cardBtn {
  display: none;
  margin-top: 10px;
}

.cardItem:hover .cardTitle {
  display: none;
}

.cardItem:hover .cardBtn {
  display: block;
}

.cardItem:hover .cardItem_imgWrap {
  width: 100%;
  display: block !important;
  margin: 0 auto;
  aspect-ratio: 340 / 213;
  border-radius: 15px;
  box-shadow: 0 10px 30px -12px rgba(0, 0, 0, 0.42), 0 4px 25px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(5px);
  filter: blur(5px);
  background: rgba(74, 74, 74, 0.5);
  position: absolute;
  top: 0;
  left: 0;
}

.cardItem:hover .cardItem_block {
  width: 100%;
  display: block !important;
  position: absolute;
  z-index: 99;
  top: 0;
  left: 0;
  padding: 20px;
  box-sizing: border-box;
  cursor: pointer;
}

.cardItem_block img {
  display: block;
}
</style>

<style scoped>
.app-content {
  padding-top: 0 !important;
}

/* 添加以下样式以使图像居中 */
.eCard_flow {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.manageCard {
  width: 100%;
  background: #fff;
  padding: 10px;
  border-radius: 6px;
  overflow-y: auto;
}

.cardBox {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 30px 79px;
  background: #f7f7f7;
}

.cardItem {
  margin-bottom: 40px;
  margin-right: 20px;
  border: 1px solid #d9d9d9;
  height: 264px;
  aspect-ratio: 380 / 264;
  border-radius: 6px;
  background: #fff;
  position: relative;
}


.cardItem > .img {
  width: 90%;
  display: block;
  margin: 0 auto;
  margin-top: -20px;
  aspect-ratio: 340 / 213;
  position: relative;
  border-radius: 15px;
}

.cardState {
  border-radius: 0 15px 0 15px;
  position: absolute;
  top: 2px;
  right: 2px;
  width: 67px;
  height: 24px;
  background: #39c113;
  z-index: 200;
  text-align: center;
  line-height: 24px;
  color: #fff;
}

.searchBox {
  display: flex;
  padding: 15px 0;
  background: #fff;
}

.btn.btn-active-secondary {
  font-size: 16px !important;
}

.viewBtn {
  width: 80%;
  height: 24px;
  text-align: center;
  background: #009ef7;
  color: #fff;
  line-height: 24px;
  margin-top: 15px;
}
.displayNone{
  display: none;
}
</style>
