import {
    getVipCollectiveProcurementListApi,
    getVipCollectiveProcurementListMyApi,
    setVipCollectiveProcurementIsOverApi
} from "@/api/VipCollectiveProcurementApi";

export const mixins = {
    data() {
        return {
            vipStatus: [
                {
                    title: "集采订单"
                },
                {
                    title: "我的集采"
                }
            ],
            index: 1,
            collOrder: [],
            vipCollectiveProcurementParams: {
                current: 1,
                size: 10
            },
            collMyOrder: [],
            total: 0,
        }

    },
    methods: {
        jumpIndex(index) {
            this.index = index;
            this.vipCollectiveProcurementParams.current = 1
            this.vipCollectiveProcurementParams.size = 10
            if (index == 1) {
                this.getCollOrder()
            } else {
                this.getMyCollOrder()
            }
        },
        async getCollOrder() {
            let {data} = await getVipCollectiveProcurementListApi(this.vipCollectiveProcurementParams)
            this.collOrder = data.rows
            this.vipCollectiveProcurementParams.current = data.current
            this.vipCollectiveProcurementParams.size = data.size
            this.total = data.total
        },
        async getMyCollOrder() {
            let {data} = await getVipCollectiveProcurementListMyApi(this.vipCollectiveProcurementParams)
            this.collMyOrder = data.rows
            this.vipCollectiveProcurementParams.current = data.current
            this.vipCollectiveProcurementParams.size = data.size
            this.total = data.total
        },
        async setIsOver(id) {
            await setVipCollectiveProcurementIsOverApi({id: id})
            this.getCollOrder()
        },
        handleSizeChange(val) {
            this.vipCollectiveProcurementParams.size = val
        },
        handleCurrentChange(val) {
            this.vipCollectiveProcurementParams.current = val
        }
    },
    created() {
        this.getCollOrder()
    }
}
