<template>
  <div id="kt_post" class="post flex-column-fluid" style="min-height: 87vh">
    <!--    顶部标题-->
    <TitleCard style="margin: 10px auto; max-width: 97%" title="集采订单管理">
      <template v-slot:subTitle>
        <ul class="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
          <li class="breadcrumb-item text-muted">Order Management</li>
        </ul>
      </template>
    </TitleCard>

    <!-- 下半-->
    <div id="kt_post" class="post d-flex flex-column-fluid " style="margin-bottom: 20px">
      <div id="kt_content_container" class="container"
           style="max-width: 98%; flex-direction: row !important; display: flex">
        <div class="d-flex flex-column flex-column-fluid">
          <div class="card card-flush" style="position: relative">
            <div class="card-body">
              <div>
                <ul
                    class="nav nav-tabs nav-line-tabs nav-line-tabs-2x border-transparent fs-4 fw-semibold"
                >
                  <li
                      v-for="(item, i) in vipStatus"
                      :key="i"
                      class="nav-item"
                  >

                    <a
                        :ref="'page'+(i + 1)"
                        :class="index === i + 1 ? 'active' : ''"
                        :href="`#kt_tab_pane_${i + 1}`"
                        class="nav-link text-active-primary"
                        data-bs-toggle="tab"
                        style="
                          font-size: 13px;
                          font-weight: bold;
                          text-align: center
                        "
                        @click="jumpIndex(i + 1)"

                    >
                      {{ item.title }}</a
                    >

                  </li>
                </ul>
              </div>
              <div style="margin-top: 10px; min-height: 65vh">
                <el-table v-if="index == 1"
                          :data="collOrder"
                          border
                          style="width: 100%">
                  <el-table-column
                      label="商品名"
                      prop="goodsName"
                      width="150">
                  </el-table-column>
                  <el-table-column
                      label="联系人"
                      prop="contacts"
                      width="120">
                  </el-table-column>
                  <el-table-column
                      label="手机号"
                      prop="tel"
                      width="200">
                  </el-table-column>
                  <el-table-columnaddress
                      label="数量"
                      prop="city"
                      width="num">
                  </el-table-columnaddress>
                  <el-table-column
                      label="地址"
                      prop="address"
                  >
                  </el-table-column>
                  <el-table-column
                      label="配送区域"
                      prop="shippingMethod"
                  >
                  </el-table-column>
                  <el-table-column
                      label="单价"
                      prop="price"
                  >
                  </el-table-column>
                  <el-table-column
                      label="备注"
                      prop="remark"
                  >

                  </el-table-column>
                  <el-table-column
                      label="创建时间"
                      prop="createTime"
                  >

                  </el-table-column>
                  <el-table-column
                      label="处理时间"
                      prop="overTime"
                  >
                  </el-table-column>
                  <el-table-column
                      fixed="right"
                      label="操作"
                      width="100">
                    <template slot-scope="scope">
                      <el-button v-if="!scope.row.isOver" round size="mini" type="primary"
                                 @click="setIsOver(scope.row.id)">
                        标记已读
                      </el-button>
                    </template>
                  </el-table-column>
                </el-table>
                <el-table v-if="index == 2"
                          :data="collMyOrder"
                          border
                          style="width: 100%">
                  <el-table-column
                      label="商品名"
                      prop="goodsName"
                      width="150">
                  </el-table-column>
                  <el-table-column
                      label="联系人"
                      prop="contacts"
                      width="120">
                  </el-table-column>
                  <el-table-column
                      label="手机号"
                      prop="tel"
                      width="200">
                  </el-table-column>
                  <el-table-columnaddress
                      label="数量"
                      prop="city"
                      width="num">
                  </el-table-columnaddress>
                  <el-table-column
                      label="地址"
                      prop="address"
                  >
                  </el-table-column>
                  <el-table-column
                      label="配送区域"
                      prop="shippingMethod"
                  >
                  </el-table-column>
                  <el-table-column
                      label="单价"
                      prop="price"
                  >
                  </el-table-column>
                  <el-table-column
                      label="备注"
                      prop="remark"
                  >

                  </el-table-column>
                  <el-table-column
                      label="创建时间"
                      prop="createTime"
                  >

                  </el-table-column>
                  <el-table-column
                      label="处理时间"
                      prop="overTime"
                  >
                  </el-table-column>
                  <el-table-column
                      fixed="right"
                      label="状态"
                      width="100">
                    <template slot-scope="scope">
                      {{ scope.row.isOver ? "已处理" : "未处理" }}
                    </template>
                  </el-table-column>
                </el-table>
              </div>
              <div style="display: flex;justify-content: center;">
                <el-pagination
                    :current-page="vipCollectiveProcurementParams.current"
                    :page-size="vipCollectiveProcurementParams.size"
                    :page-sizes="[10, 20, 50, 100]"
                    :total="total"
                    layout="total, sizes, prev, pager, next, jumper"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange">
                </el-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>
<script>

import {mixins} from "@/views/vipCollectiveProcurementOrder/mixins";

export default {
  name: "VipCollectiveProcurementOrder",
  mixins: [mixins],
}


</script>
<style scoped>

</style>
<script setup>
</script>
