import {getVipBulletinApi} from "@/api/vipBulletinApi";
import sd from "silly-datetime";

export const bulletinBoard = {
    data(){
        return{
            params:{
                current:1,
                size:10,
            },
            boardList:[],
            total: 0,
            bulletinInfo: false,
            item: {}
        }
    },
    filters:{
        formatDate(val){
            return  sd.format(new Date(val),"YYYY年MM月DD日 HH时mm分ss秒");
        }
    },
    methods:{
        openInfo(item) {
            this.item = item;
            this.bulletinInfo = true;
        },
        async getList(){
            let {data}=await getVipBulletinApi(this.params)
            this.boardList=data.rows;
            this.total=data.total
        },
        handleSizeChange(val) {
            this.params.size=val
            this.getList()
        },
        handleCurrentChange(val) {
            this.params.current=val
            this.getList()
        },
    },
    created() {
        this.getList()
    }
}
