import service from "@/utils/request"

export function getVipCollectiveProcurementListApi(params) {
    return service({
        url: '/vip_collective_procurement/getList',
        method: 'get',
        params
    })
}

export function vipCollectiveProcurementSaveApi(data) {
    return service({
        url: '/vip_collective_procurement/add',
        method: 'post',
        data
    })
}

export function getVipCollectiveProcurementListMyApi(params) {
    return service({
        url: '/vip_collective_procurement/getMyList',
        method: 'get',
        params
    })
}

export function setVipCollectiveProcurementIsOverApi(params) {
    return service({
        url: '/vip_collective_procurement/setIsOver',
        method: 'get',
        params
    })
}

export function getVipCollectiveProcurementNotOverCountApi(params) {
    return service({
        url: '/vip_collective_procurement/getNotOverCount',
        method: 'get',
        params
    })
}
