<template>
    <div>
      <VipPayComponent ref="VipPayComponent" @checkPay="checkPay"></VipPayComponent>
        <form action="#">
            <!--begin::Card-->
            <div class="card mb-7" style="border-top-left-radius: 0px;border-top-right-radius: 0px">
                <!--begin::Card body-->
                <div class="card-body">
                    <!--begin::Compact form-->
                    <div class="d-flex align-items-center">
                        <!--begin::Input group-->
                        <div style="display: flex;flex-direction: row">
                          <div class="position-relative w-md-400px me-md-2">
                            <span
                                class="svg-icon svg-icon-3 svg-icon-gray-500 position-absolute top-50 translate-middle ms-6">
                                <svg xmlns="http://www.w3.org/2000/svg"
                                     width="24px" height="24px" viewBox="0 0 24 24">
                                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                        <rect x="0" y="0" width="24" height="24"></rect>
                                        <path
                                            d="M14.2928932,16.7071068 C13.9023689,16.3165825 13.9023689,15.6834175 14.2928932,15.2928932 C14.6834175,14.9023689 15.3165825,14.9023689 15.7071068,15.2928932 L19.7071068,19.2928932 C20.0976311,19.6834175 20.0976311,20.3165825 19.7071068,20.7071068 C19.3165825,21.0976311 18.6834175,21.0976311 18.2928932,20.7071068 L14.2928932,16.7071068 Z"
                                            fill="#000000" fill-rule="nonzero" opacity="0.3"></path>
                                        <path
                                            d="M11,16 C13.7614237,16 16,13.7614237 16,11 C16,8.23857625 13.7614237,6 11,6 C8.23857625,6 6,8.23857625 6,11 C6,13.7614237 8.23857625,16 11,16 Z M11,18 C7.13400675,18 4,14.8659932 4,11 C4,7.13400675 7.13400675,4 11,4 C14.8659932,4 18,7.13400675 18,11 C18,14.8659932 14.8659932,18 11,18 Z"
                                            fill="#000000" fill-rule="nonzero"></path>
                                    </g>
                                </svg>
                            </span>
                            <!--end::Svg Icon-->
                            <input type="text" class="form-control form-control-solid ps-10" id="sTitle" name="shopName"
                                   value="" placeholder="商城标题" v-model="queryParams.shopName">
                          </div>
                          <div class="d-flex align-items-center">
                            <div class="btn btn-primary me-5" @click="resetGetShopList">查询</div>
                            <a id="kt_horizontal_search_advanced_link" class="btn btn-link" data-bs-toggle="collapse"
                               href="#kt_advanced_search_form">高级查询</a>
                          </div>
                        </div>

                        <!--end::Input group-->
                        <!--begin:Action-->

                        <!--end:Action-->
                        <div style="margin-right: 10px">
                          <!--begin::Actions-->

                          <!--end::Actions-->
                        </div>
                    </div>
                    <!--end::Compact form-->
                    <!--begin::高级搜索-->
                    <div class="collapse" id="kt_advanced_search_form">
                        <!--begin::Separator-->
                        <div class="separator separator-dashed mt-9 mb-6"></div>
                        <!--end::Separator-->
                        <!--begin::Row-->
                        <div class="row g-8 mb-8">
                            <div class="col-lg-3">
                                <label class="fs-6 form-label fw-bolder text-dark">商城状态</label>
                                <!--begin::Select-->
                                <br>
                                <el-select v-model="queryParams.shopStatus" placeholder="请选择">
                                    <el-option value="0" label="全部">全部</el-option>
                                    <el-option value="1" label="使用中">使用中</el-option>
                                    <el-option value="4" label="已过期">已到期</el-option>
                                    <el-option value="2" label="已停用">已停用</el-option>
                                </el-select>
                                <!-- <select  v-model="queryParams.shopStatus" class="form-select form-select-solid"
                                    data-control="select2" data-placeholder="请选择" data-hide-search="true" id="shopStatus"
                                    name="shopStatus">
                                    <option value="">请选择</option>
                                    <option value="1">使用中</option>
                                    <option value="2">已过期</option>
                                    <option value="3">已停用</option>

                                </select> -->
                            </div>

                            <!--begin::Col-->
                            <div class="col-lg-4">
                                <label class="fs-6 form-label fw-bolder text-dark">商城创建日期区间</label>
                                <!-- <input class="form-control form-control-solid" placeholder="选择日期区间" id="shopCreateDate" /> -->
                                <br>
                                <el-date-picker v-model="createDateRange" type="daterange" align="right" unlink-panels
                                    range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"
                                    value-format="yyyy-MM-dd" :picker-options="pickerOptions" @change="startDateChange">
                                </el-date-picker>
                            </div>
                            <!--end::Col-->

                            <!--begin::Col-->
                            <div class="col-lg-4">
                                <label class="fs-6 form-label fw-bolder text-dark">商城到期日期区间</label>
                                <br>
                                <el-date-picker v-model="stopDateRange" type="daterange" align="right" unlink-panels
                                    range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"
                                    value-format="yyyy-MM-dd" :picker-options="pickerOptions" @change="stopDateChange">
                                </el-date-picker>
                            </div>
                            <!--end::Col-->
                        </div>
                        <!--end::Row-->
                    </div>
                    <!--end::Advance form-->
                </div>
                <!--end::Card body-->
            </div>
            <!--end::Card-->
        </form>
        <!--begin::Post-->
        <div class="post d-flex flex-column-fluid" id="kt_post">
            <!--begin::Container-->
            <div id="kt_content_container" class="container" style="max-width:100%;">
                <div class="row g-5 g-xl-8">
                    <div class="col-xl-3" v-for="(item, index) in rows" :key="index">
                        <div class="template_wrap" style="background-color: white;padding:10px;border-radius:10px;">
                            <!--begin::Mixed Widget 2-->
                            <div class="template_msg">
                                <div :id="'shopList_' + index" style="position:relative;">
                                    <div class="code_wrap" v-if="code_show && code_id == 'shopList_' + index">
                                        <div v-if="item.publication===2">
                                          <div class="code_content"  style="margin-top: -65px">
                                            <img style="border-radius:inherit;width: 100%" :src="qrCodeUrl" alt=""/>
                                          </div>
                                          <div class="text_content">
                                            <el-link @click="copyUrl" type="primary" :data-clipboard-text="'https://h5.3jzc.cn/?shopId='+item.id" class="copyUrl">点击复制链接</el-link>
                                          </div>

                                        </div>

                                        <img v-else class="code_content" style="top:50%" src="https://www.3jzc.cn/Uploads/weixinerweima/zhelidao.jpg" alt="" />

                                    </div>
                                    <div style="display: flex; align-items: center;" >
                                        <div style="width: 40%">
                                          <div class="thumbnailUrl">
                                            <img :src="item.thumbnailUrl" alt="" style="width: 90%; height: auto;border-radius: 6px;">
                                            <div class="wrap">
                                              <div class="wrapper" @click="openMobileModalBox(item.id)">
                                                <div class="preview cursor-pointer">
                                                  <i class="el-icon-view"></i> 在线预览
                                                </div>

                                              </div>
                                            </div>
                                          </div>
                                        </div>

                                        <div style="width: 56%;">
                                            <div style="display: flex;align-items: center;justify-content:space-between;padding-right:10px;">
                                                <div
                                                    style="font-size: 17px;max-width:67%; white-space: nowrap;overflow: hidden;text-overflow: ellipsis;font-weight: 600;">
                                                    {{ item.name }}
                                                </div>
                                                <button type="button"
                                                    class="btn btn-sm btn-icon btn-color-primary btn-active-light-primary border-1 me-n3"
                                                    data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end"
                                                    data-kt-menu-flip="top-end" @click="setCurrentWxShopId(item.id,7)"
                                                    style="width:80px;">
                                                    <!--begin::Svg Icon | path: icons/duotone/Layout/Layout-4-blocks-2.svg-->
                                                    <span class="svg-icon svg-icon-2"> <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width="34px"
                                                            height="24px" viewBox="0 0 24 24">
                                                            <g stroke="none" stroke-width="1" fill="none"
                                                                fill-rule="evenodd">
                                                                <rect x="5" y="5" width="5" height="5" rx="1"
                                                                    fill="#000000">
                                                                </rect>
                                                                <rect x="14" y="5" width="5" height="5" rx="1"
                                                                    fill="#000000" opacity="0.3">
                                                                </rect>
                                                                <rect x="5" y="14" width="5" height="5" rx="1"
                                                                    fill="#000000" opacity="0.3">
                                                                </rect>
                                                                <rect x="14" y="14" width="5" height="5" rx="1"
                                                                    fill="#000000" opacity="0.3">
                                                                </rect>
                                                            </g>
                                                        </svg> </span> <!--end::Svg Icon--> <span
                                                        class="svg-icon svg-icon-2">复制
                                                    </span>
                                                </button>
                                            </div>
                                            <div style="font-size: 13px;margin-top: 8px;">创建时间：{{ item.crTime }}
                                            </div>
                                            <div style="font-size: 13px;margin-top: 4px;">到期时间：{{ item.endTime }}
                                            </div>
                                            <div style="font-size: 13px;margin-top: 4px;display: flex;align-items: center;">
                                                <div>状  态:</div>
                                                <div  v-if="item.statusStr=='已到期'"
                                                    style="color: red;border: 1px solid red;padding: 0 4px;margin-left: 6px; border-radius: 2px;">
                                                    {{ item.statusStr }}</div>
                                              <div  v-else-if="item.statusStr=='已停用'"
                                                    style="color: #c47505;border: 1px solid #c47505;padding: 0 4px;margin-left: 6px; border-radius: 2px;">
                                                {{ item.statusStr }}</div>
                                              <div  v-else
                                                    style="color: #38af04;border: 1px solid #38af04;padding: 0 4px;margin-left: 6px; border-radius: 2px;">
                                                {{ item.statusStr }}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="data_wrap mt-4"
                                        style="display: flex;justify-content:space-around;color:#071437;;">
                                        <div class="data_item text-hover-primary" data-bs-toggle="modal"
                                            data-bs-target="#myShop_modal_users" style="cursor:pointer;"
                                            @click="setCurrentWxShopId(item.id,2,item)">
                                            <div style="font-size: 13px;">用户数量</div>
                                            <div style="font-size: 16px; color: rgb(0, 158, 247);text-align: center;">{{
                                                item.userNum }}</div>
                                        </div>
                                        <div class="data_item text-hover-primary" data-bs-toggle="modal"
                                            data-bs-target="#myShop_modal_Product" style="cursor:pointer;"
                                            @click="setCurrentWxShopId(item.id,1)">
                                            <div style="font-size: 13px;">商品数量</div>
                                            <div style="font-size: 16px;color: rgb(0, 158, 247);text-align: center;">{{
                                                item.goodsNum }}</div>
                                        </div>
                                        <div class="data_item text-hover-primary" data-bs-toggle="modal"
                                            data-bs-target="#myShop_modal_Order" style="cursor:pointer;"
                                            @click="setCurrentWxShopId(item.id,3)">
                                            <div style="font-size: 13px;">订单总数</div>
                                            <div style="font-size: 16px;color: rgb(0, 158, 247);text-align: center;">{{
                                                item.orderNum }}</div>
                                        </div>
                                    </div>
                                </div>

                                <div style="display: flex; align-items: center;" >
                                    <div @click="openQrcode('shopList_' + index, item.id)" class="col cursor-pointer" style="cursor:pointer; border-right: 1px solid #ddd;">
                                        <span class="svg-icon svg-icon-2hx svg-icon-primary d-block my-2 text-center">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor"
                                                class="bi bi-qr-code text-muted" viewBox="0.7 0 14 16"
                                                style="width: 20px; height: 20px !important;">
                                                <path d="M2 2h2v2H2V2Z"></path>
                                                <path d="M6 0v6H0V0h6ZM5 1H1v4h4V1ZM4 12H2v2h2v-2Z">
                                                </path>
                                                <path d="M6 10v6H0v-6h6Zm-5 1v4h4v-4H1Zm11-9h2v2h-2V2Z">
                                                </path>
                                                <path
                                                    d="M10 0v6h6V0h-6Zm5 1v4h-4V1h4ZM8 1V0h1v2H8v2H7V1h1Zm0 5V4h1v2H8ZM6 8V7h1V6h1v2h1V7h5v1h-4v1H7V8H6Zm0 0v1H2V8H1v1H0V7h3v1h3Zm10 1h-1V7h1v2Zm-1 0h-1v2h2v-1h-1V9Zm-4 0h2v1h-1v1h-1V9Zm2 3v-1h-1v1h-1v1H9v1h3v-2h1Zm0 0h3v1h-2v1h-1v-2Zm-4-1v1h1v-2H7v1h2Z">
                                                </path>
                                                <path d="M7 12h1v3h4v1H7v-4Zm9 2v2h-3v-1h2v-1h1Z">
                                                </path>
                                            </svg> </span> <span
                                            class="svg-icon svg-icon-3x svg-icon-primary d-block my-2 text-center">
                                            <a href="javascript:void(0)" class="text-dark fs-6 text-hover-primary">二维码</a></span>
                                    </div>
                                    <div class="col cursor-pointer" data-bs-toggle="modal"
                                        data-bs-target="#myShop_modal_Voucher"
                                         v-if="item.shopType===1"
                                        style="cursor:pointer; border-right: 1px solid #ddd;"
                                        @click="setCurrentWxShopId(item.id,6)"> <span
                                            class="svg-icon svg-icon-2hx svg-icon-primary d-block my-2 text-center">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor"
                                                class="bi bi-wallet-fill text-muted" viewBox="0.7 0 14 16"
                                                style="width: 20px; height: 20px !important;">
                                                <path
                                                    d="M1.5 2A1.5 1.5 0 0 0 0 3.5v2h6a.5.5 0 0 1 .5.5c0 .253.08.644.306.958.207.288.557.542 1.194.542.637 0 .987-.254 1.194-.542.226-.314.306-.705.306-.958a.5.5 0 0 1 .5-.5h6v-2A1.5 1.5 0 0 0 14.5 2h-13z">
                                                </path>
                                                <path
                                                    d="M16 6.5h-5.551a2.678 2.678 0 0 1-.443 1.042C9.613 8.088 8.963 8.5 8 8.5c-.963 0-1.613-.412-2.006-.958A2.679 2.679 0 0 1 5.551 6.5H0v6A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-6z">
                                                </path>
                                            </svg> </span> <span
                                            class="svg-icon svg-icon-3x svg-icon-primary d-block my-2 text-center">
                                            <a href="javascript:void(0)"
                                                class="text-dark fs-6 text-hover-primary">卡密</a>
                                        </span> </div>
                                    <div class="col cursor-pointer" data-bs-toggle="modal"
                                        data-bs-target="#kt_modal_update_app" style="cursor:pointer;"
                                        @click="setCurrentWxShopId(item.id,4)"> <span
                                            class="svg-icon svg-icon-2hx svg-icon-primary d-block my-2 text-center">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor"
                                                class="bi bi-gear-fill text-muted" viewBox="0.7 0 14 16"
                                                style="width: 20px; height: 20px !important;">
                                                <path
                                                    d="M9.405 1.05c-.413-1.4-2.397-1.4-2.81 0l-.1.34a1.464 1.464 0 0 1-2.105.872l-.31-.17c-1.283-.698-2.686.705-1.987 1.987l.169.311c.446.82.023 1.841-.872 2.105l-.34.1c-1.4.413-1.4 2.397 0 2.81l.34.1a1.464 1.464 0 0 1 .872 2.105l-.17.31c-.698 1.283.705 2.686 1.987 1.987l.311-.169a1.464 1.464 0 0 1 2.105.872l.1.34c.413 1.4 2.397 1.4 2.81 0l.1-.34a1.464 1.464 0 0 1 2.105-.872l.31.17c1.283.698 2.686-.705 1.987-1.987l-.169-.311a1.464 1.464 0 0 1 .872-2.105l.34-.1c1.4-.413 1.4-2.397 0-2.81l-.34-.1a1.464 1.464 0 0 1-.872-2.105l.17-.31c.698-1.283-.705-2.686-1.987-1.987l-.311.169a1.464 1.464 0 0 1-2.105-.872l-.1-.34zM8 10.93a2.929 2.929 0 1 1 0-5.86 2.929 2.929 0 0 1 0 5.858z">
                                                </path>
                                            </svg> </span> <span
                                            class="svg-icon svg-icon-3x svg-icon-primary d-block my-2 text-center">
                                            <a href="javascript:void(0)"
                                                class="text-dark fs-6 text-hover-primary">设置</a>
                                        </span> </div>
                                    <div class="resize-triggers">
                                        <div class="expand-trigger">
                                            <div style="width: 404px; height: 459px;"></div>
                                        </div>
                                        <div class="contract-trigger"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div style="height:20px"></div>
                <el-pagination style="display:flex;justify-content:flex-end" @size-change="handleSizeChange"
                    @current-change="handleCurrentChange" :current-page="queryParams.pageIndex" :page-sizes="[12]"
                    :page-size="queryParams.pageSize" layout="total, sizes, prev, pager, next, jumper" :total="totalCount"
                    background>
                </el-pagination>
            </div>

        </div>
        <!-- 用户的弹窗 -->
        <el-dialog title="商城用户"  v-if="wxShopUserVisible" :visible.sync="wxShopUserVisible" width="80%">
            <template #title>
                <div class="modal-content">
                    <div class="modal-header">
                        <h2>商城用户</h2>
                    </div>
                </div>
            </template>
            <shopUserModal :currentWxShopId="currentWxShopId" :currentWxShopObj="currentWxShopObj"></shopUserModal>
        </el-dialog>
        <el-dialog title="订单列表">

        </el-dialog>

        <!-- 商品的弹窗 -->
        <el-dialog title="商城商品" v-if="wxShopGoodsVisible" :visible.sync="wxShopGoodsVisible" width="90%">
            <template #title>
                <div class="modal-content">
                    <div class="modal-header">
                        <h2>商城商品</h2>
                    </div>
                </div>
            </template>
            <shopGoodsModal :currentWxShopId="currentWxShopId"></shopGoodsModal>
        </el-dialog>
      <el-dialog v-if="copyWxShopVisible" :visible.sync="copyWxShopVisible" width="40%" destroy-on-close="true" @close="closeCopyWxShopDialog">
        <template #title>
          <div class="modal-content">
            <div class="modal-header">
              <h2>复制商城 </h2>
            </div>
          </div>
        </template>
        <el-form :model="copyWxShopObj" :rules="copyWxShopObjRule" ref="updateVoucher" label-width="100px" label-position="top">
          <el-row>
            <el-col >
              <el-form-item prop="name">
                                    <span slot="label" style="display:inline-block;">
                                        <label class="form-label fw-bolder text-dark fs-6">新商城名称</label>
                                    </span>
                <el-input v-model="copyWxShopObj.name"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <div class="modal-footer">
          <button type="button" class="btn btn-light"
                  @click="copyWxShopVisible=false">关闭</button>
          <button @click="submitCopyShop" type="button"
                  class="btn btn-primary">提交</button>
        </div>
      </el-dialog>

      <el-dialog style="padding: 0px" title="商城订单" v-if="wxShopOrderVisible"  :visible.sync="wxShopOrderVisible" width="100%">
        <template #title >
          <div class="modal-content">
            <div class="modal-header">
              <h2>商城订单</h2>
            </div>
          </div>
        </template>
        <orderIndex :currentWxShopId="currentWxShopId"></orderIndex>
      </el-dialog>

        <!-- 商品的弹窗 -->
        <el-dialog title="商城卡密" v-if="wxShopVoucherVisible"  :visible.sync="wxShopVoucherVisible" width="80%">
            <template #title>
                <div class="modal-content">
                    <div class="modal-header">
                        <h2>商城卡密</h2>
                    </div>
                </div>
            </template>
            <shopVoucherModal :currentWxShopId="currentWxShopId"></shopVoucherModal>
        </el-dialog>

        <el-dialog title="编辑商城"  v-if="createShopVisible"  :visible.sync="createShopVisible" width="80%"  @close="closeShopPopup" destroy-on-close="true">
          <template #title>
            <div class="modal-content">
              <div class="modal-header" style="justify-content: flex-start">
                <h2>编辑商城</h2>

                <span style="margin-left: 100px" >
                  <a  class="btn btn-sm btn-primary" @click="deleteWxShop"> 删除商城 </a>
                  <el-switch
                      style="margin-left: 100px"
                      v-model="shopStatus"
                      active-value="1"
                      active-text="使用中"
                      inactive-value="2"
                      inactive-text="已停用"
                      @change="shopStatusSwitch"
                  >
                  </el-switch>
                </span>

              </div>
            </div>
          </template>
          <createShop @closePopup="closeShopPopup" :currentWxShopId="currentWxShopId"></createShop>
        </el-dialog>
      <MobileModalBox  ref="MobileModalBox"/>
    </div>
</template>

<script>
import {copyWxShop, getShopListApi, updateWxShopStatus} from '@/api/shopApi'
// 页码指定跳转
import Pagination from '@/components/Pagination/index.vue'
import QRCode from 'qrcode'
import Clipboard from 'clipboard'
import shopUserModal from '@/views/Shop/component/shopUserModal.vue'
import shopGoodsModal from '@/views/Shop/component/shopGoodsModal.vue'
import shopVoucherModal from '@/views/Shop/component/shopVoucherModal.vue'
import createShop from "@/views/Shop/component/createShopModal/createShop.vue";
import orderIndex from "@/views/Order/index.vue"

export default {
    components: {
      createShop,
        Pagination,shopUserModal,shopGoodsModal,shopVoucherModal,orderIndex
    },
    data() {
        return {
            currentWxShopId:null,
            wxShopOrderVisible:false,
            wxShopUserVisible:false,
            wxShopGoodsVisible:false,
            wxShopVoucherVisible:false,
            createShopVisible:false,
            copyWxShopVisible:false,
          currentWxShopObj:null,
            qrCodeUrl:'',
            code_id: '',
            code_show: false,
            createDateRange: "",
            stopDateRange: "",
            //日期选择器 用
            pickerOptions: {
                shortcuts: [{
                    text: '最近一周',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近一个月',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近三个月',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                        picker.$emit('pick', [start, end]);
                    }
                }]
            },
            shopStatus:"1",
            rows: [],
            queryParams: {
                pageIndex: 1,
                pageSize: 12,
                shopName: null,
                shopStatus: "0",
                createDateStart: null,
                createDateEnd: null,
                stopDateStart: null,
                stopDateEnd: null,
            },
            totalCount: 0,
          // 复制商城的时候
            copyWxShopObj:{
              name:"",
              wxShopId:this.currentWxShopId
            }
        }
    },
    methods: {
      checkPay() {
        if (localStorage.getItem('paying')) {
          this.$confirm('是否完成支付?', '支付', {
            confirmButtonText: '已经完成',
            cancelButtonText: '未完成',
            type: 'warning'
          }).then(() => {
            this.checkOrder();
            localStorage.removeItem("paying")
          }).catch(() => {
            this.checkOrder();
            localStorage.removeItem("paying")
          });
        }
      },
      checkOrder() {
        console.log(1)
      },
      pay() {

      },
        async getShopList() {
          if(this.queryParams.shopStatus=="0"){
            this.queryParams.shopStatus = null
          }
            let { data } = await getShopListApi(this.queryParams)
            console.log(data)
            this.rows = data.rows
            this.totalCount = data.count
        },
        //重置分页参数，获取list
        resetGetShopList() {
          console.log("执行了")
            this.queryParams.pageIndex = 1;
            this.getShopList();
        },
        handleCurrentChange(param) {
            this.queryParams.pageIndex = param
            this.getShopList();
        },
        handleSizeChange(param) {
            this.queryParams.pageIndex = 1
            this.queryParams.pageSize = param
            this.getShopList();
        },
        startDateChange(param) {
            if (Array.isArray(param)) {
                console.log("afa")
                this.queryParams.createDateStart = param[0]
                this.queryParams.createDateEnd = param[1]
            } else {
                this.queryParams.createDateStart = null
                this.queryParams.createDateEnd = null
            }

        },
        stopDateChange(param) {
            if (Array.isArray(param)) {
                this.queryParams.stopDateStart = param[0]
                this.queryParams.stopDateEnd = param[1]
            } else {
                this.queryParams.stopDateStart = null
                this.queryParams.stopDateEnd = null
            }

        },
        creatQrCode(pageUrl) {
            //QRCode:二维码生成插件
            QRCode.toDataURL(pageUrl,{margin:1}).then(res =>{
                this.qrCodeUrl = res
            })
        },
        openQrcode(id, shopId) {
            this.creatQrCode("https://h5.3jzc.cn/?shopId="+shopId)
            this.code_id = id
            this.code_show = !this.code_show
        },
        closeShopPopup() {
          this.createShopVisible = false
          if(this.currentWxShopId!=null){
            this.getShopList()
          }else{
            this.resetGetShopList();
          }
          this.currentWxShopId=null
          // 关闭弹窗逻辑
        },
        setCurrentWxShopId(wxShopId,type,obj){
            this.currentWxShopId = wxShopId
            if(type==1){
                this.wxShopGoodsVisible = true
            }else if(type == 2){
              this.currentWxShopObj=obj
                this.wxShopUserVisible = true
            }else if(type == 6){
                this.wxShopVoucherVisible = true
            }else if(type == 4){
                this.createShopVisible = true
            }else if(type == 3){
               this.wxShopOrderVisible = true
            }else if(type == 7){
              this.copyWxShopVisible =  true
            }
        },
       shopStatusSwitch(val){
         const that = this;
         let deleteParam = {
           wxShopId: this.currentWxShopId,
           status:val
         }
          updateWxShopStatus(deleteParam).then((res) => {
            that.getShopList();
            that.closeShopPopup();
            this.$message({
              type: 'success',
              message: '更新成功!'
            });
          })
        },
        deleteWxShop(){
          const that = this;
          this.$confirm('将删除该商城 是否继续?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
            center: true
          }).then(() => {
            let deleteParam = {
              wxShopId: this.currentWxShopId,
              status:3
            }
            updateWxShopStatus(deleteParam).then((res) => {
              that.getShopList();
              that.closeShopPopup();
              this.$message({
                type: 'success',
                message: '删除成功!'
              });
            })

          }).catch(() => {
            // this.$message({
            //     type: 'info',
            //     message: '已取消删除'
            // });
          });
        },
      closeCopyWxShopDialog(){
          this.copyWxShopObj.name=""
      },
      openCreateShopDialog(){
          this.$emit('openCreateShopDialog')
      },
      openTopicDialog(){
        this.$emit('openTopicDialog')
      },
      async submitCopyShop(){
        try {
          this.copyWxShopObj.wxShopId = this.currentWxShopId
          let {data} = await copyWxShop(this.copyWxShopObj)
          if (data) {
            this.$message({
              type: 'success',
              message: '复制成功!'
            });
            this.copyWxShopVisible = false
            await this.getShopList()
          }
        } catch (e) {
          if (e.code === 500032) {
            this.copyWxShopVisible = false
            this.$confirm('剩余搭建商城次数不足，是否增购？（50元/个）', '提示', {
              confirmButtonText: '购买',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(() => {
              this.$refs.VipPayComponent.openPay({
                type: 2,
                rerunUrl: process.env.NODE_ENV === "development" ? "http://localhost:8080/#/payCallback" : "https://saas.zhelidao/#/payCallback"
              })
            })
          }
        }

      },
      openMobileModalBox(id){
        const shopIdUrl ="https://h5.3jzc.cn/?shopId="+ id;
        this.$refs.MobileModalBox.open(shopIdUrl);

      },
      copyUrl(){
        let clipboard = new Clipboard(".copyUrl");
        console.log(clipboard)
        clipboard.on("success", e => {
          // 释放内存
          this.$message({
            message: "复制成功",
            type: "success"
          });
          clipboard.destroy();
        });
        clipboard.on("error", e => {
          // 不支持复制
          this.$message({
            message: "该浏览器不支持自动复制",
            type: "warning"
          });
          // 释放内存
          clipboard.destroy();
        });
      }
    },
    created() {
        //this.getChooseGoodsList();
        this.resetGetShopList();
    },
    updated() {
        // 当数据更新后，可以在此处手动初始化下拉菜单插件
        // this.rows.forEach((_, index) => {
        //     this.toggleDropdown(index);
        // });
    }
}
</script>
<style scoped>
.text_content{
  position: absolute;
  bottom: 5px;
  color: white;
  text-align: center;
  width: 100%;
}

.code_wrap {
    width: 100%;
    height: 100%;
    background: #3b3b3b91;
    position: absolute;
    backdrop-filter:  blur(4px) saturate(150%);
    left: 0;
    top: 0;
    z-index: 99;
}
.code_content {
    width: 140px;
    height: 140px;
    position: absolute;
    left: 50%;
    top: 40%;
    margin-top: -70px;
    margin-left: -70px;
    border-radius: 8px;
}
::v-deep .el-date-editor .el-range-separator {
  display: inline-block;
  height: 100%;
  /* padding: 0 5px; */
  margin: 0;
  text-align: center;
  line-height: 32px;
  font-size: 14px;
  width: 7%;
  color: #303133;
}
.thumbnailUrl{
  position: relative;
}

.thumbnailUrl:hover .wrap {
  display: block;
}
.wrap {
  width: 90%;
  aspect-ratio: 134 / 103;
  padding: 1px;
  box-sizing: border-box;
  background: #040404af;
  position: absolute;
  left: 0;
  top: 0;
  display: none;
}
.wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  justify-content: space-around;
  padding: 20px;
  box-sizing: border-box;
  width: 100%;
  aspect-ratio: 134 / 103;
}
.preview {
  //position: absolute;
  //right: 15px;
  //top: 15px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.copyUrl{
  color: #fff !important;
}
.copyUrl:hover{
  color: blue !important;
  text-decoration: blue underline;
}
</style>
