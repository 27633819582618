import {getCategoryList} from "@/api/categoryApi";
import {
    addAddressApi,
    buyPoolGoodsApi,
    deleteAddressApi,
    getAddressInfoApi,
    getAddressPageApi,
    getChooseGoodsListApi,
    getChooseGoodsListCountApi,
    getJdRegionListApi,
    selectionGoodsApi,
    updateAddressApi
} from "@/api/chooseGoodsApi";
import {getGoodsGroupByStaffIdApi, getWxShopByStaffIdApi, getWxTopicByStaffIdApi} from "@/api/groupGoodsApi";
import {vipCollectiveProcurementSaveApi} from "@/api/VipCollectiveProcurementApi";
import {getVipStaffInfoApi} from "@/api/vipAddressBookApi";

export const mixins = {
    data() {
        return {
            vipBaseInfoVisible: false,
            state: "",
            status: 1,
            buyManyVisibleFromGoods: false,
            buyOneVisibleFromGoods: false,
            vipDetailVisible: false,
            goodsDetailVisible: false,
            procurementDialogFormVisible: false,
            topics: [
                {
                    id: 1,
                    title: "情人节",
                    selected: false,
                    isNew: true
                },
                {
                    id: 2,
                    title: "端午节",
                    selected: false,
                    isNew: false,
                },
                {
                    id: 3,
                    title: "精选好物",
                    selected: false,
                    isNew: false,
                },
                {
                    id: 4,
                    title: "春节送礼",
                    selected: false,
                    isNew: false,
                },

            ],
            rows: [],
            categoryOne: [],
            categoryTwo: [],
            loading: false,
            params: {
                isJd: 2,
                current: 1,
                size: 10,
                sTitle: "",
                sPhone: "",
                minSupplyPrice: "",
                maxSupplyPrice: "",
                maxRightRate: "",
                minRightRate: "",
                categoryId1: "",
                categoryId2: "",
                isVipGoods: "",
                enterpriseName: "",
                isVipBoutique: "",
                fromType: 4
            },
            queryParam: {
                categoryId1: null,
                categoryId2: null
            },
            goodsItem: {},
            item: {},
            totalCount: 9999,
            dialogVisible: false,
            addressListDialog: false,
            createOrEdit: false,
            addressObj: {},
            addressList: [],
            editAddressObj: {
                name: "",
                tel: "",
                address: "",
                isType: 1,
                pro: 0,
                city: 0,
                area: 0,
                stree: 0
            },
            proList: [],
            cityList: [],
            areaList: [],
            streeList: [],
            num: "",
            checkboxInput: true,
            idArr: [],
            pushGoodsObj: {
                topicIds: [],
                shopIds: [],
                goodsGroupIds: [],
                selectionObjects: [],
                goodsIds: [],
                isJd: 1,
                salePriceChange: "2",
                supplPriceChange: "2",
                salePriceDto: {
                    salePriceReference: "2",
                    salePriceAdjust: "2",
                    salePriceNum: "",
                    salePriceUnit: "1",
                },
                supplPriceDto: {
                    supplPriceReference: "1",
                    supplPriceAdjust: "2",
                    supplPriceNum: "",
                    supplPriceUnit: "1",
                },
            },
            options: [
                //{
                //  value: "1",
                //  label: "销售价",
                //},
                {
                    value: "2",
                    label: "市场价",
                },
                {
                    value: "3",
                    label: "分销价",
                },
            ],
            unitOptions: [
                {
                    value: "1",
                    label: "%",
                },
                {
                    value: "2",
                    label: "元",
                },
            ],
            adjustOptions: [
                {
                    value: "2",
                    label: "下降",
                },
            ],
            groupProps: [],
            shopProps: [],
            topicProps: [],
            collectiveProcurementFrom: {
                goodsId: "",
                contacts: "",
                tel: "",
                num: 1,
                shippingMethod: 1,
                remark: "",
                address: "",
                price: ""
            },
            rules: {
                contacts: [
                    {required: true, message: '请输入联系人', trigger: 'blur'}
                ],
                tel: [
                    {required: true, message: '请输入联系电话', trigger: 'blur'}
                ],
                num: [
                    {required: true, message: '请输入数量', trigger: 'blur'}
                ],
                shippingMethod: [
                    {required: true, message: '请选择配送方式', trigger: 'blur'}
                ],
                remark: [
                    {required: false, message: '请输入备注', trigger: 'blur'}
                ],
                address: [
                    {required: true, message: '请输入收货地址', trigger: 'blur'}
                ]
            },
            vipInfo: {}
        }
    },
    methods: {
        checkPay() {
            if (localStorage.getItem('paying')) {
                this.$confirm('是否完成支付?', '支付', {
                    confirmButtonText: '已经完成',
                    cancelButtonText: '未完成',
                    type: 'warning'
                }).then(() => {
                    this.checkOrder();
                    localStorage.removeItem("paying")
                }).catch(() => {
                    this.checkOrder();
                    localStorage.removeItem("paying")
                });
            }
        },
        checkOrder() {
            console.log(1)
        },
        pay() {

        },
        setTree(data, typeName) {
            this.$refs[typeName].setCheckedKeys([]);
            if (data) {
                this.$refs[typeName].setCheckedNodes([data]);
            }
        },
        groupTreeNodeClick(data) {
            this.setTree(data, "groupTree")
        },
        shopTreeNodeClick(data) {
            this.setTree(data, "shopTree")
        },
        topicTreeNodeClick(data) {
            this.setTree(data, "topicTree")
        },
        shaixuan() {
            this.first = true;
            let categoryId1 = this.queryParam.categoryId1
            let categoryId2 = this.queryParam.categoryId2
            this.params.categoryId1 = categoryId1
            this.params.categoryId2 = categoryId2
            this.getChooseGoodsList();
        },
        async getChooseGoodsList() {
            this.checkboxInput = false;
            this.loading = true;
            let {data} = await getChooseGoodsListApi(this.params);
            this.rows = data.rows;
            if (this.first) {
                this.getPageCount();
            }
            this.first = false;
            this.loading = false;

            let a = true;
            this.rows.forEach((e) => {
                if (!this.idArr.includes(e.id)) {
                    a = false;
                }
            });
            this.checkboxInput = a;
        },
        async getPageCount() {
            let {data} = await getChooseGoodsListCountApi(this.params);
            this.totalCount = data;
        },
        resetQuery() {
            this.params = {
                isJd: 2,
                current: 1,
                size: 10,
                sTitle: "",
                sPhone: "",
                minSupplyPrice: "",
                maxSupplyPrice: "",
                maxRightRate: "",
                minRightRate: "",
                categoryId1: "",
                categoryId2: "",
                isVipGoods: false,
                enterpriseName: "",
                isVipBoutique: "",
                fromType: 4
            };
            this.queryParam = {
                categoryId1: null,
                categoryId2: null
            }

            this.shaixuan();
        },
        changIsJd(type) {
            this.params.isJd = type;
        },
        toggleDropdown(index) {
            this.$nextTick(() => {
                const dropdown = document.getElementById(`dropdown-${index}`);
                new KTMenu(dropdown);
            });
        },
        openBuyManyFromGoods(item) {
            this.collectiveProcurementFrom = {
                goodsId: "",
                contacts: "",
                tel: "",
                num: 1,
                shippingMethod: 1,
                remark: ""
            }
            console.log(item)
            this.collectiveProcurementFrom.goodsId = item.id
            this.collectiveProcurementFrom.price = item.supplPrice
            this.procurementDialogFormVisible = true
        },

        async openVipBaseInfoDialog(staffId) {
            if (await this.getVipBaseInfo(staffId)) {
                this.vipBaseInfoVisible = true
            }

        },
        closeVipBaseInfoDialog() {
            this.vipBaseInfoVisible = false
        },
        //初始化分类
        async getCategory1List(parentId) {
            const {data} = await getCategoryList({parentId});
            this.categoryOne = data.list
        },
        async changeCategoryId1() {
            const categoryId1 = this.queryParam.categoryId1;
            console.log(categoryId1)
            this.queryParam.categoryId2 = null
            if (categoryId1 == "null") {
                this.categoryTwo = []
            } else {
                const param = {parentId: categoryId1};
                const {data} = await getCategoryList(param);
                this.categoryTwo = data.list
            }
        },
        async pushGood(id, state) {
            this.state = 1;
            this.id = id;
            this.$refs.pushGoodsAll.open();
            this.shopProps = [];
            this.topicProps = [];
            this.groupProps = [];
            let {data: res1} = await getWxTopicByStaffIdApi();
            res1.forEach((item, index) => {
                this.topicProps.push({
                    id: item.id,
                    label: item.title,
                });
            });
            let {data: res2} = await getWxShopByStaffIdApi();
            res2.forEach((item, index) => {
                this.shopProps.push({
                    id: item.id,
                    label: item.name,
                });
            });
            let {data: res3} = await getGoodsGroupByStaffIdApi();
            res3.forEach((item, index) => {
                this.groupProps.push({
                    id: item.id,
                    label: item.name,
                });
            });
        },
        openGoodInfo(id, isJd) {
            console.log(id)
            this.$refs.goodInfo.open()
            this.goodsDetailVisible = true
            //this.$refs.goodInfo.open()
            setTimeout(() => {
                this.$refs.goodInfoComponent.openGoodsInfo(id, isJd);
            }, 200)
        },
        handleSizeChange(val) {
            this.params.size = val
            this.shaixuan();
        },
        handleCurrentChange(val) {
            this.params.current = val
            this.shaixuan()
        },
        async buyGoods(item) {
            // let id= item.isJD == 1 ? item.skuId : item.id;
            this.item = item;
            this.num = 1;
            this.getDefaultAddressPage()
        },
        async getDefaultAddressPage() {
            let {data} = await getAddressPageApi({
                name: "",
                tel: "",
                isType: 1,
                current: 1,
                size: 50
            })
            data.records.forEach((e) => {
                if (e.isType == 1) {
                    this.addressObj = e;
                }
            })
            this.dialogVisible = true
        },
        async moreAddress() {
            this.addressListDialog = true;
            let {data} = await getAddressPageApi({
                name: "",
                tel: "",
                isType: "",
                current: 1,
                size: 50
            })
            this.addressList = data.records;
        },
        async addAddress() {
            this.status = 1;
            this.proList = await this.initJdRegionList(0)
            this.editAddressObj = {
                name: "",
                tel: "",
                address: "",
                isType: "2",
                pro: "",
                city: "",
                area: "",
                stree: ""
            }
            this.createOrEdit = true;
        },
        selectAddress(item) {
            this.addressObj = item
            this.addressListDialog = false
            this.$message({
                type: 'success',
                message: '选择成功!'
            });
        },
        async editAddress(item) {
            let {data} = await getAddressInfoApi({
                addressId: item.id
            })
            this.editAddressObj = data
            this.editAddressObj.pro = +data.pro
            this.editAddressObj.city = +data.city
            this.editAddressObj.area = +data.area
            this.editAddressObj.stree = +data.stree ? +data.stree : '';
            this.status = 2;
            this.proList = await this.initJdRegionList(0)
            this.cityList = await this.initJdRegionList(data.pro)
            this.areaList = await this.initJdRegionList(data.city)
            this.streeList = await this.initJdRegionList(data.area)
            this.createOrEdit = true;
        },
        async delAddress(item) {
            this.$confirm('此操作将永久删除该地址, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(async () => {
                let {data} = await deleteAddressApi({
                    addressId: item.id
                })
                let {data: res} = await getAddressPageApi({
                    name: "",
                    tel: "",
                    isType: "",
                    current: 1,
                    size: 50
                })
                this.addressList = res.records;
                this.$message({
                    type: 'success',
                    message: '删除成功!'
                });
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });
        },
        async proChange() {
            this.editAddressObj.city = ""
            this.editAddressObj.area = ""
            this.editAddressObj.stree = ""
            this.cityList = []
            this.areaList = []
            this.streeList = []
            this.cityList = await this.initJdRegionList(this.editAddressObj.pro)
        },
        async cityChange() {
            this.editAddressObj.area = ""
            this.editAddressObj.stree = ""
            this.areaList = []
            this.streeList = []
            this.areaList = await this.initJdRegionList(this.editAddressObj.city)
        },
        async areaChange() {
            this.editAddressObj.stree = ""
            this.streeList = []
            this.streeList = await this.initJdRegionList(this.editAddressObj.area)
        },
        async saveAddress() {
            let obj = {
                name: this.editAddressObj.name,
                pro: this.editAddressObj.pro,
                city: this.editAddressObj.city,
                area: this.editAddressObj.area,
                address: this.editAddressObj.address,
                tel: this.editAddressObj.tel,
                isType: this.editAddressObj.isType,
                stree: this.editAddressObj.stree
            }
            if (this.status == 2) {
                obj.id = this.editAddressObj.id
            }
            //修改
            if (this.status == 2) {
                let {data} = await updateAddressApi(JSON.stringify(obj))
            } else {
                //创建
                let {data} = await addAddressApi(obj)
            }
            this.moreAddress()
            this.getDefaultAddressPage()
            this.createOrEdit = false;
        },
        async initJdRegionList(id = 0) {
            //编辑时初始化省市区
            let {data} = await getJdRegionListApi({
                pId: id
            })
            return data;
        },
        //购买商品
        payGoods() {
            this.$confirm('你确认购买当前商品?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(async () => {
                let obj = {
                    goodsId: this.item.id,
                    supplyPrice: this.item.supplPrice,
                    goodsIsJd: this.item.isJD,
                    addressId: this.addressObj.id,
                    goodsQuantity: this.num,
                    isVipGoods: true
                }
                let {data} = await buyPoolGoodsApi(obj)
                this.dialogVisible = false;
                this.$message({
                    type: 'success',
                    message: '购买成功!'
                });
            }).catch(() => {

            });
        },
        checkboxInputEvent() {
            if (this.checkboxInput) {
                this.rows.forEach((e) => {
                    if (!this.idArr.includes(e.id)) {
                        this.idArr.push(e.id);
                    }
                });
            } else {
                this.rows.forEach((e) => {
                    let index = this.idArr.indexOf(e.id);
                    if (index !== -1) {
                        this.idArr.splice(index, 1);
                    }
                });
            }
        },
        addIdArr(id) {
            if (this.idArr.includes(id)) {
                let index = this.idArr.indexOf(id);
                if (index !== -1) {
                    this.idArr.splice(index, 1);
                }
            } else {
                this.idArr.push(id);
            }
            let a = true;
            this.rows.forEach((e) => {
                if (!this.idArr.includes(e.id)) {
                    a = false;
                }
            });
            this.checkboxInput = a;
        },
        // 推送
        async pushAll() {
            this.id = "";
            this.state = ""
            if (this.idArr.length == 0) {
                return;
            }
            this.$refs.pushGoodsAll.open();
            this.shopProps = [];
            this.topicProps = [];
            this.groupProps = [];
            let {data: res1} = await getWxTopicByStaffIdApi();
            res1.forEach((item, index) => {
                this.topicProps.push({
                    id: item.id,
                    label: item.title,
                });
            });
            let {data: res2} = await getWxShopByStaffIdApi();
            res2.forEach((item, index) => {
                this.shopProps.push({
                    id: item.id,
                    label: item.name,
                });
            });
            let {data: res3} = await getGoodsGroupByStaffIdApi();
            res3.forEach((item, index) => {
                this.groupProps.push({
                    id: item.id,
                    label: item.name,
                });
            });
        },
        // 选择推送目标改变时触发
        changeSelectionObjects() {
            if (this.pushGoodsObj.selectionObjects == 'group') {
                this.pushGoodsObj.salePriceChange = '2'
                this.pushGoodsObj.salePriceDto.salePriceNum = null
            }
        },
        async nextPush() {
            if (this.pushGoodsObj.selectionObjects.length == 0) {
                this.$message({
                    message: "请选择推送目标",
                    type: "warning",
                });
                return;
            }
            this.$refs.pushGrounp.open();
        },
        async submitPush(id) {
            this.pushGoodsObj.topicIds = [];
            this.pushGoodsObj.shopIds = [];
            this.pushGoodsObj.goodsGroupIds = [];
            this.pushGoodsObj.goodsIds = [];
            if (this.pushGoodsObj.selectionObjects.includes("topic")) {
                this.pushGoodsObj.topicIds = this.$refs.topicTree.getCheckedKeys();
                if (this.pushGoodsObj.topicIds.length == 0) {
                    let index = this.pushGoodsObj.selectionObjects.indexOf("topic");
                    this.pushGoodsObj.selectionObjects.splice(index, 1);
                }
            }
            if (this.pushGoodsObj.selectionObjects.includes("shop")) {
                this.pushGoodsObj.shopIds = this.$refs.shopTree.getCheckedKeys();
                if (this.pushGoodsObj.shopIds.length == 0) {
                    let index = this.pushGoodsObj.selectionObjects.indexOf("shop");
                    this.pushGoodsObj.selectionObjects.splice(index, 1);
                }
            }
            if (this.pushGoodsObj.selectionObjects.includes("group")) {
                this.pushGoodsObj.goodsGroupIds = this.$refs.groupTree.getCheckedKeys();
                if (this.pushGoodsObj.goodsGroupIds.length == 0) {
                    let index = this.pushGoodsObj.selectionObjects.indexOf("group");
                    this.pushGoodsObj.selectionObjects.splice(index, 1);
                }
            }
            if (this.pushGoodsObj.supplPriceChange == "2") {
                this.pushGoodsObj.supplPriceDto = {
                    supplPriceReference: "",
                    supplPriceAdjust: "",
                    supplPriceNum: "",
                    supplPriceUnit: "",
                };
            }
            if (this.pushGoodsObj.salePriceChange == "2") {
                this.pushGoodsObj.salePriceDto = {
                    salePriceReference: "",
                    salePriceAdjust: "",
                    salePriceNum: "",
                    salePriceUnit: "",
                };
            }
            if (this.id == "") {
                this.pushGoodsObj.goodsIds = this.idArr;
            } else {
                this.pushGoodsObj.goodsIds = [this.id];
            }
            this.pushGoodsObj.isJd = this.params.isJd;

            let arr = ["group", "shop", "topic"];
            let arr1 = [];
            arr.forEach((item) => {
                if (this.pushGoodsObj.selectionObjects.includes(item)) {
                    arr1.push(item);
                }
            });
            let goodNum = this.pushGoodsObj.goodsIds.length;
            let {data} = await selectionGoodsApi(this.pushGoodsObj);
            let total = 0;
            for (let key in data) {
                if (data.hasOwnProperty(key)) {
                    total += data[key];
                }
            }
            if (total === 0) {
                this.$message({
                    message: "全部商品推送成功",
                    type: "success",
                });
            } else if (total >= goodNum) {
                this.$message({
                    message: "商品全部推送失败，请检查价格比例或者商城已存在商品!",
                    type: "error",
                    offset: 110
                });
            } else if (total < goodNum) {
                this.$message.warning({
                    message: "商品部分推送失败，请调整价格比例或部分商品已被推送!",
                    offset: 110
                })
            }
            // this.$message({
            //     message: "推送成功",
            //     type: "success",
            // });
            this.idArr = [];
            this.$refs.pushGoodsAll.close();
            this.$refs.pushGrounp.close();
        },
        selectChange() {
            if (this.pushGoodsObj.salePriceDto.salePriceReference == 2) {
                this.pushGoodsObj.salePriceDto.salePriceAdjust = "2";
                this.adjustOptions = [
                    {
                        value: "2",
                        label: "下降",
                    },
                ]
            } else {
                this.adjustOptions = [
                    {
                        value: "1",
                        label: "上调",
                    },
                    {
                        value: "2",
                        label: "下降",
                    },
                ]
            }
        },
        submitFrom() {
            this.$refs["collectiveProcurementFromRuleForm"].validate(async (valid) => {
                if (!valid) {
                    return false;
                }
                await vipCollectiveProcurementSaveApi(this.collectiveProcurementFrom);
                this.$message({
                    message: "提交成功",
                    type: "success",
                });
                this.procurementDialogFormVisible = false
            });
        },
        changeMessage() {
            this.$forceUpdate()
        },
        async getVipBaseInfo(staffId) {
            let queryparam = {
                staffId
            }
            try {
                let res = await getVipStaffInfoApi(queryparam);
                this.vipInfo = res.data

            } catch (e) {
                if (e.code === 700009) {
                    this.$confirm('查看商品来源达到最大限制, 是否进行购买?', '提示', {
                        confirmButtonText: '购买',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                        this.$refs.VipPayComponent.openPay({
                            type: 5,
                            rerunUrl: process.env.NODE_ENV === "development" ? "http://localhost:8080/#/payCallback" : "https://saas.zhelidao.com/#/payCallback"
                        })
                    }).catch(() => {

                    });
                }
                return false;
            }
            return true
        }


    },
    created() {
        this.getCategory1List(0)
        this.shaixuan();
    }
}
