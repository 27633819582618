<template>
  <div style="background-color: #fff;">
    <div class="d-flex flex-column flex-root">
      <!--begin::Authentication - Sign-in -->
      <div
          class="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed my-component">
        <!--begin::Content-->
        <div class="d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20">
          <!--begin::Logo-->
          <a class="mb-14">

            <img :src="obj.logoUrl" alt="Logo" class="h-65px"/>


          </a>
          <!--end::Logo-->
          <!--begin::Wrapper-->
          <div class="w-lg-500px bg-body rounded shadow-sm p-10 p-lg-15 mx-auto">
            <div class="mb-10 text-center">
              <h1 class="text-dark mb-3">欢迎进入系统</h1>
              <div class="text-gray-400 fw-bold fs-4">
                已有账号?
                <router-link class="link-primary fw-bolder" to="/login">前往登录</router-link>
              </div>
            </div>
            <el-form ref="regFrom" :model="regFrom" :rules="regFromRules">
              <el-form-item class="regFromText" label="账号" prop="staffUsername">
                <el-input v-model="regFrom.staffUsername" class="regFromInput" placeholder="请输入登录账号"></el-input>
              </el-form-item>
              <el-form-item class="regFromText" label="密码" prop="staffPassword">

                <el-tooltip  class="item" content="建议密码长度为8-16位，且包含数字、字母、符号至少两种" effect="light"
                            placement="right">
                  <el-input v-model="regFrom.staffPassword" class="regFromInput" placeholder="请输入密码" show-password>
                  </el-input>
                </el-tooltip>
                <div class="input_span">
                  <span
                      :style="{'background-color':(msgText > 1 || msgText == 1) ?  '#FC5F76' : '#BBBBBB', 'color': (msgText > 1 || msgText == 1) ?  '#FC5F76' : '#BBBBBB' }">弱</span>
                  <span
                      :style="{'background-color':(msgText > 2 || msgText == 2) ?  '#FF9900' : '#BBBBBB', 'color': (msgText > 2 || msgText == 2) ?  '#FF9900' : '#BBBBBB'}">中</span>
                  <span
                      :style="{'background-color':(msgText > 4 || msgText == 4) ?  '#33CC00' : '#BBBBBB', 'color': (msgText > 4 || msgText == 4) ?  '#33CC00' : '#BBBBBB'}">强</span>
                </div>
              </el-form-item>
              <el-form-item class="regFromText" label="确认密码" prop="configPassword">
                <el-input v-model="regFrom.configPassword" class="regFromInput" placeholder="请再输入一次密码"
                          show-password></el-input>
              </el-form-item>
              <el-form-item class="regFromText" label="联系人" prop="staffName">
                <el-input v-model="regFrom.staffName" class="regFromInput" placeholder="请输入联系人姓名"></el-input>
              </el-form-item>
              <el-form-item class="regFromText" label="手机号" prop="tel">
                <el-input v-model="regFrom.tel" class="regFromInput" placeholder="请输入联系人手机号"></el-input>
              </el-form-item>
              <el-form-item class="regFromText" label="验证码" prop="code">
                <br>
                <div style="display: flex">
                  <el-input v-model="regFrom.code" style="width: 60%;" class="regFromInput" placeholder="请输入6位验证码"></el-input>
                  <el-button type="primary" style="width: 35%; margin-left: 5%" @click="sendCode" plain :disabled="sendCodeStatus || countdown > 0">
                    {{ sendCodeStatus ? '发送中...' : countdown > 0 ? `${countdown}秒后重试` : '发送验证码' }}
                  </el-button>
                </div>

              </el-form-item>
              <el-form-item class="regFromText" label="邮箱" prop="email">
                <el-input v-model="regFrom.email" class="regFromInput" placeholder="请输入联系人邮箱"></el-input>
              </el-form-item>
              <el-form-item class="regFromText" label="公司名称" prop="backup">
                <el-input v-model="regFrom.backup" class="regFromInput" placeholder="请输入公司名称"></el-input>
              </el-form-item>
              <el-form-item class="regFromText" label="公司详细地址" prop="companyAddress">
                <el-input v-model="regFrom.companyAddress" class="regFromInput"
                          maxlength="50"
                          placeholder="请输入公司详细地址"></el-input>
              </el-form-item>

              <el-form-item class="regFromText" label="公司级别" prop="region">
                <div class="fv-row">
                  <select v-model="regFrom.region" class="form-select form-select-solid form-select-lg"
                          style="width: 100%">
                    <option disabled hidden selected value="">请选择公司级别</option>
                    <option value="1">会长及常务单位</option>
                    <option value="2">副会长单位</option>
                    <option value="3">理事单位</option>
                    <option value="4">会员单位</option>
                  </select>
                </div>
              </el-form-item>
              <el-form-item class="regFromText" label="主营品牌" prop="mainBusiness">
                <textarea v-model="regFrom.mainBusiness" class="form-control form-control-solid regFromInput"
                          name="message"
                          maxlength="100"
                          placeholder="请输入公司主营品牌" rows="2"></textarea>
              </el-form-item>
              <el-form-item class="regFromText" label="公司简介" prop="companyIntroduction">
                <textarea v-model="regFrom.companyIntroduction" class="form-control form-control-solid regFromInput"
                          name="message"
                          maxlength="100"
                          placeholder="请输入公司简介" rows="2"></textarea>
              </el-form-item>

              <el-form-item class="regFromText" label="上传营业执照" prop="avatarFile">
                <br>
                <div class="col-lg-8">
                  <!--begin::Image input-->
                  <div class="image-input image-input-outline blankPng" data-kt-image-input="true"
                       style="background-image: url(assets/media/avatars/blank.png)">
                    <!--begin::Preview existing avatar-->
                    <div class="image-input-wrapper w-125px h-125px"
                         :style="`background-image: url(${imageSrc})`"></div>
                    <!--end::Preview existing avatar-->
                    <!--begin::Label-->
                    <label
                        class="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                        data-bs-original-title="上传图像" data-bs-toggle="tooltip" data-kt-image-input-action="change"
                        title=""> <i class="bi bi-pencil-fill fs-7"></i>
                      <!--begin::Inputs--> <input id="pic_file_list" ref="avatarFile"
                                                  accept=".png, .jpg, .jpeg" name="avatar" type="file"
                                                  @change="uploadPic('avatarFile')">
                      <input name="avatar_remove" type="hidden">
                      <input id="picUrl" name="picUrl" type="hidden" value="">
                    </label>
                    <!--end::Label-->
                    <!--begin::Cancel-->
                    <span
                        class="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                        data-bs-original-title="取消" data-bs-toggle="tooltip" data-kt-image-input-action="cancel"
                        title=""> <i class="bi bi-x fs-2"></i>
										</span>
                    <!--end::Cancel-->
                    <!--begin::Remove-->
                    <span
                        class="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                        data-bs-original-title="移除图像" data-bs-toggle="tooltip" data-kt-image-input-action="remove"
                        title=""> <i class="bi bi-x fs-2"></i>
										</span>
                    <!--end::Remove-->
                  </div>
                  <!--end::Image input-->
                  <!--begin::Hint-->
                  <div class="form-text">允许的文件类型: png, jpg, jpeg.</div>
                  <!--end::Hint-->
                </div>
              </el-form-item>
              <el-form-item class="regFromText" label="上传LOGO" prop="avatarLOGOFile">
                <br>
                <div class="col-lg-8">
                  <!--begin::Image input-->
                  <div class="image-input image-input-outline blankPng" data-kt-image-input="true"
                       style="background-image: url(assets/media/avatars/blank.png)">
                    <!--begin::Preview existing avatar-->
                    <div class="image-input-wrapper w-125px h-125px"
                         :style="`background-image: url(${imageLOGOSrc})`"></div>
                    <!--end::Preview existing avatar-->
                    <!--begin::Label-->
                    <label
                        class="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                        data-bs-original-title="上传图像" data-bs-toggle="tooltip" data-kt-image-input-action="change"
                        title=""> <i class="bi bi-pencil-fill fs-7"></i>
                      <!--begin::Inputs--> <input id="pic_file_list" ref="avatarLOGOFile"
                                                  accept=".png, .jpg, .jpeg" name="avatar" type="file"
                                                  @change="uploadPic('avatarLOGOFile')">
                      <input name="avatar_remove" type="hidden">
                      <input id="picLOGOUrl" name="picLOGOUrl" type="hidden" value="">
                    </label>
                    <!--end::Label-->
                    <!--begin::Cancel-->
                    <span
                        class="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                        data-bs-original-title="取消" data-bs-toggle="tooltip" data-kt-image-input-action="cancel"
                        title=""> <i class="bi bi-x fs-2"></i>
										</span>
                    <!--end::Cancel-->
                    <!--begin::Remove-->
                    <span
                        class="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                        data-bs-original-title="移除图像" data-bs-toggle="tooltip" data-kt-image-input-action="remove"
                        title=""> <i class="bi bi-x fs-2"></i>
										</span>
                    <!--end::Remove-->
                  </div>
                  <!--end::Image input-->
                  <!--begin::Hint-->
                  <div class="form-text">允许的文件类型: png, jpg, jpeg.
                    <br>
                    建议尺寸：130*130
                  </div>
                  <!--end::Hint-->
                </div>
              </el-form-item>

            </el-form>
            <div style="text-align: center">
              <el-button round type="primary" @click="submitForm">注&nbsp;&nbsp;册</el-button>
            </div>
          </div>
          <!--end::Wrapper-->
        </div>
        <!--end::Content-->
        <!--begin::Footer-->
        <div class="d-flex flex-center flex-column-auto p-10">
          <!--begin::Links-->
          <div class="d-flex align-items-center fw-bold fs-6">
            <a class="text-muted text-hover-primary" href="jd.jsp">联系我们：</a>
            <a class="text-muted text-hover-primary px-2" href="jd.jsp">{{ obj.phone }}</a>
            <a class="text-muted text-hover-primary px-2" href="jd.jsp">{{ obj.email }}</a>
            <a class="text-muted text-hover-primary px-2" href="https://beian.miit.gov.cn"
               target="_blank">{{ obj.webIcp }}</a>
          </div>
          <!--end::Links-->
        </div>


        <!--end::Footer-->
      </div>
      <!--end::Authentication - Sign-in-->
    </div>
  </div>
</template>

<script>

import {mixins} from "@/views/VipRegister/mixins";

export default {
  name: "VipRegister",
  mixins: [mixins]
}
</script>

<style>
.regFromText > .el-form-item__label {
  font-weight: bold !important;
  font-size: 1.075rem !important;
  color: var(--bs-text-dark) !important;
}

.regFromInput > .el-input__inner, .regFromInput > .el-input--suffix {
  background-color: var(--bs-gray-100) !important;
  border-color: var(--bs-gray-100) !important;
  color: var(--bs-gray-700) !important;
  transition: color .2s ease !important;
  min-height: calc(1.5em + 1.65rem + 2px) !important;
  padding: 0.825rem 1.5rem !important;
  font-size: 1.15rem !important;
  border-radius: 1.25rem !important;
}

.el-form-item {
  margin-bottom: 10px !important;
}

.input_span {
  height: 8px;
  display: flex;
  float: right;
  width: 35%;

  span {
    display: inline-block;
    width: 30%;
    border-radius: 8px;
    margin-right: 3px;
    text-align: center;
    margin-top: 3px;
  }
}
</style>
