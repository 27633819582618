import service from "@/utils/request"

export function getTopicGoodsList(data) {
    return service({
        url: '/wxShopTopicGoods/getTopicGoodsList',
        method: 'get',
        params:data
    })
}

export function getTopicGoodsCount(data) {
    return service({
        url: '/wxShopTopicGoods/getTopicGoodsCount',
        method: 'get',
        params:data
    })
}

export function getTopicGoodGroupList(data){
    return service({
        url: '/wxShopTopicGoods/getTopicGoodGroupList',
        method: 'get',
        params:data
    })
}

export function calculateTopicSalePrice(data){
    return service({
        url: '/wxShopTopicGoods/calculateTopicSalePrice',
        method: 'post',
        data
    })
}

export function updateAddPrice(data){
    return service({
        url: '/wxShopTopicGoods/updateAddPrice',
        method: 'post',
        data
    })
}

export function batchUpdateAddPrice(data) {
    return service({
        url: '/wxShopTopicGoods/batchUpdateAddPrice',
        method: 'post',
        data
    })
}

export function deleteById(data){
    return service({
        url: '/wxShopTopicGoods/deleteById',
        method: 'delete',
        params:data
    })
}

export function batchDeleteByIds(data){
    return service({
        url: '/wxShopTopicGoods/batchDeleteByIds',
        method: 'post',
        data:data
    })
}
