<template>
  <div>
    <el-dialog title="商品信息" :visible.sync="buyOneVisible" width="60%" @close="closeDialog">
      <el-descriptions class="margin-top" title="" :column="1" border>
        <!-- <template slot="extra">
          <el-button type="primary" size="small">操作</el-button>
        </template> -->
        <el-descriptions-item>
          <template slot="label">
            标题
          </template>
          {{ item.title }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <!-- <i class="el-icon-mobile-phone"></i> -->
            市场价
          </template>
          {{ item.price | format }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <!-- <i class="el-icon-location-outline"></i> -->
            分销价
          </template>
          {{ item.supplPrice | format }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <!-- <i class="el-icon-tickets"></i> -->
            销售方式
          </template>
          {{ item.buyType }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <!-- <i class="el-icon-office-building"></i> -->
            收件人信息
          </template>
          <el-descriptions title="" direction="vertical" :column="5" border>
            <el-descriptions-item label="收件人">{{ addressObj.name }}</el-descriptions-item>
            <el-descriptions-item label="手机号">{{ addressObj.tel }}</el-descriptions-item>
            <el-descriptions-item label="收件地址">{{ addressObj.proCity }}{{ addressObj.address }}</el-descriptions-item>
            <el-descriptions-item label="详细地址">{{ addressObj.address }}</el-descriptions-item>
            <el-descriptions-item label="操作"><el-link type="primary" @click="addAddress"><i
                class="el-icon-plus el-icon--right"></i>新增</el-link><el-link type="primary" style="margin-left: 10px;"
                                                                               @click="moreAddress">更多</el-link></el-descriptions-item>
          </el-descriptions>

        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <!-- <i class="el-icon-office-building"></i> -->
            购买数量
          </template>
          <el-input-number v-model="num" :min="1"></el-input-number>
        </el-descriptions-item>
      </el-descriptions>
      <span slot="footer" class="dialog-footer">
        <el-button @click="buyOneVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitBuy">购 买</el-button>
      </span>
    </el-dialog>

    <!-- 修改或新增收货地址 -->
    <el-dialog :title="status == 1 ? '新增收货信息' : '编辑收货信息'" :close-on-click-modal="false" :visible.sync="createOrEdit"
               width="30%">
      <el-form :model="editAddressObj">
        <el-form-item label="收货人" style="width:100%">
          <el-input v-model="editAddressObj.name" placeholder="请输入收货人"></el-input>
        </el-form-item>
        <el-form-item label="所在地区" style="margin-bottom:0">
        </el-form-item>
        <el-form :inline="true" label-position="top">
          <el-form-item label="省">
            <el-select v-model="editAddressObj.pro" style="width:120px" @change="proChange">
              <el-option v-for="item in proList" :label="item.name" :value="item.kid" :key="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="市">
            <el-select v-model="editAddressObj.city" style="width:120px" @change="cityChange">
              <el-option v-for="item in cityList" :label="item.name" :value="item.kid" :key="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="县(区)">
            <el-select v-model="editAddressObj.area" style="width:120px" @change="areaChange">
              <el-option v-for="item in areaList" :label="item.name" :value="item.kid" :key="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="街道">
            <el-select v-model="editAddressObj.stree" style="width:120px">
              <el-option v-for="item in streeList" :label="item.name" :value="item.kid" :key="item.id"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <el-form-item label="详细地址">
          <el-input v-model="editAddressObj.address" placeholder="请输入详细地址"></el-input>
        </el-form-item>
        <el-form-item label="联系电话">
          <el-input v-model="editAddressObj.tel" placeholder="请输入联系电话"></el-input>
        </el-form-item>
        <el-checkbox v-model="editAddressObj.isType" true-label="1" false-label="2">设置默认地址</el-checkbox>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="createOrEdit = false">取 消</el-button>
        <el-button type="primary" @click="saveAddress">保 存</el-button>
      </span>
    </el-dialog>
    <!-- 收货地址列表 -->
    <el-dialog title="收货信息列表" :visible.sync="addressListDialog" width="50%">
      <el-table :data="addressList">
        <el-table-column property="name" label="收件人"></el-table-column>
        <el-table-column property="proCity" label="收件地址"></el-table-column>
        <el-table-column property="address" label="详细地址"></el-table-column>
        <el-table-column property="tel" label="手机号"></el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="selectAddress(scope.row)">选择</el-button>
            <el-button type="text" size="small" @click="editAddress(scope.row)">编辑</el-button>
            <el-button type="text" size="small" v-if="scope.row.isType == 2"
                       @click="setDefalt(scope.row)">设置默认</el-button>
            <el-button type="text" size="small" v-if="scope.row.isType == 2" @click="delAddress(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addressListDialog = false">取 消</el-button>
        <el-button type="primary" @click="addressListDialog = false">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>

import {
  addAddressApi,
  defaultAddressApi, deleteAddressApi,
  getAddressInfoApi,
  getAddressPageApi, getJdRegionListApi,
  updateAddressApi
} from "@/api/chooseGoodsApi";

  export default {
      props:{
        buyOneVisible:{
          type:Boolean,
          default:false
        },
        item:{
          type:Object,
          default:{}
        }

      },
      data(){
        return{
          dialogVisible: false,
          createOrEdit: false,
          addressListDialog: false,
          addressObj:{

          },
          addressList: [],
          num:"1",
          status: 1,
          editAddressObj: {
            name: "",
            tel: "",
            address: "",
            isType: 1,
            pro: 0,
            city: 0,
            area: 0,
            stree: 0
          },
          proList: [],
          cityList: [],
          areaList: [],
          streeList: []
        }
      },
      methods:{
        selectAddress(item) {
          this.addressObj = item
          this.addressListDialog = false
          this.$message({
            type: 'success',
            message: '选择成功!'
          });
        },
        async proChange() {
          this.editAddressObj.city = ""
          this.editAddressObj.area = ""
          this.editAddressObj.stree = ""
          this.cityList = []
          this.areaList = []
          this.streeList = []
          this.cityList = await this.initJdRegionList(this.editAddressObj.pro)
        },
        async cityChange() {
          this.editAddressObj.area = ""
          this.editAddressObj.stree = ""
          this.areaList = []
          this.streeList = []
          this.areaList = await this.initJdRegionList(this.editAddressObj.city)
        },
        async areaChange() {
          this.editAddressObj.stree = ""
          this.streeList = []
          this.streeList = await this.initJdRegionList(this.editAddressObj.area)
        },
        async saveAddress() {
          let obj = {
            name: this.editAddressObj.name,
            pro: this.editAddressObj.pro,
            city: this.editAddressObj.city,
            area: this.editAddressObj.area,
            address: this.editAddressObj.address,
            tel: this.editAddressObj.tel,
            isType: this.editAddressObj.isType,
            stree: this.editAddressObj.stree
          }
          if (this.status == 2) {
            obj.id = this.editAddressObj.id
          }
          //修改
          if (this.status == 2) {
            let { data } = await updateAddressApi(JSON.stringify(obj))
          } else {
            //创建
            let { data } = await addAddressApi(obj)
          }
          this.moreAddress()
          this.getDefaultAddressPage()
          this.createOrEdit = false;
        },
        async setDefalt(item) {
          let obj = {
            addressId: item.id,
          }
          let { data } = await defaultAddressApi(obj)
          this.moreAddress()
          // this.getDefaultAddressPage()
          // this.addressListDialog = false;
        },
        async editAddress(item) {
          let { data } = await getAddressInfoApi({
            addressId: item.id
          })
          this.editAddressObj = data
          this.editAddressObj.pro = +data.pro
          this.editAddressObj.city = +data.city
          this.editAddressObj.area = +data.area
          this.editAddressObj.stree = +data.stree?+data.stree:'';
          this.status = 2;
          this.proList = await this.initJdRegionList(0)
          this.cityList = await this.initJdRegionList(data.pro)
          this.areaList = await this.initJdRegionList(data.city)
          this.streeList = await this.initJdRegionList(data.area)
          this.createOrEdit = true;
        },
        async moreAddress() {
          this.addressListDialog = true;
          let { data } = await getAddressPageApi({
            name: "",
            tel: "",
            isType: "",
            current: 1,
            size: 50
          })
          this.addressList = data.records;
        },
        async delAddress(item) {
          this.$confirm('此操作将永久删除该地址, 是否继续?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(async () => {
            let { data } = await deleteAddressApi({
              addressId: item.id
            })
            let { data: res } = await getAddressPageApi({
              name: "",
              tel: "",
              isType: "",
              current: 1,
              size: 50
            })
            this.addressList = res.records;
            this.$message({
              type: 'success',
              message: '删除成功!'
            });
          }).catch(() => {
            this.$message({
              type: 'info',
              message: '已取消删除'
            });
          });
        },
        async initJdRegionList(id = 0) {
          //编辑时初始化省市区
          let { data } = await getJdRegionListApi({
            pId: id
          })
          return data;
        },
        async addAddress() {
          this.status = 1;
          this.proList = await this.initJdRegionList(0)
          this.editAddressObj = {
            name: "",
            tel: "",
            address: "",
            isType: "2",
            pro: "",
            city: "",
            area: "",
            stree: ""
          }
          this.createOrEdit = true;
        },
        async getDefaultAddressPage() {
          let { data } = await getAddressPageApi({
            name: "",
            tel: "",
            isType: 1,
            current: 1,
            size: 50
          })
          data.records.forEach((e) => {
            if (e.isType == 1) {
              this.addressObj = e;
            }
          })
        },
        closeDialog(){
          this.num=""
          this.$emit("closeDialog")
        },
        submitBuy(){
          this.$message({
            type: 'success',
            message: '提交成功!'
          });
          this.$emit("closeDialog")
        }
      },
    created() {
        this.getDefaultAddressPage();
    }
  }
</script>