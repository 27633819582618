import {mapState} from "vuex";
import {number} from "yup";
import {getCodeApi, registerVipApi} from "@/api/vipUserApi";

export const mixins = {
    data() {
        var validatePassword = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('请再次输入密码'));
            } else if (value !== this.regFrom.staffPassword) {
                callback(new Error('两次输入密码不一致!'));
            } else {
                callback();
            }
        };
        var validateTel = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('请输入手机号'));
            } else if (!/^1[34578]\d{9}$/.test(value)) {
                callback(new Error('手机号格式不正确!'));
            } else {
                callback();
            }
        };
        var validateEmail = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('请输入邮箱地址'));
            } else if (!/^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/.test(value)) {
                callback(new Error('邮箱地址格式不正确!'));
            } else {
                callback();
            }
        };
        return {
            regFrom: {
                staffUsername: "",
                staffPassword: "",
                configPassword: "",
                staffName: "",
                tel: "",
                code: "",
                email: "",
                // 公司名称
                backup: "",
                webSite: 3,
                // 公司级别 1 会长单位 2副会长单位 3理事单位
                region: "",
                // 主营业务
                mainBusiness: "",
                // 公司地址
                companyAddress: "",
                // 公司简介
                companyIntroduction: "",
            },
            msgText: "",
            imageSrc: "",
            imageLOGOSrc: "",
            sendCodeStatus: false,
            sendCodeText: "获取验证码",
            regFromRules: {
                staffUsername: [
                    {required: true, message: "请输入用户名", trigger: "blur"}
                ],
                staffPassword: [
                    {required: true, message: "请输入密码", trigger: "blur"}
                ],
                configPassword: [
                    {required: true, validator: validatePassword, trigger: "blur"}
                ],
                staffName: [
                    {required: true, message: "请输入联系人姓名", trigger: "blur"}
                ],
                tel: [
                    {required: true, validator: validateTel, trigger: "blur"}
                ],
                code: [
                    {required: true, message: "请输入验证码", trigger: "blur"}
                ],
                email: [
                    {required: true, validator: validateEmail, trigger: "blur"}
                ],
                backup: [
                    {required: true, message: "请输入公司名称", trigger: "blur"}
                ],
                companyAddress: [
                    {required: true, message: "请输入公司详细地址", trigger: "blur"}
                ],
                region: [
                    {required: true, message: "请选择公司级别", trigger: "blur"}
                ],
                mainBusiness: [
                    {required: true, message: "请输入公司主营业务", trigger: "blur"}
                ],
                companyIntroduction: [
                    {required: true, message: "请输入公司简介", trigger: "blur"}
                ],
                avatarFile: [
                    {required: true, message: "请输上传营业执照", trigger: "blur"}
                ],
                avatarLOGOFile: [
                    {required: true, message: "请输上传LOGO", trigger: "blur"}
                ],
            },
            countdown: 0,

        }
    },
    computed: {
        ...mapState("web", ["obj"]),
    },

    methods: {
        /**
         * 密码强度
         *
         * @param sValue
         * @returns {number|number}
         */
        checkStrong(sValue) {
            var modes = 0;
            //正则表达式验证符合要求的
            if (sValue.length < 1) return modes;
            if (/\d/.test(sValue)) modes++; //数字
            if (/[a-z]/.test(sValue)) modes++; //小写
            if (/[A-Z]/.test(sValue)) modes++; //大写
            if (/\W/.test(sValue)) modes++; //特殊字符
            // console.log("modes:", sValue.length, modes)
            //逻辑处理
            switch (modes) {
                case 1:
                    return 1;
                    break;
                case 2:
                    return 2;
                    break;
                case 3:
                case 4:
                    return sValue.length < 4 ? 3 : 4;
                    break;
            }
            return modes;
        },
        /**
         * 上传图片
         *
         * @param type 表单中具体的上传框
         */
        uploadPic(type) {
            const fileInput = this.$refs[type];
            if (fileInput.files.length > 0) {
                const selectedFile = fileInput.files[0];
                // 使用FileReader读取上传的文件
                const reader = new FileReader();

                reader.onload = () => {
                    // 将文件转换为Base64格式
                    if (type == "avatarFile") {
                        this.imageSrc = reader.result;
                    } else {
                        this.imageLOGOSrc = reader.result;
                    }
                };

                reader.readAsDataURL(selectedFile);
            }
        },
        /**
         * 提交时验证表单
         */
        verifForm() {
            if (this.regFrom.staffUsername === "") {
                this.$message({
                    message: '请输入登录账号',
                    type: 'warning'
                });
                return false;
            }
            if (this.regFrom.staffPassword === "") {
                this.$message({
                    message: '请输入登录密码',
                    type: 'warning'
                });
                return false;
            }
            if (this.regFrom.configPassword === "") {
                this.$message({
                    message: '请输入确认密码',
                    type: 'warning'
                });
                return false;
            }
            if (this.regFrom.staffPassword !== this.regFrom.configPassword) {
                this.$message({
                    message: '两次密码不一致',
                    type: 'warning'
                });
                return false;
            }
            if (this.regFrom.staffName === "") {
                this.$message({
                    message: '请输入姓名',
                    type: 'warning'
                });
                return false;
            }
            if (this.regFrom.tel === "") {
                this.$message({
                    message: '请输入手机号',
                    type: 'warning'
                });
                return false;
            }
            //验证手机号正则
            if (!this.regFrom.tel.match(/^1[34578]\d{9}$/)) {
                this.$message({
                    message: '手机号格式不正确',
                    type: 'warning'
                });
                return false;
            }

            if (this.regFrom.backup === "") {
                this.$message({
                    message: '请输入公司名',
                    type: 'warning'
                });
                return false;
            }
            if (this.regFrom.region === "") {
                this.$message({
                    message: '请选择公司级别',
                    type: 'warning'
                });
                return false;
            }
            if (this.regFrom.mainBusiness === "") {
                this.$message({
                    message: '请输入主营业务',
                    type: 'warning'
                });
                return false;
            }
            if (this.regFrom.companyAddress === "") {
                this.$message({
                    message: '请输入公司详细地址',
                    type: 'warning'
                });
                return false;
            }
            if (this.regFrom.companyIntroduction === "") {
                this.$message({
                    message: '请输入公司简介',
                    type: 'warning'
                });
                return false;
            }
            if (!this.$refs.avatarFile.files[0]) {
                this.$message({
                    message: '请上传营业执照',
                    type: 'warning'
                });
                return false;
            }
            if (!this.$refs.avatarLOGOFile.files[0]) {
                this.$message({
                    message: '请上传LOGO',
                    type: 'warning'
                });
                return false;
            }
            return true;
        },
        /**
         * 提交表单
         */
        async submitForm() {
            if (!this.verifForm()) {
                return
            }
            let formData = new FormData();
            formData.append("staffUsername", this.regFrom.staffUsername);
            formData.append("staffPassword", this.regFrom.staffPassword);
            formData.append("staffName", this.regFrom.staffName);
            formData.append("tel", this.regFrom.tel);
            formData.append("code", this.regFrom.code);
            formData.append("backup", this.regFrom.backup);
            formData.append("webSite", this.regFrom.webSite);
            formData.append("region", this.regFrom.region);
            formData.append("mainBusiness", this.regFrom.mainBusiness);
            formData.append("companyAddress", this.regFrom.companyAddress);
            formData.append("companyIntroduction", this.regFrom.companyIntroduction);
            formData.append("email", this.regFrom.email);
            formData.append("file", this.$refs.avatarFile.files[0]);
            formData.append("file", this.$refs.avatarLOGOFile.files[0]);
            let data = await registerVipApi(formData)
            if (data.code != 200) {
                return
            }
            this.$message({
                message: "注册成功，等待后台管理人员审核。",
                type: "success",
            });
            this.$router.push("/login");
        },
        sendCode() {
            if (this.countdown > 0 || this.sendCodeStatus) {
                return; // 防止重复点击发送
            }
            if (this.regFrom.tel === "") {
                this.$message({
                    message: '请输入手机号',
                    type: 'warning'
                });
                return;
            }
            //验证手机号正则
            if (!this.regFrom.tel.match(/^1[34578]\d{9}$/)) {
                this.$message({
                    message: '手机号格式不正确',
                    type: 'warning'
                });
                return;
            }

            this.sendCodeStatus = true;
            this.startCountdown();
            this.sendGetCode();

        },
        startCountdown() {
            this.countdown = 60;
            const timer = setInterval(() => {
                this.countdown--;
                if (this.countdown <= 0) {
                    clearInterval(timer);
                }
            }, 1000);

        },
        async sendGetCode() {

            let pram = {
                tel: this.regFrom.tel,
                type: this.obj.tplId
            }
            try {
                await getCodeApi(pram)

            } catch (error) {
                this.countdown = 0;
            }
            this.sendCodeStatus = false;

        },
    },

    watch: {
        'regFrom.staffPassword': {
            handler(keyWord, oldname) {
                // console.log("keyWord:", keyWord)
                this.msgText = this.checkStrong(keyWord);
            }
        }
    },
    created() {
        if (this.regFrom.staffPassword) {
            this.msgText = this.checkStrong(this.regFrom.staffPassword);
        }
    }
}
