<template>
  <div>
    <div class="post d-flex flex-column-fluid" id="kt_post">
      <div
          id="kt_content_container"
          class="container"
          style="
          margin-top: 10px;
          max-width: 100%;
          flex-direction: row !important;
          display: flex;
        "
      >

        <!-- 搜索开始 -->
        <div
            class="flex-column flex-lg-row-auto w-100 w-lg-250px w-xxl-325px mb-8 mb-lg-0 me-lg-9 me-5"
        >
          <form action="#" id="category_form1">
            <div class="card">
              <div class="card-body" style="padding: 1.2rem 1rem">
                <div class="mb-5" style="position: relative">
                  <div class="nav-group nav-group-fluid">
                    <label>
                      <input
                          type="radio"
                          class="btn-check"
                          name="goodsPlace"
                          @click="ownReviewedGoods(1)"
                          :checked="goodsPlace === 1"
                      />
                      <span
                          class="btn btn-sm btn-color-muted btn-active btn-active-primary er px-4 fw-bold"
                      >
                        自有商品
                      </span>
                    </label>
                    <label>
                      <input
                          type="radio"
                          class="btn-check"
                          name="goodsPlace"
                          @click="ownReviewedGoods(2)"
                          :checked="goodsPlace === 2"
                      />
                      <span
                          class="btn btn-sm btn-color-muted btn-active btn-active-primary er px-4 fw-bold"
                      >
                        商品池商品
                      </span>
                    </label>
                  </div>
                  <div v-if="goodsPlace == 2" class="arrow-down" style="position: absolute;bottom: -20px;right: 23%;">
                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#009ef7" class="bi bi-caret-down-fill" viewBox="0 0 16 16">
                      <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                    </svg>
                  </div>
                </div>
                <div class="mb-5  d-flex justify-content-center " v-if="goodsPlace == 2">
                  <div class="nav-group">
                    <label>
                      <input
                          type="radio"
                          class="btn-check"
                          name="isJd"
                          @click="ownReviewedGoods(2,2)"
                          :checked="params.isJd === 2"
                      />
                      <span
                          v-if="!role.includes('vipMember')"
                          style="width: 130px"
                          class="btn btn-sm btn-color-muted btn-active btn-active-primary er px-4 fw-bold"
                      >
                        厂供商品
                      </span>
                      <span
                          v-else
                          class="btn btn-sm btn-color-muted btn-active btn-active-primary er px-4 fw-bold"
                          style="width: 130px"
                      >
                        会员商品
                      </span>
                    </label>
                    <label>
                      <input
                          type="radio"
                          class="btn-check"
                          name="isJd"
                          @click="ownReviewedGoods(2,1)"
                          :checked="params.isJd === 1"
                      />
                      <span
                          v-if="!role.includes('vipMember')"
                          style="width: 130px"
                          class="btn btn-sm btn-color-muted btn-active btn-active-primary er px-4 fw-bold"
                      >
                        京东商品
                      </span>
                    </label>
                  </div>
                </div>
                <div class="mb-5">
                  <label class="fs-6 form-label er text-dark fw-bold">搜索场景</label>
                  <select
                      class="form-select form-select-solid"
                      v-model="selType"
                      data-placeholder="全部"
                      @change="getType"
                  >
                    <option value="" v-if="goodsPlace == 1">全部</option>
                    <option value="group">分组</option>
                    <option value="shop">商城</option>
                    <option value="topic">活动</option>
                  </select>
                </div>
                <div class="mb-5">
                  <label class="fs-6 form-label er text-dark fw-bold">场景范围</label>
                  <select class="form-select form-select-solid" v-model="selTypeId" @change="getStaffList" data-placeholder="全部">
                    <option value="0">全部</option>
                    <option v-for="(item,index) in selArr" :key="index" :value="item.id">{{ item.name || item.title }}</option>
                  </select>
                </div>
                <div class="separator separator-dashed my-8"></div>
                <div class="mb-5">
                  <label class="fs-6 form-label er text-dark fw-bold"
                  >商品标题</label
                  >
                  <div class="position-relative">
                    <span
                        class="svg-icon svg-icon-3 svg-icon-gray-500 position-absolute top-50 translate-middle ms-6"
                    >
                      <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          width="24px"
                          height="24px"
                          viewBox="0 0 24 24"
                          version="1.1"
                      >
                        <g
                            stroke="none"
                            stroke-width="1"
                            fill="none"
                            fill-rule="evenodd"
                        >
                          <rect x="0" y="0" width="24" height="24"></rect>
                          <path
                              d="M14.2928932,16.7071068 C13.9023689,16.3165825 13.9023689,15.6834175 14.2928932,15.2928932 C14.6834175,14.9023689 15.3165825,14.9023689 15.7071068,15.2928932 L19.7071068,19.2928932 C20.0976311,19.6834175 20.0976311,20.3165825 19.7071068,20.7071068 C19.3165825,21.0976311 18.6834175,21.0976311 18.2928932,20.7071068 L14.2928932,16.7071068 Z"
                              fill="#000000"
                              fill-rule="nonzero"
                              opacity="0.3"
                          ></path>
                          <path
                              d="M11,16 C13.7614237,16 16,13.7614237 16,11 C16,8.23857625 13.7614237,6 11,6 C8.23857625,6 6,8.23857625 6,11 C6,13.7614237 8.23857625,16 11,16 Z M11,18 C7.13400675,18 4,14.8659932 4,11 C4,7.13400675 7.13400675,4 11,4 C14.8659932,4 18,7.13400675 18,11 C18,14.8659932 14.8659932,18 11,18 Z"
                              fill="#000000"
                              fill-rule="nonzero"
                          ></path>
                        </g>
                      </svg>
                    </span>
                    <input
                        id="title"
                        type="text"
                        class="form-control form-control-solid ps-10"
                        name="search"
                        v-model="params.title"
                        value=""
                        placeholder="请输入需要查询的商品标题名称"
                    />
                  </div>
                </div>
                <div class="mb-5">
                  <label class="fs-6 form-label er text-dark fw-bold"
                  >一级分类</label
                  >
                  <select
                      class="form-select form-select-solid"
                      v-model="params.categoryId1"
                      data-placeholder="全部"
                      @change="findCategory(1)"
                  >
                    <option value="">全部</option>
                    <option
                        v-for="(item, index) in categoryOne"
                        :key="index"
                        :value="item.categoryId"
                    >
                      {{ item.fullName }}
                    </option>
                  </select>
                </div>
                <div class="mb-5">
                  <label class="fs-6 form-label er text-dark fw-bold"
                  >二级分类</label
                  >
                  <select
                      class="form-select form-select-solid"
                      v-model="params.categoryId2"
                      @change="findCategory(2)"
                      data-placeholder="全部"
                  >
                    <option value="">全部</option>
                    <option
                        v-for="(item, index) in categoryTwo"
                        :key="index"
                        :value="item.categoryId"
                    >
                      {{ item.fullName }}
                    </option>
                  </select>
                </div>
                <div class="mb-5" v-if="goodsPlace === 1">
                  <label class="fs-6 form-label fw-bolder text-dark">商品来源</label>
                  <select class="form-select form-select-solid" v-model="params.goodsStaffId" data-placeholder="全部">
                    <option value="">全部</option>
                    <option v-for="(item, index) in staffArr" :key="index" :value="item.id">{{ item.companyName }}</option>
                  </select>
                </div>
                <div class="d-flex align-items-center justify-content-end">
                  <a
                      @click="shaixuan"
                      href="javascript:void(0);"
                      class="btn btn-primary fw-bold"
                  >筛选</a
                  >&nbsp;&nbsp;&nbsp;
                  <a
                      @click="resetQuery"
                      href="javascript:void(0);"
                      class="btn text-hover-primary bg-hover-light  btn-color-gray-400 me-3 fw-bold"
                  >重置</a
                  >
                </div>
              </div>
            </div>
          </form>
        </div>
        <!-- 列表开始 -->
        <div
            class="card mb-5 mb-xl-8"
            style="margin-left: 3px; width: 100%"
            element-loading-text="拼命加载中"
            element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(0, 0, 0, 0.8)"
            v-loading="loading"
        >
          <div class="card-body py-3" style="padding-left: 15px">
            <div class="table-responsive">
              <table
                  class="table align-middle table-row-dashed table-hover fs-6 gy-5 dataTable no-footer"
                  id="kt_ecommerce_products_table"
              >
                <thead>
                <tr
                    class="text-start text-gray-500  fs-7 text-uppercase gs-0"
                >
                  <!--<th
                      class="w-10px pe-2 sorting_disabled"
                      rowspan="1"
                      colspan="1"
                      aria-label=""
                      style="width: 2%"
                  >
                    <div
                        class="form-check form-check-sm form-check-custom form-check-solid me-3"
                    >
                      <input
                          class="form-check-input"
                          type="checkbox"
                          data-kt-check="true"
                          data-kt-check-target="#kt_ecommerce_products_table .form-check-input"
                          value="TRUE"
                          v-model="checkboxInput"
                          @change="checkboxInputEvent"
                      />
                    </div>
                  </th>-->
                  <th
                      class="min-w-170px"
                      tabindex="0"
                      aria-controls="kt_ecommerce_products_table"
                      rowspan="1"
                      colspan="1"
                      aria-label="Product: activate to sort column ascending"
                      style="min-width: 17%"
                  >
                    商品信息
                  </th>
                  <th
                      class="text-center"
                      tabindex="0"
                      rowspan="1"
                      colspan="1"
                      aria-label="分类"
                      style="min-width: 8%"
                  >
                    分类
                  </th>
                  <th
                      class="text-center min-w-55px"
                      tabindex="0"
                      rowspan="1"
                      colspan="1"
                      aria-label="市场价"
                      style="width: 5%"
                  >
                    市场价
                  </th>
                  <th
                      class="text-center min-w-55px"
                      tabindex="0"
                      rowspan="1"
                      colspan="1"
                      aria-label="分销价"
                      style="width: 5%"
                  >
                    分销价
                  </th>
                  <th
                      v-if="!(goodsPlace == 2 && params.isJd == 1)"
                      class="text-center min-w-55px"
                      tabindex="0"
                      rowspan="1"
                      colspan="1"
                      aria-label="全国商品池"
                      style="width: 4%"
                  >
                    商品池
                  </th>
                  <th
                      v-if="!(goodsPlace == 2 && params.isJd == 1) && !role.includes('vipMember')"
                      class="text-center min-w-55px"
                      tabindex="0"
                      rowspan="1"
                      colspan="1"
                      aria-label="全国分销价(加价)"
                      style="width: 7%"
                  >
                    全国分销价
                  </th>
                  <th
                      class="text-center min-w-55px"
                      tabindex="0"
                      rowspan="1"
                      colspan="1"
                      aria-label="利润率"
                      style="width: 5%"
                  >
                    利润率
                  </th>
                  <th
                      class="text-center min-w-55px"
                      tabindex="0"
                      rowspan="1"
                      colspan="1"
                      aria-label="销售价"
                      style="width: 4%"
                  >
                    销售价
                  </th>
                  <th
                      class="text-center min-w-55px"
                      tabindex="0"
                      rowspan="1"
                      colspan="1"
                      aria-label="销量"
                      style="width: 4%"
                  >
                    销量
                  </th>
                  <th
                      class="text-center min-w-55px"
                      tabindex="0"
                      rowspan="1"
                      colspan="1"
                      aria-label="运费模板"
                      style="width: 10%"
                  >
                    运费模板
                  </th>
                  <th
                      class="text-center min-w-55px"
                      tabindex="0"
                      rowspan="1"
                      colspan="1"
                      aria-label="库存"
                      style="width: 7%"
                  >
                    库存
                  </th>
                  <th
                      class="text-center min-w-55px"
                      tabindex="0"
                      rowspan="1"
                      colspan="1"
                      aria-label="商品来源"
                      style="width: 7%"
                  >
                    商品来源
                  </th>
                  <th
                      class="text-center min-w-55px"
                      tabindex="0"
                      rowspan="1"
                      colspan="1"
                      aria-label="状态"
                      style="width: 6%"
                  >
                    状态
                  </th>
                  <th
                      class="text-center min-w-70px"
                      tabindex="0"
                      rowspan="1"
                      colspan="1"
                      aria-label="更新时间"
                      style="width: 9%"
                  >
                    更新时间
                  </th>
                  <!--<th
                      class="text-center max-w-70px sorting_disabled"
                      rowspan="1"
                      colspan="1"
                      aria-label="操作"
                      style="width: 8%"
                  >
                    操作
                  </th>-->
                </tr>
                </thead>
                <tbody class="fw-semibold text-gray-600">
                <tr
                    v-for="(item, index) in rows"
                    :class="{ old: index % 2 == 0, even: index % 2 != 0 }"
                    :key="index"
                >
                  <!-- 复选按钮 -->
                  <!--                  <td>-->
                  <!--                    <div-->
                  <!--                        class="form-check form-check-sm form-check-custom form-check-solid"-->
                  <!--                    >-->
                  <!--                      <input-->
                  <!--                          class="form-check-input"-->
                  <!--                          type="checkbox"-->
                  <!--                          value="1"-->
                  <!--                          @click="addIdArr(item.id)"-->
                  <!--                          :checked="idArr.includes(item.id)"-->
                  <!--                      />-->
                  <!--                    </div>-->
                  <!--                  </td>-->

                  <!-- 商品信息 -->
                  <td style="padding-right: 0">
                    <div
                        class="d-flex align-items-center"
                        @click="
                          openGoodInfo(
                            item.isJD == 1 ? item.skuId : item.id,
                            item.isJD
                          )
                        "
                    >
                      <a href="javascript:void(0)" class="symbol symbol-50px">
                        <!-- <img class="symbol-label lozad" v-if="item.picUrl.indexOf('http')==0"
                          src="../../assets/images/spinner.gif" :data-src="`${item.picUrl}`">
                      <img class="symbol-label lozad" v-else
                      src="../../assets/images/spinner.gif" :data-src="`https://www.3jzc.cn${item.picUrl}`"> -->
                        <img
                            class="symbol-label lozad"
                            v-if="item.picUrl.indexOf('http') == 0"
                            :src="`${item.picUrl}`"
                        />
                        <img
                            class="symbol-label lozad"
                            v-else
                            :src="`https://www.3jzc.cn${item.picUrl}`"
                        />
                      </a>
                      <div
                          style="
                            max-width: 170px;
                            display: -webkit-box;
                            -webkit-box-orient: vertical;
                            -webkit-line-clamp: 2;
                            overflow: hidden;
                            margin-left: 10px;
                          "
                      >
                        <a
                            href="javascript:void(0)"
                            :title="item.title"
                            class="text-gray-800 text-hover-primary fs-5 "
                            data-kt-ecommerce-product-filter="product_name"
                        >{{ item.title }}</a
                        >
                      </div>
                    </div>
                  </td>
                  <!-- 分类 -->
                  <td class="text-center pe-0">
                    <div
                        class=""
                        style="font-size: 90%"
                        v-html="item.categoryName"
                    ></div>
                  </td>
                  <!-- 市场价 -->
                  <td
                      class="text-center pe-0 text-black "
                  >
                      <span
                          class=""
                          style="
                          font-size: 90%;
                        "
                      >
                        {{ item.price | format }}
                      </span>
                  </td>
                  <!-- 分销价 -->
                  <td
                      class="text-center pe-0 text-black "
                  >
                      <span
                          class=""
                          style="
                          font-size: 90%;
                        "
                      >
                        {{ item.supplPrice | format }}
                      </span>
                  </td>
                  <!-- 全国商品池 -->
                  <td class="text-center pe-0" v-if="!(goodsPlace == 2 && params.isJd == 1)">
                    <span
                        v-if="item.isQuan == 1 && item.status==0"
                        class=" text-warning"
                        style="font-size: 90%"
                        >待审核</span
                      >
                      <span
                        v-if="item.isQuan == 1 && item.status==1"
                        class=" text-success"
                        style="font-size: 90%"
                        >审核通过</span
                      >
                      <span
                        v-if="item.isQuan == 1 && item.status==2"
                        class=" text-danger"
                        style="font-size: 90%"
                        >审核未通过</span
                      >
                      <span
                        v-if="item.isQuan == 2 || (item.status==-1)"
                        class="text-danger"
                        style="font-size: 90%"
                        >不加入</span
                      >
                  </td>
                  <!-- 全国分销价 -->
                  <td v-if="!(goodsPlace == 2 && params.isJd == 1) && !role.includes('vipMember')">
                    <div
                        class="text-center pe-0 text-black  min-w-75px"
                        v-if="item.quanSupplyPrice != '-'"
                        @click="openQuanSupplyPriceDialog(item)"
                    >
                        <span
                            class=""
                            style="
                            font-size: 90%;
                          "
                            v-html="item.quanSupplyPrice"
                        ></span>
                    </div>
                    <div class="text-center" v-else>-</div>
                  </td>
                  <!-- 利润率 -->
                  <td class="text-center pe-0">
                    <span
                        class=""
                        style="font-size: 90%"
                        v-html="item.rightRate"
                    ></span>
                    <span
                        v-if="goodsPlace == 2 && params.isJd == 1"
                        class=""
                        style="font-size: 90%"
                        v-html="item.maxRightRate"
                    ></span>
                  </td>
                  <!-- 销售价 -->
                  <td class="text-center pe-0">
                      <span
                          class=" text-black  text-hover-info border-hover-info cursor-pointer"
                          @click="openSalePriceDialog(item)"
                          style="
                          font-size: 90%;
                        "
                      >
                        {{ item.salePrice | format }}</span
                      >
                  </td>
                  <!-- 销量 -->
                  <td class="text-center pe-0">
                      <span class="" style="font-size: 90%" v-if="item.saleNum">{{
                          item.saleNum
                        }}</span>
                    <span class="" style="font-size: 90%" v-else>0</span>
                  </td>
                  <!-- 运费模板 -->
                  <td class="text-center pe-0">
                      <span
                          class=""
                          style="
                          word-break: break-all;
                          overflow: hidden;
                          font-size: 90%;
                          display: -webkit-box;
                          -webkit-line-clamp: 2;
                          -webkit-box-orient: vertical;
                        "
                          :title="item.freightId"
                      >
                        {{ item.freightId }}
                      </span>
                  </td>
                  <!-- 库存 -->
                  <td class="text-center pe-0">
                      <span
                          class=""
                          @click="editStock(item)"
                          style="
                          font-size: 90%;
                        "
                      >{{ item.stock }}</span
                      >
                  </td>
                  <!-- 商品来源 -->
                  <td class="text-center pe-0">
                      <span class="" style="font-size: 90%">{{
                          item.type
                        }}</span>
                  </td>
                  <!-- 状态 -->
                  <td class="text-center pe-0">
                    <span
                        class="text-center "
                    >
                      <span
                          class="badge badge-light-success fs-7 "
                          style="
                          display: block;
                          text-align: center;
                          margin: 0 auto;
                        "
                          v-html="item.isShow"
                      ></span>

                    </span>
                  </td>
                  <!-- 更新时间 -->
                  <td class="text-center pe-0">
                    <span class="">{{ item.crTime }}</span>
                  </td>
                </tr>
                </tbody>
              </table>
              <Pagination
                  :total-items="totalCount"
                  @page-change="pageChange"
              ></Pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 商品详情 -->
    <ModalDialog title="商品详情" id="goodInfo" ref="goodInfo" width="1000px">
      <GoodInfoComponent ref="goodInfoComponent"></GoodInfoComponent>
    </ModalDialog>

  </div>
</template>
<script>
// 导入数据api
import {
  getReviewedPoolGoodsCountApi,
  getReviewedPoolGoodsListApi,
  getReviewedSjGoodsCountApi,
  getReviewedSjGoodsListApi,
  getStaffListApi
} from "@/api/goodsManageApi";
import {getGoodsGroupByStaffIdApi, getWxShopByStaffIdApi, getWxTopicByStaffIdApi,} from "@/api/groupGoodsApi";
import GoodInfoComponent from "@/components/GoodInfoComponent";
// 页码指定跳转
import Pagination from "@/components/Pagination/index.vue";
import {getCategoryList} from "@/api/categoryApi";
import {mapState} from "vuex";

export default {
  components: {
    Pagination,
    GoodInfoComponent,
  },
  data() {
    return {
      staffArr: [], // 几级供应商
      selTypeId: 0, // 场景范围id
      selType: "",  // 搜索场景类型
      categoryOne: [],
      categoryTwo: [],
      idArr: [],
      rows: [],
      selArr: [],  // 场景范围数组
      checkboxInput: false,
      loading: false,
      params: {
        current: 1,
        size: 10,
        spuId: "",
        title: "",
        categoryId1: "",
        categoryId2: "",
        isShow: 1,
        isTwoShow: 1,
        stock: "",
        isQuan: 1,
        status: 1,
        minStock: "",
        maxStock: "",
        isJd: 2,
        goodsGroupId: "",
        shopId: "",
        topicId: "",
        goodsStaffId: ""
      },
      goodsPlace: 1,   // 1 自有 2 商品池
      totalCount: 999,
      first: true,
    }
  },
  // 方法
  methods: {
    // 选择商城还是选择活动等等
    changeQuery() {
      if (this.selType == "group") {
        this.params.shopId = ""
        this.params.topicId = ""
        this.params.goodsGroupId = "group";
      }
      if (this.selType == "topic") {
        this.params.shopId = ""
        this.params.topicId = this.selTypeId
        this.params.goodsGroupId = "";
      }
      if (this.selType == "shop") {
        this.params.shopId = this.selTypeId
        this.params.topicId = ""
        this.params.goodsGroupId = "";
      }
      this.first = true
      if (this.goodsPlace == 1) {
        this.getSjGoodsList()
      } else {
        this.getPoolGoodsList()
      }
    },
    // 具体的 哪个商城 或者 哪个活动
    async getType() {
      this.selArr=[]
      this.params.shopId = ""
      this.params.topicId = ""
      this.params.goodsGroupId = "";
      if (this.goodsPlace === 1 && this.selType == "") {
        this.selType = ""
      }
      if (this.goodsPlace === 2 && this.selType == "") {
        this.selType = "group"
      }
      this.selTypeId = 0
      if (this.selType == "group") {
        let {data} = await getGoodsGroupByStaffIdApi();
        this.selArr = data

      }
      if (this.selType == "topic") {
        let {data} = await getWxTopicByStaffIdApi();
        this.selArr = data

      }
      if (this.selType == "shop") {
        let {data} = await getWxShopByStaffIdApi();
        this.selArr = data
      }
      this.idArr = []
      this.changeQuery();
    },
    async getInitType() {
      this.selArr=[]
      if (this.goodsPlace === 1 && this.selType == "") {
        this.selType = ""
        this.selTypeId = ""
      }
      if (this.goodsPlace === 2 && this.selType == "") {
        this.selType = "group"
      }
      this.selTypeId = 0
      if (this.selType == "group") {
        let {data} = await getGoodsGroupByStaffIdApi();
        this.selArr = data
      }
      if (this.selType == "topic") {
        let {data} = await getWxTopicByStaffIdApi();
        this.selArr = data
      }
      if (this.selType == "shop") {
        let {data} = await getWxShopByStaffIdApi();
        this.selArr = data
      }
      this.idArr = []
      //this.changeQuery();
    },
    checkboxInputEvent() {
      if (this.checkboxInput) {
        this.rows.forEach((e) => {
          if (!this.idArr.includes(e.id)) {
            this.idArr.push(e.id);
          }
        });
      } else {
        this.rows.forEach((e) => {
          let index = this.idArr.indexOf(e.id);
          if (index !== -1) {
            this.idArr.splice(index, 1);
          }
        });
      }
      this.$emit("setIdArr", this.idArr)
    },
    async addIdArr(id) {
      if (this.idArr.includes(id)) {
        let index = this.idArr.indexOf(id);
        if (index !== -1) {
          this.idArr.splice(index, 1);
        }
      } else {
        this.idArr.push(id);
      }
      let a = true;
      this.rows.forEach((e) => {
        if (!this.idArr.includes(e.id)) {
          a = false;
        }
      });
      if (this.rows, length == 0) {
        a = false
      }
      this.$emit("setIdArr", this.idArr)
      this.checkboxInput = a;
    },

    openGoodInfo(id, isJd) {
      this.$refs.goodInfo.open();
      this.$refs.goodInfoComponent.openGoodsInfo(id, isJd);
    },

    // 分页查询 自有商品
    async getSjGoodsList() {
      this.checkboxInput = false;
      this.loading = true;
      let json = JSON.parse(JSON.stringify(this.params))
      if (this.selType == "group") {
        json.shopId = ""
        json.topicId = ""
        json.goodsGroupId = this.selTypeId;
      }
      if (this.selType == "topic") {
        json.shopId = ""
        json.topicId = this.selTypeId;
        json.goodsGroupId = "";
      }
      if (this.selType == "shop") {
        json.shopId = this.selTypeId;
        json.topicId = ""
        json.goodsGroupId = "";
      }
      let {data} = await getReviewedSjGoodsListApi(json);
      this.loading = false;
      this.first = true;
      this.rows = data.rows;
      this.$emit("setIsJd", this.params)
      if (this.first) {
        this.getSjGoodsPageCount();
      }
      this.first = false;
      let a = true;
      this.rows.forEach((e) => {
        if (!this.idArr.includes(e.id)) {
          a = false;
        }
      });
      if (this.rows, length == 0) {
        a = false
      }
      this.checkboxInput = a;
    },

    // 分页查询 商品池商品
    async getPoolGoodsList() {
      let json = JSON.parse(JSON.stringify(this.params))
      if (this.selType == "group") {
        json.shopId = ""
        json.topicId = ""
        json.goodsGroupId = this.selTypeId;
      }
      if (this.selType == "topic") {
        json.shopId = ""
        json.topicId = this.selTypeId
        json.goodsGroupId = "";
      }
      if (this.selType == "shop") {
        json.shopId = this.selTypeId
        json.topicId = ""
        json.goodsGroupId = "";
      }
      this.checkboxInput = false;
      this.loading = true;
      this.first = true;
      let {data} = await getReviewedPoolGoodsListApi(json);
      this.loading = false;
      this.rows = data.rows;
      if (this.first) {
        this.getPoolGoodsPageCount();
      }
      this.first = false;
      let a = true;
      this.rows.forEach((e) => {
        if (!this.idArr.includes(e.id)) {
          a = false;
        }
      });
      this.checkboxInput = a;
    },
    // 分页
    pageChange(currentPage, pageSize) {
      this.params.current = currentPage;
      this.params.size = pageSize;
      if (this.goodsPlace == 1) {
        this.getSjGoodsList();
      } else {
        this.getPoolGoodsList();
      }
    },
    async getSjGoodsPageCount() {
      let json = JSON.parse(JSON.stringify(this.params))
      if (this.selType == "group") {
        json.shopId = ""
        json.topicId = ""
        json.goodsGroupId = this.selTypeId;
      }
      if (this.selType == "topic") {
        json.shopId = ""
        json.topicId = this.selTypeId;
        json.goodsGroupId = "";
      }
      if (this.selType == "shop") {
        json.shopId = this.selTypeId;
        json.topicId = ""
        json.goodsGroupId = "";
      }
      let {data} = await getReviewedSjGoodsCountApi(json);
      this.totalCount = data;
    },
    async getPoolGoodsPageCount() {
      let json = JSON.parse(JSON.stringify(this.params))
      if (this.selType == "group") {
        json.shopId = ""
        json.topicId = ""
        json.goodsGroupId = this.selTypeId;
      }
      if (this.selType == "topic") {
        json.shopId = ""
        json.topicId = this.selTypeId;
        json.goodsGroupId = "";
      }
      if (this.selType == "shop") {
        json.shopId = this.selTypeId;
        json.topicId = ""
        json.goodsGroupId = "";
      }
      let {data} = await getReviewedPoolGoodsCountApi(json);
      this.totalCount = data;
    },
    toggleDropdown(index) {
      this.$nextTick(() => {
        const dropdown = document.getElementById(`dropdown-${index}`);
        new KTMenu(dropdown);
      });
    },
    // 筛选
    shaixuan() {
      this.idArr = []
      this.first = true;
      if (this.goodsPlace == 1) {
        this.getSjGoodsList();
      } else {
        this.getPoolGoodsList();
      }
      this.$emit("setIdArr", this.idArr)
    },
    // 条件重置
    resetQuery(item) {
      if (this.goodsPlace === 1) {
        this.selType = ""
      }
      if (this.goodsPlace === 2) {
        this.selType = "group"
      }
      this.selArr = []
      this.selTypeId = 0
      this.params = {
        current: 1,
        size: 10,
        spuId: "",
        title: "",
        categoryId1: "",
        categoryId2: "",
        isShow: 1,
        isTwoShow: 1,
        stock: "",
        isQuan: 1,
        status: 1,
        minStock: "",
        maxStock: "",
        isJd: this.params.isJd,
        goodsGroupId: "",
        shopId: "",
        topicId: "",
        goodsStaffId: ""
      }
      if (this.goodsPlace == 1) {
        this.getSjGoodsList();
      } else {
        this.getPoolGoodsList();
      }
    },
    async findCategory(index) {
      this.first = true;
      //this.params.isShow = index;
      if (index == 1) {
        if (!this.params.categoryId1){
          this.params.categoryId1 = 0;
          this.params.categoryId2 = "";
          this.categoryTwo = [];
        }
        let {data} = await getCategoryList({
          parentId: this.params.categoryId1,
        });
        if (this.params.categoryId1 !== 0) {
          this.categoryTwo = data.list;
        }
      }
      if (this.params.categoryId1 === 0){
        this.params.categoryId1 = "";
      }
      if (this.goodsPlace == 1) {
        this.getSjGoodsList();
      } else {
        this.getPoolGoodsList();
      }
    },
    //初始化分类
    async initCategory() {
      let {data} = await getCategoryList({
        parentId: 0,
      });
      this.categoryOne = data.list;
    },
    // 点击自由商品和商品池商品
    ownReviewedGoods(goodsPlace, place) {
      // 切换时筛选条件置为空
      this.params = {
        current: 1,
        size: 10,
        spuId: "",
        title: "",
        categoryId1: "",
        categoryId2: "",
        isShow: 1,
        isTwoShow: 1,
        stock: "",
        isQuan: 1,
        status: 1,
        minStock: "",
        maxStock: "",
        isJd: 2,
        goodsGroupId: "",
        shopId: "",
        topicId: "",
        goodsStaffId: ""
      }
      this.idArr = []
      this.$emit("setIdArr", this.idArr)
      this.selType = goodsPlace == 1 ? "" : "group"
      this.selTypeId = 0;
      //this.selType="group";
      this.params.goodsGroupId = "";
      this.params.shopId = "";
      this.params.topicId = "";
      this.goodsPlace = goodsPlace;
      if (place) {
        this.params.isJd = place;
      }
      if (!place){
        this.getInitType()
      }
      if (goodsPlace == 1) {
        this.getSjGoodsList();
      } else {
        this.getPoolGoodsList();
      }
    },
    async getStaffList() {
      let {data} = await getStaffListApi();
      this.staffArr = data;
    },
    // 父类index使用
    createGoods() {
    },

  },
  // 初始化这个方法
  created() {
    this.getSjGoodsList();
    this.initCategory();
    this.getStaffList();
  },
  computed: {
    ...mapState('user', ['role'])
  },
  updated() {
    // 当数据更新后，可以在此处手动初始化下拉菜单插件
    this.rows.forEach((_, index) => {
      this.toggleDropdown(index);
    });
  },
};
</script>
<!--组件样式-->
<style scoped>
.borderHover:hover {
  border-color: rgb(0, 158, 247) !important;
}

/* 修改滚动条样式 */
.table-responsive::-webkit-scrollbar {
  width: 20px; /* 设置滚动条宽度 */
  height: 15px;
  background-color: #F5F5F5; /* 设置滚动条背景颜色 */
}

.table-responsive::-webkit-scrollbar-thumb {
  background-color: #888; /* 设置滚动条滑块颜色 */
  border-radius: 5px; /* 设置滚动条滑块圆角 */
}

.table-responsive::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* 设置滚动条滑块在鼠标悬停时的颜色 */
}

</style>
