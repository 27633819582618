import Pagination from "@/components/Pagination/index.vue";
import GoodInfoComponent from "@/components/GoodInfoComponent/index.vue";
import {
    addAddressApi,
    buyPoolGoodsApi,
    defaultAddressApi,
    deleteAddressApi,
    getAddressInfoApi,
    getAddressPageApi,
    getChooseGoodsListApi,
    getChooseGoodsListCountApi,
    getJdRegionListApi,
    selectionGoodsApi,
    updateAddressApi,
    updateGoodsInfoApi
} from "@/api/chooseGoodsApi";
import {getGoodsGroupByStaffIdApi, getWxShopByStaffIdApi, getWxTopicByStaffIdApi} from "@/api/groupGoodsApi";
import {getCategoryList} from "@/api/categoryApi";

export const mixins = {
    components: {
        Pagination,
        GoodInfoComponent,
    },
    data() {
        return {
            dialogVisible: false,
            createOrEdit: false,
            addressListDialog: false,
            groupProps: [],
            shopProps: [],
            topicProps: [],
            unitOptions: [
                {
                    value: "1",
                    label: "%",
                },
                {
                    value: "2",
                    label: "元",
                },
            ],
            adjustOptions: [
                {
                    value: "2",
                    label: "下降",
                },
            ],
            options: [
                //{
                //  value: "1",
                //  label: "销售价",
                //},
                {
                    value: "2",
                    label: "市场价",
                },
                {
                    value: "3",
                    label: "分销价",
                },
            ],
            salePriceReference: "1",
            checkboxInput: true,
            idArr: [],
            categoryOne: [],
            categoryTwo: [],
            loading: false,
            pushGoodsObj: {
                topicIds: [],
                shopIds: [],
                goodsGroupIds: [],
                selectionObjects: [],
                goodsIds: [],
                isJd: 1,
                salePriceChange: "2",
                supplPriceChange: "2",
                salePriceDto: {
                    salePriceReference: "2",
                    salePriceAdjust: "2",
                    salePriceNum: "",
                    salePriceUnit: "1",
                },
                supplPriceDto: {
                    supplPriceReference: "1",
                    supplPriceAdjust: "2",
                    supplPriceNum: "",
                    supplPriceUnit: "1",
                },
            },
            rows: [],
            params: {
                isJd: 2,
                current: 1,
                size: 10,
                sTitle: "",
                sPhone: "",
                minSupplyPrice: "",
                maxSupplyPrice: "",
                maxRightRate: "",
                minRightRate: "",
                categoryId1: "",
                categoryId2: "",
            },
            totalCount: 99999,
            first: true,
            goodsId: "",
            isJd: 1,
            id: "",
            state: "",
            item: "",
            num: 1,
            addressList: [],
            addressObj: {

            },
            status: 1,
            editAddressObj: {
                name: "",
                tel: "",
                address: "",
                isType: 1,
                pro: 0,
                city: 0,
                area: 0,
                stree: 0
            },
            proList: [],
            cityList: [],
            areaList: [],
            streeList: []
        };
    },
    methods: {
        setTree(data,typeName){
            this.$refs[typeName].setCheckedKeys([]);
            if (data){
                this.$refs[typeName].setCheckedNodes([data]);
            }
        },
        groupTreeNodeClick(data){
            this.setTree(data,"groupTree")
        },
        shopTreeNodeClick(data){
            this.setTree(data,"shopTree")
        },
        topicTreeNodeClick(data){
            this.setTree(data,"topicTree")
        },
        selectChange(){
            if (this.pushGoodsObj.salePriceDto.salePriceReference==2){
                this.pushGoodsObj.salePriceDto.salePriceAdjust="2";
                this.adjustOptions= [
                    {
                        value: "2",
                        label: "下降",
                    },
                ]
            }else{
                this.adjustOptions= [
                    {
                        value: "1",
                        label: "上调",
                    },
                    {
                        value: "2",
                        label: "下降",
                    },
                ]
            }
        },
        //购买商品
        payGoods() {
            this.$confirm('你确认购买当前商品?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(async () => {
                let obj = {
                    goodsId: this.item.id,
                    supplyPrice: this.item.supplPrice,
                    goodsIsJd: this.item.isJD,
                    addressId: this.addressObj.id,
                    goodsQuantity: this.num
                }
                let { data } = await buyPoolGoodsApi(obj)
                this.dialogVisible = false;
                this.$message({
                    type: 'success',
                    message: '购买成功!'
                });
            }).catch(() => {

            });
        },
        selectAddress(item) {
            this.addressObj = item
            this.addressListDialog = false
            this.$message({
                type: 'success',
                message: '选择成功!'
            });
        },
        async proChange() {
            this.editAddressObj.city = ""
            this.editAddressObj.area = ""
            this.editAddressObj.stree = ""
            this.cityList = []
            this.areaList = []
            this.streeList = []
            this.cityList = await this.initJdRegionList(this.editAddressObj.pro)
        },
        async cityChange() {
            this.editAddressObj.area = ""
            this.editAddressObj.stree = ""
            this.areaList = []
            this.streeList = []
            this.areaList = await this.initJdRegionList(this.editAddressObj.city)
        },
        async areaChange() {
            this.editAddressObj.stree = ""
            this.streeList = []
            this.streeList = await this.initJdRegionList(this.editAddressObj.area)
        },
        async saveAddress() {
            let obj = {
                name: this.editAddressObj.name,
                pro: this.editAddressObj.pro,
                city: this.editAddressObj.city,
                area: this.editAddressObj.area,
                address: this.editAddressObj.address,
                tel: this.editAddressObj.tel,
                isType: this.editAddressObj.isType,
                stree: this.editAddressObj.stree
            }
            if (this.status == 2) {
                obj.id = this.editAddressObj.id
            }
            //修改
            if (this.status == 2) {
                let { data } = await updateAddressApi(JSON.stringify(obj))
            } else {
                //创建
                let { data } = await addAddressApi(obj)
            }
            this.moreAddress()
            this.getDefaultAddressPage()
            this.createOrEdit = false;
        },
        async setDefalt(item) {
            let obj = {
                addressId: item.id,
            }
            let { data } = await defaultAddressApi(obj)
            this.moreAddress()
            // this.getDefaultAddressPage()
            // this.addressListDialog = false;
        },
        async editAddress(item) {
            let { data } = await getAddressInfoApi({
                addressId: item.id
            })
            this.editAddressObj = data
            this.editAddressObj.pro = +data.pro
            this.editAddressObj.city = +data.city
            this.editAddressObj.area = +data.area
            this.editAddressObj.stree = +data.stree?+data.stree:'';
            this.status = 2;
            this.proList = await this.initJdRegionList(0)
            this.cityList = await this.initJdRegionList(data.pro)
            this.areaList = await this.initJdRegionList(data.city)
            this.streeList = await this.initJdRegionList(data.area)
            this.createOrEdit = true;
        },
        async moreAddress() {
            this.addressListDialog = true;
            let { data } = await getAddressPageApi({
                name: "",
                tel: "",
                isType: "",
                current: 1,
                size: 50
            })
            this.addressList = data.records;
        },
        async delAddress(item) {
            this.$confirm('此操作将永久删除该地址, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(async () => {
                let { data } = await deleteAddressApi({
                    addressId: item.id
                })
                let { data: res } = await getAddressPageApi({
                    name: "",
                    tel: "",
                    isType: "",
                    current: 1,
                    size: 50
                })
                this.addressList = res.records;
                this.$message({
                    type: 'success',
                    message: '删除成功!'
                });
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });
        },
        async initJdRegionList(id = 0) {
            //编辑时初始化省市区
            let { data } = await getJdRegionListApi({
                pId: id
            })
            return data;
        },
        async addAddress() {
            this.status = 1;
            this.proList = await this.initJdRegionList(0)
            this.editAddressObj = {
                name: "",
                tel: "",
                address: "",
                isType: "2",
                pro: "",
                city: "",
                area: "",
                stree: ""
            }
            this.createOrEdit = true;
        },
        handleClose(done) {

        },
        async getDefaultAddressPage() {
            let { data } = await getAddressPageApi({
                name: "",
                tel: "",
                isType: 1,
                current: 1,
                size: 50
            })
            data.records.forEach((e) => {
                if (e.isType == 1) {
                    this.addressObj = e;
                }
            })
            this.dialogVisible = true
        },
        async buyGoods(item) {
            // let id= item.isJD == 1 ? item.skuId : item.id;
            this.item = item;
            this.num = 1;
            this.getDefaultAddressPage()
        },
        async pushGood(id, state) {
            this.state = 1;
            this.id = id;
            this.$refs.pushGoodsAll.open();
            this.shopProps = [];
            this.topicProps = [];
            this.groupProps = [];
            let { data: res1 } = await getWxTopicByStaffIdApi();
            res1.forEach((item, index) => {
                this.topicProps.push({
                    id: item.id,
                    label: item.title,
                });
            });
            let { data: res2 } = await getWxShopByStaffIdApi();
            res2.forEach((item, index) => {
                this.shopProps.push({
                    id: item.id,
                    label: item.name,
                });
            });
            let { data: res3 } = await getGoodsGroupByStaffIdApi();
            res3.forEach((item, index) => {
                this.groupProps.push({
                    id: item.id,
                    label: item.name,
                });
            });
        },
        async submitPush(id) {
            this.pushGoodsObj.topicIds = [];
            this.pushGoodsObj.shopIds = [];
            this.pushGoodsObj.goodsGroupIds = [];
            this.pushGoodsObj.goodsIds = [];
            if (this.pushGoodsObj.selectionObjects.includes("topic")) {
                this.pushGoodsObj.topicIds = this.$refs.topicTree.getCheckedKeys();
                if (this.pushGoodsObj.topicIds.length == 0) {
                    let index = this.pushGoodsObj.selectionObjects.indexOf("topic");
                    this.pushGoodsObj.selectionObjects.splice(index, 1);
                }
            }
            if (this.pushGoodsObj.selectionObjects.includes("shop")) {
                this.pushGoodsObj.shopIds = this.$refs.shopTree.getCheckedKeys();
                if (this.pushGoodsObj.shopIds.length == 0) {
                    let index = this.pushGoodsObj.selectionObjects.indexOf("shop");
                    this.pushGoodsObj.selectionObjects.splice(index, 1);
                }
            }
            if (this.pushGoodsObj.selectionObjects.includes("group")) {
                this.pushGoodsObj.goodsGroupIds = this.$refs.groupTree.getCheckedKeys();
                if (this.pushGoodsObj.goodsGroupIds.length == 0) {
                    let index = this.pushGoodsObj.selectionObjects.indexOf("group");
                    this.pushGoodsObj.selectionObjects.splice(index, 1);
                }
            }
            if (this.pushGoodsObj.supplPriceChange == "2") {
                this.pushGoodsObj.supplPriceDto = {
                    supplPriceReference: "",
                    supplPriceAdjust: "",
                    supplPriceNum: "",
                    supplPriceUnit: "",
                };
            }
            if (this.pushGoodsObj.salePriceChange == "2") {
                this.pushGoodsObj.salePriceDto = {
                    salePriceReference: "",
                    salePriceAdjust: "",
                    salePriceNum: "",
                    salePriceUnit: "",
                };
            }
            if (this.id == "") {
                this.pushGoodsObj.goodsIds = this.idArr;
            } else {
                this.pushGoodsObj.goodsIds = [this.id];
            }
            this.pushGoodsObj.isJd = this.params.isJd;

            let arr = ["group", "shop", "topic"];
            let arr1 = [];
            arr.forEach((item) => {
                if (this.pushGoodsObj.selectionObjects.includes(item)) {
                    arr1.push(item);
                }
            });
            let goodNum = this.pushGoodsObj.goodsIds.length;
            let { data } = await selectionGoodsApi(this.pushGoodsObj);
            let total = 0;
            for (let key in data) {
                if (data.hasOwnProperty(key)) {
                    total += data[key];
                }
            }
            if (total === 0) {
                this.$message({
                    message: "全部商品推送成功",
                    type: "success",
                });
            } else if (total >= goodNum) {
                this.$message({
                    message: "商品全部推送失败，请检查价格比例或者商城已存在商品!",
                    type: "error",
                    offset: 110
                });
            } else if (total < goodNum) {
                this.$message.warning({
                    message: "商品部分推送失败，请调整价格比例或部分商品已被推送!",
                    offset: 110
                })
            }
            // this.$message({
            //     message: "推送成功",
            //     type: "success",
            // });
            this.idArr = [];
            this.$refs.pushGoodsAll.close();
            this.$refs.pushGrounp.close();
        },
        async updatePrice(id, isJd) {
            let {data}=await updateGoodsInfoApi({id,isJd})
            // this.$refs.goodInfoComponent.openGoodsInfo(id, isJd);
            this.$message({
                message: "更新成功",
                type: "success",
            });
            this.getChooseGoodsList();
        },
        async nextPush() {
            if (this.pushGoodsObj.selectionObjects.length == 0) {
                this.$message({
                    message: "请选择推送目标",
                    type: "warning",
                });
                return;
            }
            this.$refs.pushGrounp.open();
        },
        // 推送
        async pushAll() {
            this.id = "";
            this.state = ""
            if (this.idArr.length == 0) {
                return;
            }
            this.$refs.pushGoodsAll.open();
            this.shopProps = [];
            this.topicProps = [];
            this.groupProps = [];
            let { data: res1 } = await getWxTopicByStaffIdApi();
            res1.forEach((item, index) => {
                this.topicProps.push({
                    id: item.id,
                    label: item.title,
                });
            });
            let { data: res2 } = await getWxShopByStaffIdApi();
            res2.forEach((item, index) => {
                this.shopProps.push({
                    id: item.id,
                    label: item.name,
                });
            });
            let { data: res3 } = await getGoodsGroupByStaffIdApi();
            res3.forEach((item, index) => {
                this.groupProps.push({
                    id: item.id,
                    label: item.name,
                });
            });
        },
        addIdArr(id) {
            if (this.idArr.includes(id)) {
                let index = this.idArr.indexOf(id);
                if (index !== -1) {
                    this.idArr.splice(index, 1);
                }
            } else {
                this.idArr.push(id);
            }
            let a = true;
            this.rows.forEach((e) => {
                if (!this.idArr.includes(e.id)) {
                    a = false;
                }
            });
            this.checkboxInput = a;
        },
        pageChangeSize() {
            this.idArr = [];
        },
        checkboxInputEvent() {
            if (this.checkboxInput) {
                this.rows.forEach((e) => {
                    if (!this.idArr.includes(e.id)) {
                        this.idArr.push(e.id);
                    }
                });
            } else {
                this.rows.forEach((e) => {
                    let index = this.idArr.indexOf(e.id);
                    if (index !== -1) {
                        this.idArr.splice(index, 1);
                    }
                });
            }
        },
        //重置
        resetQuery() {
            this.params = {
                isJd: this.params.isJd,
                current: 1,
                size: 10,
                sTitle: "",
                sPhone: "",
                minSupplyPrice: "",
                maxSupplyPrice: "",
                maxRightRate: "",
                minRightRate: "",
                categoryId1: "",
                categoryId1List: [],
                categoryId2: "",
            };
            if (this.params.isJd == 1) {
                this.$refs.goodsPlace1.click()
            } else {
                this.$refs.goodsPlace2.click()
            }
            this.getChooseGoodsList();
        },
        async getChooseGoodsList() {
            this.checkboxInput = false;
            this.loading = true;
            let { data } = await getChooseGoodsListApi(this.params);
            this.rows = data.rows;
            if (this.first) {
                this.getPageCount();
            }
            this.first = false;
            this.loading = false;

            let a = true;
            this.rows.forEach((e) => {
                if (!this.idArr.includes(e.id)) {
                    a = false;
                }
            });
            this.checkboxInput = a;
        },
        pageChange(currentPage, pageSize) {
            this.params.current = currentPage;
            this.params.size = pageSize;
            this.getChooseGoodsList();
        },
        async getPageCount() {
            let { data } = await getChooseGoodsListCountApi(this.params);
            this.totalCount = data;
        },
        toggleDropdown(index) {
            this.$nextTick(() => {
                const dropdown = document.getElementById(`dropdown-${index}`);
                new KTMenu(dropdown);
            });
        },
        changIsJd(index) {
            this.idArr=[];
            this.first = true;
            this.params.isJd = index;
            this.getChooseGoodsList();
        },
        openGoodInfo(id, isJd) {
            this.$refs.goodInfo.open();
            this.$refs.goodInfoComponent.openGoodsInfo(id, isJd);
        },
        shaixuan() {
            this.idArr = [];
            this.first = true;
            this.getChooseGoodsList();
        },
        async findCategory(index) {
            this.first = true;
            if (!this.params.categoryId1){
                this.params.categoryId1 = 0;
                this.params.categoryId2 = "";
                this.categoryTwo = [];
            }
            if (index == 1) {
                let { data } = await getCategoryList({
                    parentId: this.params.categoryId1,
                });
                if (this.params.categoryId1 !== 0) {
                    this.categoryTwo = data.list;
                }
            }
            if (this.params.categoryId1 === 0){
                this.params.categoryId1 = "";
            }
            this.getChooseGoodsList();
        },
        //初始化分类
        async initCategory() {
            let { data } = await getCategoryList({
                parentId: 0,
            });
            this.categoryOne = data.list;
        },
        // 选择推送目标改变时触发
        changeSelectionObjects(){
            if (this.pushGoodsObj.selectionObjects == 'group') {
                this.pushGoodsObj.salePriceChange = '2'
                this.pushGoodsObj.salePriceDto.salePriceNum = null
            }
        }
    },
    created() {
        this.getChooseGoodsList();
        this.initCategory();
    },
    mounted() { },
    updated() {
        // 当数据更新后，可以在此处手动初始化下拉菜单插件
        this.rows.forEach((_, index) => {
            this.toggleDropdown(index);
        });
    },
}
