import service from "@/utils/request"

export function getPPTModuleApi(data) {
    return service({
        url: '/export/getPPTModule',
        method: 'get',
        params: data
    })
}

export function exportGoodsPptApi(data) {
    return service({
        url: '/export/ppt',
        method: 'post',
        data,
        responseType: 'blob',
    })
}

/**
 * 查询次数
 */
export function checkPptCanDownloadApi() {
    return service({
        url: '/export/checkPptCanDownload',
        method: 'get',
    })
}

export function getProgrammeExcelApi(data) {
    return service({
        url: '/export/getProgrammeExcel',
        method: 'post',
        data,
        responseType: 'blob',
    })
}

