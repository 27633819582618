export const mixins = {
    data(){
        return{
            payDialog: false,
            index: 1
        }
    },
    methods:{
        checkPay(){
          if (localStorage.getItem('paying')){
              this.$confirm('是否完成支付?', '支付', {
                  confirmButtonText: '已经完成',
                  cancelButtonText: '未完成',
                  type: 'warning'
              }).then(() => {
                  this.checkOrder();
                  localStorage.removeItem("paying")
              }).catch(() => {
                  this.checkOrder();
                  localStorage.removeItem("paying")
              });
          }
        },
        checkOrder(){
            console.log(1)
        },
        pay(){
            this.$refs.VipPayComponent.openPay({
                type: 1,
                rerunUrl: process.env.NODE_ENV === "development"?"http://localhost:8080/#/payCallback":"https://saas.zhelidao.com/#/payCallback"
            })
        },
        handleTabClick(tab) {
            console.log(tab)
            const tab1= document.getElementById('tab1')
            const tab2 = document.getElementById('tab2')
            const tab3 = document.getElementById('tab3')
            if(tab.index==='0'){
                tab1.classList.add('active')
                tab2.classList.remove('active')
                tab3.classList.remove('active')

            }else if(tab.index==='1'){
                tab1.classList.remove('active')
                tab2.classList.add('active')
                tab3.classList.remove('active')
            }else if(tab.index==='2'){
                tab1.classList.remove('active')
                tab2.classList.remove('active')
                tab3.classList.add('active')
            }
            // 在这里可以编写处理点击标签的逻辑
        },


    },
    created() {
        this.checkPay()
    }
}
