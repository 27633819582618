<template>
  <div>
    支付成功正在跳转...
  </div>
</template>
<script>
import {vipCheckPayApi} from "@/api/vipUserApi";
import {mapMutations, mapState} from "vuex";

export default {
  name: "PayCallback",
  data(){
    return{

    }
  },
  created() {
    this.getOrderNo()
  },
  computed: {
    ...mapState('diaLog',['payModal'])
  },
  methods:{
    ...mapMutations('diaLog',['openModal', 'closeModal']),
    async getOrderNo(){
      let {data} = await vipCheckPayApi({
        orderId:this.$route.query.out_trade_no
      });
      console.log(data)
      localStorage.removeItem("paying");
      this.closeModal()
    }
  },
  mounted() {

  }
}
</script>
