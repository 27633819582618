<template>
  <div class="col-xl-4 mb-xl-4">
    <div class="card card-flush py-4  flex-row-fluid">
      <!--begin::Card header-->
      <div class="card-header">
        <div class="card-title">
          <h2>官方客服</h2>
        </div>
      </div>
      <div class="card-body pt-0">
        <div class="table-responsive">
          <table class="table align-middle table-row-bordered mb-0 fs-6 gy-5 min-w-300px">
            <tbody class="fw-semibold text-gray-600">

            <tr>
              <td class="text-muted">
                <div class="d-flex align-items-center">
                  <i class="ki-outline ki-sms fs-2 me-2"></i> 电子邮件
                </div>
              </td>
              <td class="fw-bold text-end">
                <a href="javascript:void(0)"
                   class="text-gray-600 text-hover-primary">{{obj.email}} </a>
              </td>
            </tr>
            <tr>
              <td class="text-muted">
                <div class="d-flex align-items-center">
                  <i class="ki-outline ki-phone fs-2 me-2"></i> 联系电话
                </div>
              </td>
              <td class="fw-bold text-end">{{obj.tel}}
              </td>
            </tr>
            <tr>
              <td class="text-muted">
                <i class="ki-outline ki-profile-circle fs-2 me-2"></i> 客服微信
              </td>
              <td class="fw-bold text-end">
                <img :src="obj.keFu" width="130px">
              </td>
            </tr>
            </tbody>
          </table>
          <!--end::Table-->
        </div>
      </div>
      <!--end::Card body-->
    </div>
  </div>
</template>
<script>
import {mapState} from "vuex";

export default {
  name: 'help',
  computed: {
    ...mapState("web", ["obj"]),
  },
}
</script>

<style>

</style>