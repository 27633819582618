<template>
  <div>
    <div style="width: 50%;margin-left: 25%;min-height: 700px">
      <div class="mb-10" id="kt_social_feeds_posts" v-for="(item,index) in boardList">
        <!--begin::Post 1-->
        <!--begin::Card-->
        <div class="card card-flush mb-10">
          <!--begin::Card header-->
          <div class="card-header pt-9">
            <!--begin::Author-->
            <div class="d-flex align-items-center">
              <!--begin::Avatar-->
              <div class="symbol symbol-50px me-5">
                <img :src="item.imgUrl" class="" alt="">
              </div>
              <!--end::Avatar-->
              <!--begin::Info-->
              <div class="flex-grow-1">
                <!--begin::Name-->
                <a href="javascript:void(0)" class="text-gray-800 text-hover-primary fs-4 fw-bold">{{item.title}}
                  <span class="text-gray-400 fw-semibold d-block">秘书处</span>
                </a>
                <!--end::Name-->
                <!--begin::Date-->
                <span class="text-gray-400 fw-semibold d-block">{{ item.createTime | formatDate }}</span>
                <!--end::Date-->
              </div>
              <!--end::Info-->
            </div>
            <!--end::Author-->
            <!--begin::Card toolbar-->
            <div class="card-toolbar">
              <!--begin::Menu wrapper-->
              <div class="m-0">
                <!--begin::Menu toggle-->
                <button class="btn btn-icon btn-color-gray-400 btn-active-color-primary me-n4" data-kt-menu-overflow="true"
                        data-kt-menu-placement="bottom-end" data-kt-menu-trigger="click" @click="openInfo(item)">
                  <i class="ki-outline ki-dots-square fs-1"></i>
                </button>
              </div>
              <!--end::Menu wrapper-->
            </div>
            <!--end::Card toolbar-->
          </div>
          <!--end::Card header-->
          <!--begin::Card body-->
          <div class="card-body">
            <!--begin::Post content-->
<!--            style="max-height: 200px;overflow: hidden;"-->
            <div class="fs-6 fw-normal text-gray-700 mb-5 vipContent"
                 style="width: 100%;max-height: 200px;overflow: hidden" v-html="item.contents">
            </div>
            <div class="bottom-shadow"></div>
          </div>
          <!--end::Card body-->
        </div>

      </div>
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="params.current"
          :page-sizes="[10, 20, 30, 50]"
          :page-size="100"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
      </el-pagination>
    </div>
    <el-dialog
        :title="item.title"
        :visible.sync="bulletinInfo"
        width="60%">
      <div id="kt_social_feeds_posts" class="mb-10">
        <!--begin::Post 1-->
        <!--begin::Card-->
        <div class="card card-flush mb-10">
          <!--begin::Card header-->
          <div class="card-header pt-9">
            <!--begin::Author-->
            <div class="d-flex align-items-center">
              <!--begin::Avatar-->
              <div class="symbol symbol-50px me-5">
                <img :src="item.imgUrl" alt="" class="">
              </div>
              <!--end::Avatar-->
              <!--begin::Info-->
              <div class="flex-grow-1">
                <!--begin::Name-->
                <a class="text-gray-800 text-hover-primary fs-4 fw-bold" href="javascript:void(0)">
                  <span class="text-gray-400 fw-semibold d-block">秘书处</span>
                </a>
                <!--end::Name-->
                <!--begin::Date-->
                <span class="text-gray-400 fw-semibold d-block">{{ item.createTime | formatDate }}</span>
                <!--end::Date-->
              </div>
              <!--end::Info-->
            </div>
            <!--end::Author-->
            <!--begin::Card toolbar-->
            <div class="card-toolbar">
              <!--begin::Menu wrapper-->
              <div class="m-0">
                <!--begin::Menu toggle-->
              </div>
              <!--end::Menu wrapper-->
            </div>
            <!--end::Card toolbar-->
          </div>
          <!--end::Card header-->
          <!--begin::Card body-->
          <div class="card-body">
            <!--begin::Post content-->
            <!--            style="max-height: 200px;overflow: hidden;"-->
            <div class="fs-6 fw-normal text-gray-700 mb-5 vipContent"
                 style="width: 100%;" v-html="item.contents">
            </div>
            <div class="bottom-shadow"></div>
          </div>
          <!--end::Card body-->
        </div>

      </div>
      <span slot="footer" class="dialog-footer">
      <el-button @click="bulletinInfo = false">关 闭</el-button>
    </span>
    </el-dialog>
  </div>
</template>
<script>
import {bulletinBoard} from "@/views/VipUser/component/bulletinBoard";

export default {
  name: "addressBook",
  components: {},
  mixins: [bulletinBoard]

}
</script>
<style>
.vipContent .wscnph{
  width: 100%;
}
</style>
