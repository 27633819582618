<template>
  <div>
    <!--顶部标题-->
    <TitleCard style="margin: 10px auto; max-width: 97%" title="员工管理">
      <template v-slot:subTitle>
        <ul class="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
          <li class="breadcrumb-item text-muted">Staff Management</li>
        </ul>
      </template>
      <div style="position: absolute;right: 0;padding: 0px 30px;">
        <el-button round size="mini" style="margin-right: 20px" type="primary" @click="openDialogFormCreate">
          添加员工
        </el-button>
      </div>
    </TitleCard>
    <!--高级搜索框-->
    <div
        class="container-fluid d-flex flex-stack flex-column-fluid"
        style="padding: 0 30px"
    >
      <form action="#" style="width: 100%">
        <!--begin::Card-->
        <div class="card">
          <!--begin::Card body-->
          <div class="card-body">
            <!--begin::Compact form-->
            <div class="d-flex align-items-center">
              <!--begin::Input group-->
              <div class="position-relative  me-md-2">
                <span
                    class="svg-icon svg-icon-3 svg-icon-gray-500 position-absolute top-50 translate-middle ms-6"
                >
                  <svg
                      height="24px"
                      version="1.1"
                      viewBox="0 0 24 24"
                      width="24px"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                  >
                    <g
                        fill="none"
                        fill-rule="evenodd"
                        stroke="none"
                        stroke-width="1"
                    >
                      <rect height="24" width="24" x="0" y="0"></rect>
                      <path
                          d="M14.2928932,16.7071068 C13.9023689,16.3165825 13.9023689,15.6834175 14.2928932,15.2928932 C14.6834175,14.9023689 15.3165825,14.9023689 15.7071068,15.2928932 L19.7071068,19.2928932 C20.0976311,19.6834175 20.0976311,20.3165825 19.7071068,20.7071068 C19.3165825,21.0976311 18.6834175,21.0976311 18.2928932,20.7071068 L14.2928932,16.7071068 Z"
                          fill="#000000"
                          fill-rule="nonzero"
                          opacity="0.3"
                      ></path>
                      <path
                          d="M11,16 C13.7614237,16 16,13.7614237 16,11 C16,8.23857625 13.7614237,6 11,6 C8.23857625,6 6,8.23857625 6,11 C6,13.7614237 8.23857625,16 11,16 Z M11,18 C7.13400675,18 4,14.8659932 4,11 C4,7.13400675 7.13400675,4 11,4 C14.8659932,4 18,7.13400675 18,11 C18,14.8659932 14.8659932,18 11,18 Z"
                          fill="#000000"
                          fill-rule="nonzero"
                      ></path>
                    </g>
                  </svg>
                </span>
                <!--end::Svg Icon-->
              </div>
              <!--end::Input group-->
              <div>
                <input
                    id="sTitle"
                    v-model="params.userName"
                    class="form-control form-control-solid ps-10 w-300px me-5"
                    name="shopName"
                    placeholder="登录用户名"
                    type="text"
                    value=""
                />
              </div>
              <!--begin:Action-->
              <div class="d-flex align-items-center">
                <div class="btn btn-primary me-5 btn-sm" @click="getUserStaffList">
                  查询
                </div>
                <div class="btn btn-secondary me-5 btn-sm" @click="clearParams">
                  重置
                </div>
                <a
                    id="kt_horizontal_search_advanced_link"
                    class="btn btn-link"
                    data-bs-toggle="collapse"
                    href="#kt_advanced_search_form"
                >高级查询</a
                >
              </div>
              <!--end:Action-->

            </div>
            <!--begin::高级搜索-->
            <div id="kt_advanced_search_form" class="collapse">
              <!--begin::Separator-->
              <div class="separator separator-dashed mt-9 mb-6"></div>
              <!--end::Separator-->
              <!--begin::Row-->
              <div class="row g-8 mb-8">

                <!--end::Col-->
                <!--begin::Col-->
                <div class="col-lg-3">
                  <label class="fs-6 form-label fw-bolder text-dark"
                  >手机号</label>
                  <br/>
                  <el-input
                      v-model="params.tel"
                      clearable
                      placeholder="请输入手机号"
                      type="text">
                  </el-input>
                </div>
                <!--end::Col-->
                <!--begin::Col-->
                <div class="col-lg-3">
                  <label class="fs-6 form-label fw-bolder text-dark"
                  >状态</label>
                  <br/>
                  <el-select
                      v-model="params.status"
                      clearable
                      placeholder="请选择状态"
                      style="width: 100%">
                    <el-option
                        label="正常"
                        value="1"
                    ></el-option>
                    <el-option
                        label="禁用"
                        value="0"
                    ></el-option>
                  </el-select>

                </div>
                <!--end::Col-->
              </div>
              <!--end::Row-->
            </div>
            <!--end::Advance form-->
          </div>
          <!--end::Card body-->
        </div>
        <!--end::Card-->
      </form>
    </div>
    <!--下半展示列表-->
    <div style="min-height:90vh">
      <div>
        <div id="myTabContent" class="tab-content">
          <div class="card" style="margin: 10px 30px">
            <div class="card-body" style="padding: 1.2rem 1rem;
              box-sizing: content-box;
              padding: 1rem !important;">
              <div class="table-responsive">
                <el-table
                    :data="this.list"
                    style="width: 100%;min-height: 60vh">
                  <el-table-column
                      label="登录用户名"
                      prop="userName">
                  </el-table-column>
                  <el-table-column
                      label="手机号"
                      prop="tel">
                  </el-table-column>
                  <el-table-column
                      label="昵称"
                      prop="name">
                  </el-table-column>
                  <el-table-column
                      label="角色"
                      prop="roleNickName">
                  </el-table-column>
                  <el-table-column
                      label="状态"
                      prop="status">
                    <template slot-scope="scope">
                      <el-tag
                          :type="scope.row.status == 1?'success':'danger'"
                          disable-transitions>{{ scope.row.status == 1 ? '正常' : '禁用' }}
                      </el-tag>
                    </template>
                  </el-table-column>
                  <el-table-column label="操作">
                    <template slot-scope="scope">
                      <a href="#" class="btn btn-sm btn-light btn-flex btn-center btn-active-light-primary"
                         data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end" @click="toggleDropdown(scope.$index)">
                        操作
                        <i class="ki-outline ki-down fs-5 ms-1"></i>
                      </a>
                      <div :id="'dropdown-' + scope.$index"
                           class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-125px py-4"
                           data-kt-menu="true">
                        <div class="menu-item px-3" v-if="scope.row.status == 1">
                          <a href="javascript:void(0)" class="menu-link px-3" @click="disableUser(scope.row.id)">禁用</a>
                        </div>
                        <div class="menu-item px-3" v-else>
                          <a href="javascript:void(0)" class="menu-link px-3" @click="enableUser(scope.row.id)">启用</a>
                        </div>
                        <div class="menu-item px-3">
                          <a href="javascript:void(0)" class="menu-link px-3" @click="openEdit(scope.row)">编辑</a>
                        </div>
                        <div class="menu-item px-3">
                          <a href="javascript:void(0)" class="menu-link px-3" @click="openChangePassword(scope.row)">修改密码</a>
                        </div>
                      </div>
                    </template>
                  </el-table-column>
                </el-table>
                <el-pagination
                    :current-page="this.page.current"
                    :page-size="this.page.size"
                    :page-sizes="[10, 20, 50, 100]"
                    :total="this.page.total"
                    layout="total, sizes, prev, pager, next, jumper"
                    style="display: flex;justify-content: center;"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange">
                </el-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--修改密码弹窗-->
    <el-dialog :title="dialogTitle" :visible.sync="dialogFormVisible" width="500px">
      <el-form ref="changePasswordFrom" :model="form" :rules="rules">
        <el-form-item label="新密码" label-width="150px" prop="firstPassword">
          <el-input v-model="form.firstPassword" :placeholder="form.firstPassword" autocomplete="off" show-password
                    style="width: 200px"></el-input>
        </el-form-item>
        <div style="margin-top: 20px"></div>
        <el-form-item label="重复新密码" label-width="150px" prop="secondPassword">
          <el-input v-model="form.secondPassword" :placeholder="form.secondPassword" autocomplete="off" show-password
                    style="width: 200px"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="closeDialogFormVisible">取 消</el-button>
        <el-button type="primary" @click="changePassword">确 定</el-button>
      </div>
    </el-dialog>
    <!--创建用户弹窗-->
    <el-dialog :visible.sync="dialogFormCreate" title="创建用户" width="700px" :close-on-click-modal="false">
      <el-form :model="formCreate" :rules="rulesCreate" ref="createFrom">
        <el-form-item label="用户名" label-width="190px" prop="userName">
          <el-input v-model="formCreate.userName"
                    placeholder="请输入用户名"
                    autocomplete="off" style="width: 300px"></el-input>
        </el-form-item>
        <div style="margin-top: 20px"></div>
        <el-form-item label="密码" label-width="190px" prop="password">
          <el-input v-model="formCreate.password"
                    autocomplete="off"
                    placeholder="请输入密码"
                    show-password
                    style="width: 300px"></el-input>
        </el-form-item>
        <div style="margin-top: 20px"></div>
        <el-form-item label="重复新密码" label-width="190px" prop="secondPassword">
          <el-input v-model="formCreate.secondPassword"
                    autocomplete="off"
                    placeholder="请再输入一次密码"
                    show-password
                    style="width: 300px"></el-input>
        </el-form-item>
        <div style="margin-top: 20px"></div>

        <el-form-item label="姓名" label-width="190px" prop="name">
          <el-input v-model="formCreate.name" autocomplete="off"
                    placeholder="请输入姓名"
                    style="width: 300px"></el-input>
        </el-form-item>
        <div style="margin-top: 20px"></div>

        <el-form-item label="手机号" label-width="190px" prop="tel">
          <el-input v-model="formCreate.tel" autocomplete="off"
                    placeholder="请输入手机号"
                    style="width: 300px"></el-input>
        </el-form-item>
        <div style="margin-top: 20px"></div>
        <el-form-item label="角色" label-width="190px" prop="roleName">
          <el-select v-model="formCreate.roleName" placeholder="请选择">
            <el-option
                v-for="item in staffRoleList"
                :key="item.roleName"
                :label="item.roleNickName"
                :value="item.roleName">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="closeDialogFormCreate">取 消</el-button>
        <el-button type="primary" @click="secondaryDistributorsRegister">确 定</el-button>
      </div>
    </el-dialog>
    <!--编辑用户弹窗-->
    <el-dialog :visible.sync="dialogFormEdit" :title="dialogTitleEdit" width="700px" :close-on-click-modal="false">
      <el-form :model="formEdit" :rules="rulesEdit" ref="updateFrom">
        <el-form-item label="用户名" label-width="190px" prop="userName">
          <el-input v-model="formEdit.userName"
                    placeholder="请输入用户名"
                    autocomplete="off" style="width: 300px"></el-input>
        </el-form-item>
        <div style="margin-top: 20px"></div>
        <el-form-item label="姓名" label-width="190px" prop="nickName">
          <el-input v-model="formEdit.name" autocomplete="off"
                    placeholder="请输入姓名"
                    style="width: 300px"></el-input>
        </el-form-item>
        <div style="margin-top: 20px"></div>
        <el-form-item label="手机号" label-width="190px" prop="tel">
          <el-input v-model="formEdit.tel" autocomplete="off"
                    placeholder="请输入手机号"
                    style="width: 300px"></el-input>
        </el-form-item>
        <div style="margin-top: 20px"></div>
        <el-form-item label="角色" label-width="190px" prop="roleName">
          <el-select v-model="formEdit.roleName" placeholder="请选择">
            <el-option
                v-for="item in staffRoleList"
                :key="item.roleName"
                :label="item.roleNickName"
                :value="item.roleName">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="closeDialogFormEdit">取 消</el-button>
        <el-button type="primary" @click="secondaryDistributorsEdit">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getUserStaffInfoByIdApi,
  getUserStaffListApi,
  addUserStaffApi, updateUserStaffApi,
  updateUserStaffPasswordApi,
  updateUserStaffStatusApi,
  getAllStaffRoleApi
} from "@/api/userStaff";

export default {
  name: "userStaff",

  data() {
    const validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'))
        // password 是表单上绑定的字段
      } else if (value !== this.form.firstPassword) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    };
    const validatePass3 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'))
        // password 是表单上绑定的字段
      } else if (value !== this.formCreate.password) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    };
    return {
      params: {
        current: 1,
        size: 10,
        userName: "",
        tel: "",
        status: "",
      },
      staffRoleList:[],
      list: [],
      page: "",
      dialogFormVisible: false,
      dialogFormCreate: false,
      dialogFormEdit: false,
      form: {
        staffId: "",
        firstPassword: '',
        secondPassword: ''
      },
      changePasswordForm: {
        staffId: "",
        password: "",
      },
      dialogTitle: "",
      rules: {
        firstPassword: [
          {required: true, message: '请输入密码', trigger: 'blur'},
          {min: 6, max: 16, message: '密码长度在 6 到 16 个字符', trigger: 'change'}
        ],
        secondPassword: [
          {required: true, validator: validatePass2, trigger: 'change'},
        ]
      },
      rulesCreate: {
        userName: [
          {required: true, message: '请输入用户名', trigger: 'blur'},
        ],
        password: [
          {required: true, message: '请输入密码', trigger: 'blur'},
          {min: 6, max: 16, message: '密码长度在 6 到 16 个字符', trigger: 'change'}
        ],
        secondPassword: [
          {required: true, validator: validatePass3, trigger: 'change'},
        ],
        name: [
          {required: true, message: '请输入姓名', trigger: 'blur'},
        ],
        tel: [
          {required: true, message: '请输入手机号', trigger: 'blur'},
          { pattern: /^1[3456789]\d{9}$/, message: '手机号码格式不正确', trigger: 'blur' }
        ],
        roleName:[
          {required: true, message: '请选择角色', trigger: 'blur'},
        ]
      },
      formCreate: {
        userName: '',
        password: '',
        secondPassword: '',
        name: '',
        tel: '',
        roleName: ''
      },
      dialogTitleEdit:'',
      formEdit:{
        id:'',
        userName: '',
        name: '',
        tel: '',
        roleName:''
      },
      rulesEdit: {
        userName: [
          {required: true, message: '请输入用户名', trigger: 'blur'},
        ],
        name: [
          {required: true, message: '请输入姓名', trigger: 'blur'},
        ],
        tel: [
          {required: true, message: '请输入手机号', trigger: 'blur'},
          { pattern: /^1[3456789]\d{9}$/, message: '手机号码格式不正确', trigger: 'blur' }
        ],
        roleName:[
          {required: true, message: '请选择角色', trigger: 'blur'},
        ]
      },

    }

  },
  methods: {
    toggleDropdown(index) {
      this.$nextTick(() => {
        const dropdown = document.getElementById(`dropdown-${index}`);
        new KTMenu(dropdown);
      });
    },
    async getAllStaffRole(){
      let {data} = await getAllStaffRoleApi();
      this.staffRoleList = data
    },
    async getUserStaffList() {
      if (isNaN(this.params.tel.trim())) {
        this.$message({
          showClose: true,
          message: '请输入正确的手机号',
          type: 'warning'
        });
        return;
      }
      let {data} = await getUserStaffListApi(this.params);
      this.list = data.rows;
      this.page = data;
    },
    handleSizeChange(val) {
      this.params.size = val;
      this.getUserStaffList();
    },
    handleCurrentChange(val) {
      this.params.current = val;
      this.getUserStaffList();
    },
    async disableUser(staffId) {
      let data = await updateUserStaffStatusApi({
        staffId: staffId,
        status: 0
      });
      if (data.code == 200) {
        this.$message({
          showClose: true,
          title: '成功',
          message: '修改成功',
          type: 'success'
        });
      }
      this.getUserStaffList();

    },
    async enableUser(staffId) {
      let data = await updateUserStaffStatusApi({
        staffId: staffId,
        status: 1
      });
      if (data.code == 200) {
        this.$message({
          showClose: true,
          title: '成功',
          message: '修改成功',
          type: 'success'
        });
      }
      this.getUserStaffList();
    },
    clearParams() {
      this.params = {
        current: 1,
        size: 10,
        userName: "",
        tel: "",
        company: "",
        status: "",
      }
      this.getUserStaffList();
    },
    openChangePassword(item) {
      this.dialogTitle = "修改 " + item.userName + " 的密码"
      this.dialogFormVisible = true;
      this.changePasswordForm.staffId = item.id
    },
    closeDialogFormVisible() {
      this.dialogFormVisible = false;
      this.form = {
        staffId: "",
        firstPassword: '',
        secondPassword: ''
      }
    },
    async updateChangePassword() {
      this.changePasswordForm.password = this.form.firstPassword
      let data = await updateUserStaffPasswordApi(this.changePasswordForm)
      if (data.code == 200) {
        this.$message({
          showClose: true,
          title: '成功',
          message: '修改成功',
          type: 'success'
        });
      }
    },
    changePassword() {
      this.$refs.changePasswordFrom.validate((valid) => {
        if (valid) {
          this.updateChangePassword();
          this.dialogFormVisible = false;
          this.form = {
            staffId: "",
            firstPassword: '',
            secondPassword: ''
          }

        }
        return false;
      });
    },
    openDialogFormCreate() {
      this.clearFormCreate();
      this.dialogFormCreate = true;
    },
    closeDialogFormCreate() {
      this.clearFormCreate();
      this.dialogFormCreate = false;
    },
    async submitSecondaryDistributorsRegister(date) {
       try {
         let data = await addUserStaffApi(date);
         if (data.code === 200) {
           this.$message({
             showClose: true,
             title: '成功',
             message: '添加成功',
             type: 'success'
           });
           this.clearFormCreate()
           this.dialogFormCreate = false;
           this.getUserStaffList();
         }
       }catch (e){
         if(e.code===900008){
           //用户名
           this.$refs.createFrom.fields.forEach(field => {
             if (field.prop === 'tel') {
               field.validateState = 'error';
               field.validateMessage = e.msg;
             }
           });
         }else if(e.code===900007){
           //手机号
           this.$refs.createFrom.fields.forEach(field => {
             if (field.prop === 'staffUsername') {
               field.validateState = 'error';
               field.validateMessage = e.msg;
             }
           });
         }
       }


    },
    secondaryDistributorsRegister(){
      this.$refs.createFrom.validate((valid) => {
        if (valid) {
          this.submitSecondaryDistributorsRegister(this.formCreate);

        }
        return false;
      });
    },
    clearFormCreate() {
      this.formCreate = {
        userName: '',
        password: '',
        secondPassword: '',
        name: '',
        tel: '',
      }
    },
    secondaryDistributorsEdit(){
      this.updateDistributorById();

    },
    openEdit(data){
      this.getDistributorById(data.id);
      this.dialogTitleEdit = "编辑 " + data.userName + " 的信息"
      this.dialogFormEdit = true;
    },
    async getDistributorById(id){
      let {data} = await getUserStaffInfoByIdApi({id:id});
      this.formEdit.id = data.id;
      this.formEdit.userName = data.userName;
      this.formEdit.tel = data.tel;
      this.formEdit.name = data.name;
      this.formEdit.roleName = data.roleName;
    },
    async updateDistributorById(){
      try {
        let data = await updateUserStaffApi(this.formEdit)
        if (data.code === 200) {
          this.$message({
            showClose: true,
            title: '成功',
            message: '修改成功',
            type: 'success'
          });
        }
        this.closeDialogFormEdit();
        this.getUserStaffList();
      }catch (e){
        if(e.code===900008){
          //用户名
          this.$refs.updateFrom.fields.forEach(field => {
            if (field.prop === 'tel') {
              field.validateState = 'error';
              field.validateMessage = e.msg;
            }
          });
        }else if(e.code===900007){
          //手机号
          this.$refs.updateFrom.fields.forEach(field => {
            if (field.prop === 'userName') {
              field.validateState = 'error';
              field.validateMessage = e.msg;
            }
          });
        }
      }
    },
    clearFormEdit(){
      this.formEdit = {
        id:'',
        userName: '',
        name: '',
        tel: '',
      }
    },
    closeDialogFormEdit(){
      this.dialogFormEdit = false;
      this.clearFormEdit();
    }
  },
  created() {
    this.getAllStaffRole();
    this.getUserStaffList();
  }
}
</script>
<style scoped>

</style>