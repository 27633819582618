import service from "@/utils/request"
// 查询我的用户
export function getUserStaffListApi(params) {
    return service({
        url: '/userStaff/getUserStaffList',
        method: 'get',
        params
    })
}

// 修改二级供应商状态
export function updateUserStaffStatusApi(params) {
    return service({
        url: '/userStaff/updateUserStaffStatus',
        method: 'put',
        params
    })
}

// 修改二级供应商密码
export function updateUserStaffPasswordApi(data) {
    return service({
        url: '/userStaff/updateUserStaffPassword',
        method: 'post',
        data
    })
}

// 二级供应商创建
export function addUserStaffApi(data) {
    return service({
        url: '/userStaff/addUserStaff',
        method: 'post',
        data
    })
}

// 根据ID查询二级供应商信息
export function getUserStaffInfoByIdApi(params) {
    return service({
        url: '/userStaff/getUserStaffInfoById',
        method: 'get',
        params
    })
}

export function updateUserStaffApi(data) {
    return service({
        url: '/userStaff/updateUserStaff',
        method: 'post',
        data
    })
}

export function getAllStaffRoleApi(data){
    return service({
        url: '/saas-role/getAllStaffRoleByUserId',
        method: 'get',
        params:data
    })
}