<template>
  <div>
    <div class="modal-body">
      <!-- 内容开始 -->
      <div class="post d-flex flex-column-fluid" id="kt_post">
        <!--begin::Container-->
        <div id="kt_content_container" class="container"
             style="max-width:100%;flex-direction:row!important;display:flex;">
          <!-- 搜索开始 -->
          <div class="flex-column flex-lg-row-auto w-100 w-lg-250px w-xxl-300px ">
            <!--begin::Form-->
            <form action="#">
              <!--begin::Card-->
              <div class="card">
                <!--begin::Body-->
                <div class="card-body">
                  <!--begin:Search-->
                  <div class="position-relative">
                    <!--begin::Svg Icon | path: icons/duotone/General/Search.svg-->
                    <span
                        class="svg-icon svg-icon-3 svg-icon-gray-500 position-absolute top-50 translate-middle ms-6">
                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                 xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px"
                                                 viewBox="0 0 24 24" version="1.1">
                                                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                    <rect x="0" y="0" width="24" height="24"></rect>
                                                    <path
                                                        d="M14.2928932,16.7071068 C13.9023689,16.3165825 13.9023689,15.6834175 14.2928932,15.2928932 C14.6834175,14.9023689 15.3165825,14.9023689 15.7071068,15.2928932 L19.7071068,19.2928932 C20.0976311,19.6834175 20.0976311,20.3165825 19.7071068,20.7071068 C19.3165825,21.0976311 18.6834175,21.0976311 18.2928932,20.7071068 L14.2928932,16.7071068 Z"
                                                        fill="#000000" fill-rule="nonzero" opacity="0.3"></path>
                                                    <path
                                                        d="M11,16 C13.7614237,16 16,13.7614237 16,11 C16,8.23857625 13.7614237,6 11,6 C8.23857625,6 6,8.23857625 6,11 C6,13.7614237 8.23857625,16 11,16 Z M11,18 C7.13400675,18 4,14.8659932 4,11 C4,7.13400675 7.13400675,4 11,4 C14.8659932,4 18,7.13400675 18,11 C18,14.8659932 14.8659932,18 11,18 Z"
                                                        fill="#000000" fill-rule="nonzero"></path>
                                                </g>
                                            </svg>
                                        </span>
                    <!--end::Svg Icon-->
                    <input v-model="shopUserQueryParam.name" id="queryUserName" type="text"
                           class="form-control form-control-solid ps-10" name="search" value=""
                           placeholder="请输入需要查询的用户姓名">
                  </div>
                  <!--end:Search-->
                  <!--begin::Border-->
                  <div class="separator separator-dashed my-8"></div>
                  <!--end::Border-->

                  <!--begin::Input group-->
                  <div class="mb-5">
                    <label class="fs-6 form-label fw-bolder text-dark">用户手机号</label>
                    <input v-model="shopUserQueryParam.tel" id="queryUserPhone" type="text"
                           class="form-control form-control form-control-solid" name="city">
                  </div>
                  <!--end::Input group-->

                  <!--begin::Input group-->
                  <div class="mb-5">
                    <label class="fs-6 form-label fw-bolder text-dark">用户分组</label>
                    <input v-model="shopUserQueryParam.groupName" id="queryUserGroup" type="text"
                           class="form-control form-control form-control-solid" name="city">
                  </div>
                  <!--end::Input group-->

                  <!--end::Input group-->
                  <!--begin::Action-->
                  <div class="d-flex align-items-center justify-content-end">
                    <a @click="findUserRecord"
                       class="btn btn-primary">筛选</a>
                    <a @click="resetUserQuery"
                       class="btn btn-active-light-primary btn-color-gray-400 me-3">重置</a>
                  </div>
                  <!--end::Action-->
                </div>
                <!--end::Body-->
              </div>
              <!--end::Card-->
            </form>
            <!--end::Form-->
          </div>
          <!-- 列表开始 -->
          <div class="card mb-5 mb-xl-8" style="margin-left:3px;width:100%;">
            <!--begin::Header-->
            <div class="card-header border-0 pt-5">
              <h3 class="card-title align-items-start flex-column">
                <span class="card-label fw-bolder fs-3 mb-1">用户列表</span> <span
                  class="text-muted mt-1 fw-bold fs-7">该商城下所有用户的管理</span>
              </h3>
              <div class="card-toolbar">
                <a @click="exportUser" class="btn btn-sm btn-primary"
                   style="margin-right:15px; ">
                  导出
                </a>

                <a class="btn btn-sm btn-primary"
                   @click="openSaveShopUserModal(1)"
                   style="margin-right:15px;">
                  <!--begin::Svg Icon | path: icons/duotone/Communication/Add-user.svg-->
                  创建用户
                </a> <a class="btn btn-sm btn-primary"
                        @click="openExcelSave" style="margin-right:15px; ">
                <!--begin::Svg Icon | path: icons/duotone/Communication/Add-user.svg-->
                批量导入用户
              </a> <a class="btn btn-sm btn-primary" style="margin-right:15px;"
                      @click="downLoadUserTemplate()">
                <!--begin::Svg Icon | path: icons/duotone/Communication/Add-user.svg-->
                导入文件下载
              </a>
              </div>
            </div>
            <!--end::Header-->
            <!--begin::Body-->
            <div class="card-body py-3">
              <!--begin::Table container-->
              <div class="table-responsive">
                <!--begin::Table-->
                <table class="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4"
                       id="userTable">
                  <!--begin::Table head-->
                  <thead>
                  <tr class="fw-bolder text-muted bg-light">

                    <th class="ps-4 min-w-80px rounded-start">用户姓名</th>
                    <th class="min-w-80px">手机号</th>
                    <th class="min-w-80px" v-if="currentWxShopObj.shopType===1">积分</th>
                    <th class="min-w-80px">分组</th>
                    <th class="min-w-80px">操作</th>
                  </tr>
                  </thead>
                  <!--end::Table head-->
                  <!--begin::Table body-->
                  <tbody id="userTableBody">
                  <tr v-for="(item, index) in wxShopUserList" v-key="index">
                    <td><span class="text-muted fw-bold text-muted d-block fs-7"></span> {{
                        item.name
                      }}
                    </td>
                    <td><span class="text-muted fw-bold text-muted d-block fs-7">{{
                        item.tel
                      }}</span>
                    </td>
                    <td v-if="currentWxShopObj.shopType===1"><span class="text-muted fw-bold text-muted d-block fs-7">{{
                        item.score
                      }}</span></td>
                    <td><span class="text-muted fw-bold text-muted d-block fs-7">{{
                        item.groupName
                      }}</span></td>
                    <td><a href="javascript:void(0)" class="btn btn-success btn-sm px-4"
                           @click="openSaveShopUserModal(2, item.id)">编辑</a>&nbsp;&nbsp; <a
                        href="javascript:void(0)" class="btn btn-danger btn-sm px-4"
                        @click="deleteShopUser(item.id)">删除</a>&nbsp;&nbsp; <a
                        href="javascript:void(0)" class="btn btn-info btn-sm px-4"
                        @click="getUserShopOrder(item.tel)">订单</a>&nbsp;&nbsp; <a
                        href="javascript:void(0)" class="btn btn-warning  btn-sm px-4"
                        @click="getSocreDetail(item.id)" v-if="currentWxShopObj.shopType===1">积分明细</a>&nbsp;&nbsp;
                    </td>
                  </tr>
                  <tr style="text-align: center" v-if="wxShopUserList==null || wxShopUserList.length<1">
                    <td colspan="5" style="width: 100%;">
                      <span style="display:block;text-align: center;margin:0 auto">暂无数据</span>
                    </td>
                  </tr>
                  </tbody>
                  <!--end::Table body-->
                </table>
                <!--end::Table-->
              </div>
              <!--end::Table container-->
            </div>
            <!--begin::Body-->

            <!-- 分页按钮 -->
            <el-pagination style="display:flex;justify-content:flex-end" @size-change="handleSizeChange"
                           @current-change="handleCurrentChange" :current-page="shopUserQueryParam.pageIndex"
                           :page-sizes="[10, 20, 30, 50, 100]" :page-size="shopUserQueryParam.pageSize"
                           layout="total, sizes, prev, pager, next, jumper" :total="totalCount" background>
            </el-pagination>


          </div>
          <!-- 弹出框 -->
          <el-dialog title="批量导入用户" v-if="excelSaveShopUserVisible" :visible.sync="excelSaveShopUserVisible"
                     width="40%" append-to-body=true>
            <template #title>
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title">批量导入用户</h5>
                </div>
              </div>
            </template>
            <div class="modal-body">
              <div class="form-group" style="display:flex;align-items:center;">
                <el-upload
                    class="upload-Excel"
                    ref = "uploadExcel"
                    :on-change="handleFileChange"
                    accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    drag
                    action="#"
                    :http-request="uploadUserExcel"
                    :auto-upload="false"
                    multiple
                    limit = 1
                >
                  <i class="el-icon-upload"></i>
                  <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                  <div class="el-upload__tip" slot="tip">只能上传xls/xlsx文件</div>
                </el-upload>
              </div>
            </div>

            <div class="modal-footer">
              <button type="button" class="btn btn-light" @click="excelSaveShopUserVisible=false">关闭</button>
              <button @click="batchAddShopUser" type="button"
                      class="btn btn-primary">提交
              </button>
            </div>
          </el-dialog>
          <!-- 新增弹出框 -->
          <!-- 用户的弹窗 -->
          <el-dialog title="新增商城用户" v-if="saveShopUserVisible" :visible.sync="saveShopUserVisible" width="40%"
                     append-to-body=true @close="closeSaveShopUserDialog" :destroy-on-close="true">
            <template #title>
              <div class="modal-content">
                <div class="modal-header">
                  <h2 v-if="saveShopUserMode == 1">新增商城用户</h2>
                  <h2 v-if="saveShopUserMode == 2">编辑商城用户</h2>
                </div>
              </div>
            </template>
            <div class="modal-body" style="width: 80%;margin-left: 10%">
              <el-form :model="saveShopUserForm" :rules="saveShopUserFormRule" ref="saveShopUserForm">
                <el-form-item prop="name">
                                    <span slot="label" style="display:inline-block;">
                                        <label class="form-label fw-bolder text-dark fs-6">用户姓名</label>
                                    </span>
                  <el-input v-model="saveShopUserForm.name"></el-input>
                </el-form-item>
                <el-form-item prop="tel">
                                    <span slot="label" style="display:inline-block;">
                                        <label class="form-label fw-bolder text-dark fs-6">用户手机号</label>
                                    </span>
                  <el-input v-model="saveShopUserForm.tel"></el-input>
                </el-form-item>
                <el-form-item prop="groupName">
                                    <span slot="label" style="display:inline-block;">
                                        <label class="form-label fw-bolder text-dark fs-6">用户分组</label>
                                    </span>
                  <el-input v-model="saveShopUserForm.groupName"></el-input>
                </el-form-item>
                <el-form-item prop="score" v-if="currentWxShopObj.shopType===1">
                                    <span slot="label" style="display:inline-block;">
                                        <label class="form-label fw-bolder text-dark fs-6">用户积分</label>
                                    </span>
                  <el-input :disabled="saveShopUserMode==2" v-model="saveShopUserForm.score"></el-input>
                </el-form-item>
                <template   v-if="currentWxShopObj.shopType===1">
                  <el-form-item v-if="saveShopUserMode==2"  prop="adjustScore">
                                    <span slot="label" style="display:inline-block;">
                                        <label class="form-label fw-bolder text-dark fs-6">积分调整</label>
                                    </span>

                    <div style="display: flex;width: 100%">
                      <el-select  class="mySelectRightNoRadius" v-model="saveShopUserForm.adjustScoreType" placeholder="请选择调整方式">
                        <el-option
                            label="上调"
                            value="5">
                        </el-option>
                        <el-option
                            label="下调"
                            value="6">
                        </el-option>
                      </el-select>
                      <el-input v-model="saveShopUserForm.adjustScore" placeholder="请输入调整的数值"></el-input>
                    </div>

                  </el-form-item>
                </template>

              </el-form>
            </div>

            <div class="modal-footer">
              <button v-if="saveShopUserMode == 1" @click="submitAddShopUser" type="button"
                      class="btn btn-primary">提交
              </button>
              <button v-if="saveShopUserMode == 2" @click="submitUpdateShopUser" type="button"
                      class="btn btn-primary">保存
              </button>
            </div>
          </el-dialog>

          <el-dialog style="padding: 0px"  title="商城用户订单" v-if="wxShopUserOrderVisible"  :visible.sync="wxShopUserOrderVisible" width="100%" append-to-body=true>
            <template #title >
              <div class="modal-content">
                <div class="modal-header">
                  <h2>商城用户订单</h2>
                </div>
              </div>
            </template>
            <orderIndex :currentWxShopId="currentWxShopId" :user-tel="currentUserTel"></orderIndex>
          </el-dialog>

          <el-dialog title="用户积分明细" v-if="userScoreDetailVisible" :visible.sync="userScoreDetailVisible" width="40%" append-to-body=true>
              <template #title="用户积分">
                <div class="card-header border-0 pt-5">
                  <h3 class="card-title align-items-start flex-column">
                    <span class="card-label fw-bolder fs-3 mb-1">积分明细</span> <span class="text-muted mt-1 fw-bold fs-7">该商城下用户积分明细</span>
                  </h3>
                </div>
              </template>
              <show-user-score-dialog :currentWxShopId="currentWxShopId" :currentShopUserRelationId="currentUserRelationId"></show-user-score-dialog>
          </el-dialog>
        </div>
        <!--end::Container-->
      </div>
      <!-- 内容结束 -->
    </div>
  </div>
</template>


<script>
import {
  getWxShopUserList,
  deleteShopUser,
  addShopUser,
  updateShopUser,
  getShopUserDetail,
  downloadTemp,
  exportUserExcel, batchAddShopUserByExcel
} from '@/api/shopUserApi'
import createVoucher from "@/views/Shop/component/createVoucherModal/createVoucher.vue";
import {uploadFileApi} from "@/api/uploadFileApi";
import ShowUserScoreDialog from "@/views/Shop/component/showUserScoreDialog.vue";
import orderIndex from "@/views/Order/index.vue";

export default {
  components: {
    orderIndex,
    ShowUserScoreDialog,
    createVoucher

  },
  props: {
    currentWxShopId: {
      type: Number,
      default: null,
    },
    currentWxShopObj: {
      type: Object,
      default: null,
    }
  },
  data() {
    const checkTel = (rule, value, callback) => {
      const pattern = /^1[3456789]\d{9}$/;

      if (!pattern.test(value)) {
        return callback(new Error('输入手机号格式不正确'))
      }
      return callback()
    };
    const checkAdjustScore = (rule,value,callback) =>{
      console.log(typeof value)
      if(!/^(\d+|\d*\.\d{0,2})$/.test(value)){
        return callback(new Error("请输入数字类型,且小数点最高为两位"))
      }
      if(Number(value)<0){
        return callback(new Error('调整积分不能小于0'))
      }
      return callback()
    };
    return {
      wxShopUserOrderVisible:false,
      userScoreDetailVisible:false,
      excelSaveShopUserVisible: false,
      saveShopUserVisible: false,
      saveShopUserMode: 0,
      wxShopUserList: [],
      shopUserQueryParam: {
        name: "",
        tel: "",
        groupName: "",
        wxShopId: this.currentWxShopId,
        pageIndex: 1,
        pageSize: 10
      },
      saveShopUserForm: {
        name: "",
        tel: "",
        groupName: "",
        wxShopId: this.currentWxShopId,
        score: null,
        wxShopUserRelationId: null,
        adjustScoreType:"6",
        adjustScore:0
      },
      saveShopUserFormRule: {
        name: [
          {required: true, message: '请输入用户姓名', trigger: 'blur'},
        ],
        tel: [
          {required: true, message: '请输入客服手机号', trigger: 'blur'},
          {validator: checkTel, trigger: 'change'}
        ],

        score: [
          {required: true, message: '请输入积分', trigger: 'blur'},
          {validator: checkAdjustScore, trigger: 'change'}
        ],
        adjustScore:[
          // {type: 'number', message: '调整积分必须是数字类型', trigger: 'change'},
          {validator: checkAdjustScore, trigger: 'change'}
        ]
      },
      currentUserRelationId:-1,
      currentUserTel:"",
      fileList:[]
    }
  },
  methods: {
    async getWxShopUserList() {
      let {data} = await getWxShopUserList(this.shopUserQueryParam)
      console.log(data)
      this.wxShopUserList = data.rows
      this.totalCount = data.count
    },
    async deleteShopUser(wxShopUserRelationId) {
      const deleteParam = {};
      deleteParam.wxShopUserRelationId = wxShopUserRelationId
      let res = await deleteShopUser(deleteParam)
      if (res.code == 200) {
        this.$message({
          message: "删除用户成功",
          icon: "success"
        })
        await this.getWxShopUserList()
      } else {
        Swal.fire({
          title: "删除失败"
        })
      }
    },
     submitAddShopUser() {
      this.$refs["saveShopUserForm"].validate(async (valid) => {
        if (valid) {
          let res = await addShopUser(this.saveShopUserForm)
          const that = this;
          if (res.code == 200) {
            this.$message({
              message: "添加用户成功",
              type: "success"
            })
            that.shopUserQueryParam.pageIndex = 1
            await that.getWxShopUserList();
            that.saveShopUserVisible = false
          } else {
            this.$message({
              message: "添加失败",
              type: "error"
            })
          }
        } else {
          await this.$confirm("请检查必填项是否填写完全。", "提示");
        }
      });

    },

    async submitUpdateShopUser() {
      this.$refs["saveShopUserForm"].validate(async (valid) => {
        if (valid) {
          let res = await updateShopUser(this.saveShopUserForm)
          const that = this;
          if (res.code == 200) {
            this.$message({
              type: 'success',
              message: '编辑成功!'
            });
            await that.getWxShopUserList();
            that.saveShopUserVisible = false

          } else {
            this.$message({
              type: 'error',
              message: '编辑失败!'
            });
          }
        } else {
          await this.$confirm("请检查必填项是否填写完全。", "提示");
        }
      });

    },
    handleCurrentChange(param) {
      this.shopUserQueryParam.pageIndex = param
      this.getWxShopUserList();
    },
    handleSizeChange(param) {
      this.shopUserQueryParam.pageIndex = 1
      this.shopUserQueryParam.pageSize = param
      this.getWxShopUserList();
    },
    findUserRecord() {
      this.shopUserQueryParam.pageIndex = 1
      this.getWxShopUserList();
    },
    resetUserQuery() {
      this.shopUserQueryParam = {
        name: "",
        tel: "",
        groupName: "",
        wxShopId: this.currentWxShopId,
        pageIndex: 1,
        pageSize: 10
      }
      this.getWxShopUserList();
    },
    async openSaveShopUserModal(type, currentShopUserRelationId) {

      if (type == 1) {
        this.saveShopUserMode = 1
        this.saveShopUserVisible = true
      } else if (type == 2 && currentShopUserRelationId) {
        const getDetailParam = {
          wxShopUserRelationId: currentShopUserRelationId
        };
        this.saveShopUserMode = 2
        const {data} = await getShopUserDetail(getDetailParam);
        console.log(data)
        this.saveShopUserForm.name = data.name
        this.saveShopUserForm.tel = data.tel
        this.saveShopUserForm.groupName = data.groupName
        this.saveShopUserForm.score = data.score
        this.saveShopUserForm.wxShopUserRelationId = currentShopUserRelationId
        this.saveShopUserVisible = true
      }
    },
    getSocreDetail(id){
      console.log(id)
      this.userScoreDetailVisible = true
      this.currentUserRelationId = id
    },
    async downLoadUserTemplate() {
      const response = await downloadTemp();
      console.log(response)
      const blob = new Blob([response], {type: 'application/octet-stream'});
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = '导入用户模板.xlsx'; // 设置下载的文件名，根据需要修改
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    },

    async exportUser() {
      console.log(this.shopUserQueryParam)
      const response = await exportUserExcel(this.shopUserQueryParam);
      console.log(response)
      const blob = new Blob([response], {type: 'application/octet-stream'});
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = '用户列表导出.xlsx'; // 设置下载的文件名，根据需要修改
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    },
    openExcelSave() {
      this.excelSaveShopUserVisible = true
    },
    handleFileChange(file, fileList){
      this.fileList = fileList
    },
    batchAddShopUser(){
      if(this.fileList.length<1){
        this.$message({
          type: 'error',
          message: '请先选择文件!'
        });
      }

      this.$refs.uploadExcel.submit();
    },
    getUserShopOrder(tel){
      console.log(tel)
      this.currentUserTel = tel
      this.wxShopUserOrderVisible = true
    },
    async uploadUserExcel(event){
      console.log("adada")
      const formData = new FormData();
      if (event) {
        const file = event.file

        formData.append("file", file);
        formData.append("wxShopId",this.currentWxShopId);
      }else{
        this.$message({
          type: 'error',
          message: '请先选择文件!'
        });
      }
      let res = await batchAddShopUserByExcel(formData);
      if(res.code==200){
        let message = "总计："+res.data.total+"其中成功："+res.data.success+",失败:"+res.data.error
        await this.$confirm(message, '提示', {
          confirmButtonText: '确定',
          type: 'info'
        })
        this.excelSaveShopUserVisible = false
        this.findUserRecord()

      }

    },
    closeSaveShopUserDialog(){
      this.saveShopUserForm={
            name: "",
            tel: "",
            groupName: "",
            wxShopId: this.currentWxShopId,
            score: null,
            wxShopUserRelationId: null,
            adjustScore: 0,
            adjustScoreType: "5"
      }
    }

  },
  created() {
    this.getWxShopUserList();
  },
  updated() {
    // 当数据更新后，可以在此处手动初始化下拉菜单插件
    // this.rows.forEach((_, index) => {
    //     this.toggleDropdown(index);
    // });
  }

}

</script>


<style scoped>

</style>
