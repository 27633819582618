<template>
  <div>
    <el-dialog title="集采询价" :visible.sync="buyManyVisible" width="40%"  append-to-body=true @close="closeDialog">
      <el-form :model="formData">
        <el-form-item label="价格">
          <el-input v-model="formData.money" disabled></el-input>
        </el-form-item>
        <el-form-item label="数量">
          <el-input v-model="formData.num"></el-input>
        </el-form-item>
        <el-form-item label="备注">
          <el-input v-model="formData.note"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button @click="closeDialog('formData')">取消</el-button>
          <el-button type="primary" @click="submitBuy('formData')">提交</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>

  export default {
    props:{

      goodsStaffId:{
        type:String,
        required:true
      },
      buyManyVisible:{
        type:Boolean,
        default:false
      }

    },
    data(){
      return {
        formData:{
          skuId:"",
          money:"20",
          num:"",
          note:""
        }
      }
    },
    methods:{
      closeDialog(){
        this.formData.money=20
        this.formData.num=""
        this.buyManyVisible = false
        this.formData.note = ""
        this.$emit("closeDialog")
      },
      submitBuy(){
        this.$message({
          type: 'success',
          message: '提交成功!'
        });
        this.buyManyVisible = false
      }
    }
  }
</script>
